import {React,useState,useEffect} from 'react';
import {CallApi} from "../common/API/APi";
import {success,error} from "../common/Toastify"; 
const CommentModal = (props) => {
  let {setNotifyrole,commentsdata}=props;
  const [errors, setError] = useState();
  const [commenteddata, setCommentdata] = useState();

  const CommentData=async()=>{
    if(!commenteddata){
      setError({ comment: true });
      return;
     }
      const  postdata={"id":commentsdata?.id,'message':commenteddata}
      let res = await CallApi(postdata, 'booking_comment');
      if(res?.status === 200){
        document.querySelector(".commentcancel").click();
        setCommentdata('');
        setNotifyrole(current => !current);
        success(res?.message);
      }else{
        error(res?.message);
      }
    }
    useEffect(()=>{
      if(commentsdata?.id){
        setCommentdata(commentsdata?.comment);

      }
    },[commentsdata?.comment])
  return(
    <div className="modal fade com-modal" id="commentModal" tabIndex="-1" aria-labelledby="commentModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5 golden-text" id="commentModalLabel">Comment</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body pb-0">
         
            <div className='row'>
              <div className='col-md-12 mb-4'>
                <textarea className={errors?.comment? " com-input-field h-auto resize-none border-danger" : "com-input-field h-auto resize-none"} value={commenteddata} onChange={(e)=>setCommentdata(e.target.value)}   rows="6" placeholder='Message'/>
              </div>
            </div>
   
          </div>
          <div className="modal-footer text-center">
            <button type="button" className="golden-bg font-13px com-btn golden-border" onClick={CommentData} >Send</button>
            <button type="button" className="golden-bg font-13px com-btn com-btn-outline golden-border bg-transparent commentcancel" data-bs-dismiss="modal">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  )
} 

export default CommentModal;
