import React,{ useState,useEffect} from "react";
import DataTable from 'react-data-table-component';
import { BiSearch } from "react-icons/bi"
import {CallApi} from "./common/API/APi";
import DataTableLoaders from "./common/DatatableLoader";
import { DateRange } from "./modal/ComDatePicker";
import { Link } from "react-router-dom";

const DriverExpenses = () => {


  const [search,setSearch]=useState('');
  const [datatableloder,setDatatableloder]=useState();
  const [driverexpensesdata,setDriverExpensesdata]=useState();
  const [datesearch,setDateSearch]=useState([]);
  const [totalamount,setTotalamount]=useState(0);
  const paginationRowOptions = [50, 100, 500, 1000];
  const [limit, setLimit] = useState(paginationRowOptions[0]);
  const [TotalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

const columns = [
  
  {name: 'S.No.',selector: row => row.id,sortable: true,width:"80px"},
  {name: ' Driver Name',selector: row => row.drivername,sortable: true,minWidth:"200px"},
  {name: ' Driver Email',selector: row => row.driveremail,sortable: true,minWidth:"200px"},
  {name: ' Total Booking',selector: row => row.booking,sortable: true,minWidth:"200px"},
  {name: ' Total Expenses',selector: row => row.expenses,sortable: true,minWidth:"200px"}
];
const getdriverexpenses=async()=>{
  setDatatableloder(true);
  const skip = (currentPage - 1) * limit;
  let res = await CallApi({"search":search,"from_date": datesearch[0]?datesearch[0]:'',
  "to_date": datesearch[1]? datesearch[1]:'',limit:limit,skip:skip}, "getdriver_expenses");
  if(res?.status === 200){
    let array=[];
    if( res?.data?.length){
      res?.data.map((value,key)=>{
        array.push({
            
                    "id":key+1,
                    "drivername":value?.name,
                    'driveremail':value?.email,
                    'booking':value?.totalNoBooking!=0?<Link to='/Order' className='text-decoration-none text-black' state={{'driver_id':{'value':value?.id,'label':value?.name}}}>{value?.totalNoBooking}</Link>:0 ,
                    'expenses':'$'+value?.totalBookingamount
        })
      })
     
      
    }  
    setTotalamount( res?.totalAmount);
    setTotalRows(res?.TotalRows);
    setDriverExpensesdata(array);
    setDatatableloder(false);
  }
 }

 useEffect(()=>{
  getdriverexpenses();
 },[datesearch,limit,currentPage])

  return (
          <>
            <div className="main-page-body position-relative">
              <h1 className="com-heading">Driver Expenses</h1>

              <div className="com-card-box">
                <div className="d-flex justify-content-between align-items-center gap-2">
                  <div className="col-md-4">
                    <h1 className="com-heading mb-0">Driver Expenses List</h1>
                  </div>
                  <div className="col-md-3 d-flex align-items-center justify-content-end ms-auto gap-2 ">
                  </div>
                </div>
              </div>

              <div className="com-card-box">
                <div className="row">
                  <div className="col-lg-3 col-md-5 col-sm-6 mb-3 mb-sm-0">
                    <div className="input-group">
                      <div className="input-group-text bg-transparent border-end-0"><BiSearch/></div>
                      <input type="text" className="form-control border-start-0 ps-0 font-13px" value={search} onKeyPress={(e) => e.key === "Enter" && getdriverexpenses() } onChange={(e)=>{setSearch(e.target.value);setCurrentPage(1)}} placeholder="Search Driver...."/>
                    </div>
                  </div>
                  <div className=" col-md-6 ms-auto col-sm-6 col-12 order-datatable-filter-container">
                  <DateRange setDateSearch={setDateSearch} setCurrentPage={setCurrentPage} />
                    <div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="com-datatable com-card-box py-2">
                {datatableloder?
                <DataTableLoaders/>
                :
                <DataTable
                columns={columns}
                data={driverexpensesdata}
                pagination
                paginationServer
                fixedHeaderScrollHeight="300px"
                className="myTable"
                responsive
                striped
                paginationRowsPerPageOptions= {paginationRowOptions}
                paginationPerPage={limit}
                paginationTotalRows={TotalRows}
                onChangeRowsPerPage={(newLimit) => {
                  setLimit(newLimit);
                  setCurrentPage(1);
                }}
                onChangePage={(page) => {setCurrentPage(page)}}
                paginationDefaultPage={currentPage}
              />
                }
                 <div>Total Amount: ${totalamount?totalamount:0}</div>
              </div>
            
            </div>
          </>

    
  )
}

export default DriverExpenses;