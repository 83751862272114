import {React,useState,useEffect} from "react";
import AddDriver from "./modal/AddDriver";
import StatusModal from "./modal/StatusModal";
import { IoMdAddCircleOutline } from "react-icons/io";
import { BiSearch } from "react-icons/bi";
import Select from "react-select";
import DataTable from "react-data-table-component";
import EditDriver from "./modal/EditDriver";
import DeleteModal from "./modal/DeleteModal";
import DataTableLoaders from "./common/DatatableLoader";
import { CallApi } from "./common/API/APi";
import { error } from "./common/Toastify";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import moment from "moment-timezone";



const Driver = () => {
  const profiledata = JSON.parse(localStorage.getItem("profiledata"));
  const statusoption = [
    { value: 1, label: "Active" },
    { value: 2, label: "Suspend" },
  ];
  const [driverdata, setDriverdata] = useState();
  const [search, setSearch] = useState("");
  const [notifyrole, setNotifyrole] = useState();
  const [statusvalue, setStatusvalue] = useState({ value: 1, label: "Active" });
  const [datatableloder, setDatatableloder] = useState();
  const [editid, setEditid] = useState();
  const [deleteItem, setDeleteItem] = useState();
  const [statusItem, setStatusItem] = useState();
  const paginationRowOptions = [50, 100, 500, 1000];
  const [limit, setLimit] = useState(paginationRowOptions[0]);
  const [TotalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const columns = [
    {
      name: "S.No.",
      selector: (row) => row.sno,
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      minWidth: "140px",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      minWidth: "280px",
    },
    {
      name: "Contact No.",
      selector: (row) => <>{<a style={{"text-decoration": "none","color":" #000","cursor": "pointer"}} href={`tel:${row.phone}`}>{row.phone}</a>}</>,
      sortable: true,
      minWidth: "180px",
    },
    {
      name: "No. Bookings",
      selector: (row) => row.bookings,
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Vehicle Model",
      selector: (row) => row.vehicle_model,
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Created On",
      selector: (row) => row.createdOn,
      sortable: true,
      minWidth: "190px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Action",
      sortable: false,
      minWidth: "300px",
      selector: (row) => (
        <>
       {" "}
          {profiledata?.permission.filter((e) => e.name === "Driver")[0]
            ?.permission?.edit === 1 ? (
          <button
            className="cell-tabs bg-secondary text-white"
            onClick={() => setEditid(row?.action?.id)}
            data-bs-toggle="modal"
            data-bs-target="#editDriverModal"
          >
            Edit
          </button>
           ) : (
            ""
          )}
           {profiledata?.permission.filter((e) => e.name === "Driver")[0]
            ?.permission?.delete === 1 ? (
          row?.action?.ordercount != 0?
          <button
            className="cell-tabs mx-3 bg-danger text-white"
            onClick={() => setDeleteItem(row?.action?.id)}
            data-bs-toggle="modal"
            data-bs-target="#deleteitemModal"
          >
            Delete
          </button>
          :''
          ) : (
            ""
          )}
            {profiledata?.permission.filter((e) => e.name === "Driver")[0]
            ?.permission?.edit === 1 ? (
              row?.action?.ordercount != 0?
            row?.action?.status === 1 ? (
              <button
                data-bs-toggle="modal"
                data-bs-target="#statusModal"
                onClick={() =>
                  setStatusItem({ id: row?.action?.id, status: 2 })
                }
                className="cell-tabs text-white bg-success"
              >
                Active
              </button>
            ) : (
              <button
                data-bs-toggle="modal"
                data-bs-target="#statusModal"
                onClick={() =>
                  setStatusItem({ id: row?.action?.id, status: 1 })
                }
                className="cell-tabs bg-warning"
              >
                Suspend
              </button>
            )
            :''
          ) : (
            ""
          )}
           <Link to="/Order" state={{'driver_id':{'value':row?.action?.id,'label':row?.action?.name}}}>
            <button
              className="cell-tabs mx-3 orange-bg text-white"
            >
            Bookings
            </button>
          </Link>
          {" "}
        </>
      ),
    },
  ];




  const getDriver = async () => {
    setDatatableloder(true);
    const skip = (currentPage - 1) * limit;
    const postdata = { search: search, status: statusvalue?.value,user_type:2,skip:skip,limit:limit };
    let res = await CallApi(postdata, "getadmins");
    if (res?.status === 200) {
      let array = [];
      res?.data.map((value, key) => {
        const CreatedAt = moment.utc(value?.createdAt).tz("America/New_York");
        const UpdateAt = moment.utc(value?.updatedAt).tz("America/New_York");
        array.push({
          sno: key + 1,
          name: value?.name,
          email: value?.email,
          phone: value?.phone,
          bookings: value?.noOfBooking !=0? <Link to="/Order" className='text-decoration-none text-black' state={{'driver_id':{'value':value?.id,'label':value?.name}}}>{value?.noOfBooking}</Link>:0,
          vehicle_model:value?.vehicle_model,
          createdOn: (
            <Moment format="MMM DD, YYYY : hh:mm A" >
              {CreatedAt}
            </Moment>
          ),
          lastUpdated: (
            <Moment format="MMM DD, YYYY : hh:mm A" >
              {UpdateAt}
            </Moment>
          ),
          updatedBy: value?.CreatedBy,
          status: value?.status == 1 ? "Active" : "Suspend",
          action: { id: value?.id, status: value?.status,name:value?.name,ordercount:value?.orderCount },
        });
      });
      setDriverdata(array);
      setTotalRows(res?.TotalRows);
      setDatatableloder(false);
    } else {
      error("No data Found!");
    }
  };

  useEffect(() => {
    getDriver();
  }, [statusvalue, notifyrole,limit,currentPage]);
  return (
    <>
      <div className="main-page-body position-relative">
        <div className="com-card-box">
          <div className="row align-items-center">
            <div className="col-4">
              <h1 className="com-heading mb-0">Driver</h1>
            </div>

            <div className="col-3 d-flex align-items-center justify-content-end ms-auto gap-2">
              <button
                type="button"
                className="btn font-13px golden-bg d-flex align-items-center justify-content-center gap-2 text-nowrap com-btn golden-btn-respn-show"
                data-bs-toggle="modal"
                data-bs-target="#addDriverModal"
              >
                <IoMdAddCircleOutline />
                Add New
              </button>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <div className="com-card-box">
              <div className="row">
                <div className="col-lg-3 col-md-5 col-sm-6 mb-3 mb-sm-0">
                  <div className="input-group">
                    <div className="input-group-text bg-transparent border-end-0">
                      <BiSearch />
                    </div>
                    <input
                      type="text"
                      className="form-control border-start-0 ps-0 font-13px"
                      value={search}
                      onKeyPress={(e) => e.key === "Enter" && getDriver()}
                      onChange={(e) => {setSearch(e.target.value);setCurrentPage(1);}}
                      placeholder="Search Name And Email..."
                    />
                  </div>
                </div>
                <div className="col-lg-2 col-md-3 ms-auto col-sm-3 col-6">
                  <Select
                    defaultValue={statusvalue}
                    onChange={(e)=>{setStatusvalue(e);setCurrentPage(1)}}
                    options={statusoption}
                    classNamePrefix="com-select"
                    isSearchable={false}
                  />
                </div>
              </div>
            </div>

            <div className="com-datatable com-card-box py-1">
               {datatableloder ? (
                <DataTableLoaders />
              ) : (
                <DataTable
                  columns={columns}
                  data={driverdata}
                  pagination
                  paginationServer
                  className="myTable"
                  paginationRowsPerPageOptions= {paginationRowOptions}
                  paginationPerPage={limit}
                  paginationTotalRows={TotalRows}
                  onChangeRowsPerPage={(newLimit) => {
                    setLimit(newLimit);
                    setCurrentPage(1);
                  }}
                  onChangePage={(page) => {setCurrentPage(page)}}
                  paginationDefaultPage={currentPage}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <AddDriver setNotifyrole={setNotifyrole} />
      <EditDriver  
       editid={editid}
        setNotifyrole={setNotifyrole}
        setEditid={setEditid}/>
      <DeleteModal 
       apiname={"deleteadmin"}
       deleteItem={deleteItem}
       setNotifyrole={setNotifyrole}/>
      <StatusModal 
      apiname={"updateadminstatus"}
      statusItem={statusItem}
      setNotifyrole={setNotifyrole}/>
    </>
  );
};

export default Driver;
