import React, {useState} from 'react';
import Select from 'react-select';
import {CallApi} from "../common/API/APi";
import {success,error} from "../common/Toastify"; 



const AddNewRole = (props) => {
  const {setNotifyrole}= props;
  const options = [
    { value: 1, label: 'Active' },
    { value: 2, label: 'Suspend' },
  ];
  const [selectedOption, setSelectedOption] = useState({value: 1, label: 'Active'});
  const [rolename, setRolename] = useState('');
  let Pages= ['Order','Role', 'Staff','Customer','Dashboard','Driver','Finance','Setting','ExpensesType','Expenses','ExpensesChannel','Price','CRMBackup'];

  function maincheck(key,event) {
    const Allinput = document.querySelectorAll('.maincurd_'+key+' div input');
    if(event.target.checked){
      Array.from(Allinput).map((e)=>{
           e.checked =true;
      })
    }else{
      Array.from(Allinput).map((e)=>{
        e.checked =false;
      })
    }
  }
    const SubmitRole =async()=>{
            if(!rolename){
               return;
            }
        const rolearraya=[];
        const permissiondiv = document.querySelectorAll(".permissiondiv");
        Array.from(permissiondiv).map((value,key)=>{
         let permission={'view':0,'add':0,'edit':0,'delete':0};
         const p_name=  value.querySelector(".pagename").getAttribute('data-name');
         if(p_name =='Order' || p_name=='Customer'){
              const mailinputs = value.querySelector(".maincurd_"+(key+1)+" div .mail");
              if(mailinputs.checked){
                permission['mail'] =1;
              }else{
                permission['mail'] =0;
              }
              const msginputs = value.querySelector(".maincurd_"+(key+1)+" div .message");
              if(msginputs.checked){
                permission['message'] =1;
              }else{
                permission['message'] =0;
              }
          }
          if(p_name =='Order'){
              const assigninputs = value.querySelector(".maincurd_"+(key+1)+" div .assign");
              if(assigninputs.checked){
                permission['assign'] =1;
              }else{
                permission['assign'] =0;
              }
              const invoiceinputs = value.querySelector(".maincurd_"+(key+1)+" div .invoice");
              if(invoiceinputs.checked){
                permission['invoice'] =1;
              }else{
                permission['invoice'] =0;

              }
              const refundinputs = value.querySelector(".maincurd_"+(key+1)+" div .refund");
              if(refundinputs.checked){
                permission['refund'] =1;
              }else{
                permission['refund'] =0;

              }
          }
          const viewinputs = value.querySelector(".maincurd_"+(key+1)+" div .view");
          if(viewinputs.checked){
            permission['view'] =1;
          }
          const addinputs = value.querySelector(".maincurd_"+(key+1)+" div .add");
          if(addinputs.checked){
            permission['add'] =1;
          }
          const editinputs = value.querySelector(".maincurd_"+(key+1)+" div .edit");
          if(editinputs.checked){
            permission['edit'] =1;
          }
          const deleteinputs = value.querySelector(".maincurd_"+(key+1)+" div .delete");
          if(deleteinputs.checked){
            permission['delete'] =1;
          }
          rolearraya.push({"name":p_name,"permission":permission});
        });
       
         const postdata={"rolename":rolename,"status":selectedOption?.value,"permission":rolearraya}
        let res = await CallApi(postdata, "managerole");
        if(res?.status === 200){
          document.getElementById("addroleform").reset();
          setRolename('');
          success(res?.message);
          setNotifyrole(current => !current);
        }else{
          error(res?.message);
        }
    }

  const subinputcheck=(key)=>{
    const Allinput = document.querySelectorAll('.maincurd_'+key+' div input');
    const maininput = document.querySelectorAll('.pagename input');
    const array=[];
    Array.from(Allinput).map((e)=>{
      if(e.checked == true){
        array.push('true');
      }else{
        array.push('false');
      }
    })

    if(!array.includes('false')){
    Array.from(maininput).map((value)=>{
           let attr= value.getAttribute('id');
                if(parseInt(attr) === key){
                  value.checked =true;
                }
      }) 
    }else{
      Array.from(maininput).map((value)=>{
        let attr= value.getAttribute('id');
             if(parseInt(attr) === key){
               value.checked =false;
             }
       }) 
    }
  }
    
  return(
    <div className="modal fade com-modal" id="addRoleModal" tabIndex="-1" aria-labelledby="addRoleModalLabel" aria-hidden="true">

      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5 golden-text" id="addRoleModalLabel">
              Add New Role
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">

            <form id="addroleform">
            <div className='row'>
              <div className='col-md-6 mb-2'>
                <input type="text" className='com-input-field' value={rolename} onChange={(e)=>setRolename(e.target.value)}  placeholder='Role Name'/>

              </div>
              <div className="col-md-6 mb-2">
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={options}
                  classNamePrefix="com-select"
                  isSearchable={false}
                />
              </div>
            </div>


           {
            Pages?.map((data,key)=>{
              return(
                <div className='border-bottom mt-3 pb-3 permissiondiv'  key={key}>
                <div className="form-check mb-3 pagename" data-name={data}>
                  <input className="form-check-input" type="checkbox" onChange={(e)=>maincheck(key+1,e)}  value="" id={key+1} />
                  <label className="form-check-label" htmlFor={key+1} >{data}</label>

                </div>
                <div className={`d-flex gap-3 ps-4 flex-wrap maincurd_${key+1}`}>
                  <div className="form-check font-12px">
                    <input className="form-check-input view" onChange={()=>subinputcheck((key+1))} type="checkbox"  id={`manageview-${key+1}`} />
                    <label className="form-check-label" htmlFor={`manageview-${key+1}`}>View</label>
                  </div>
                  <div className="form-check font-12px">
                    <input className="form-check-input add" onChange={()=>subinputcheck((key+1))} type="checkbox" id={`manageadd-${key+1}`} />
                    <label className="form-check-label" htmlFor={`manageadd-${key+1}`}>Add</label>
                  </div>
                  <div className="form-check font-12px">
                    <input className="form-check-input edit" onChange={()=>subinputcheck((key+1))} type="checkbox" id={`manageedit-${key+1}`} />
                    <label className="form-check-label" htmlFor={`manageedit-${key+1}`}>Edit</label>
                  </div>
                  <div className="form-check font-12px">
                    <input className="form-check-input delete" onChange={()=>subinputcheck((key+1))} type="checkbox" id={`managedelete-${key+1}`} />
                    <label className="form-check-label" htmlFor={`managedelete-${key+1}`}>Delete</label>
                  </div>
                  {data=='Order' || data=='Customer' ? 
                  <>
                    <div className="form-check font-12px">
                      <input className="form-check-input mail" onChange={()=>subinputcheck((key+1))} type="checkbox" id={`managesendemail-${key+1}`} />
                      <label className="form-check-label" htmlFor={`managesendemail-${key+1}`}>Manage Email</label>
                  </div>
                  <div className="form-check font-12px">
                   <input className="form-check-input message" onChange={()=>subinputcheck((key+1))} type="checkbox" id={`managesendmess-${key+1}`} />
                   <label className="form-check-label" htmlFor={`managesendmess-${key+1}`}>Manage Message</label>
                  </div>
                   </>
                   :''}
                   {data =='Order'?
                   <>
                  <div className="form-check font-12px">
                   <input className="form-check-input assign" onChange={()=>subinputcheck((key+1))} type="checkbox" id={`manageassign-${key+1}`} />
                   <label className="form-check-label" htmlFor={`manageassign-${key+1}`}>Assign Driver</label>
                  </div>
                  <div className="form-check font-12px">
                   <input className="form-check-input invoice" onChange={()=>subinputcheck((key+1))} type="checkbox" id={`manageinvoice-${key+1}`} />
                   <label className="form-check-label" htmlFor={`manageinvoice-${key+1}`}>Manage Invoice</label>
                  </div>
                  <div className="form-check font-12px">
                   <input className="form-check-input refund" onChange={()=>subinputcheck((key+1))} type="checkbox" id={`managerefund-${key+1}`} />
                   <label className="form-check-label" htmlFor={`managerefund-${key+1}`}>Assign Driver</label>
                  </div>
                </>
                 :''}

                </div>
              </div>
              )
            })
           }
           </form>
          </div>
          <div className="modal-footer text-center">
            <button
              type="button"
              className="golden-bg font-13px com-btn golden-border"
              data-bs-dismiss="modal"
              onClick={SubmitRole}
            >
              Create Role
            </button>
            <button
              type="button"
              className="golden-bg font-13px com-btn com-btn-outline golden-border bg-transparent"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
} 

export default AddNewRole;
