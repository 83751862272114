import React,{useState,useEffect} from "react";
import DataTable from 'react-data-table-component';
import DataTableLoaders from "./common/DatatableLoader";
import EditTransferPrice from "./modal/EditTransferPrice";
import EditHourPrice from "./modal/EditHourPrice";
import EditMeetPrice from "./modal/EditMeetGreetPrice";
import EditsuregechargePrice from "./modal/Editsurgechargeprice";
import EditVehicleModel from "./modal/EditVehicleModel";
import { CallApi } from "./common/API/APi";
import Moment from "react-moment";

const PriceManage=()=>{
  const [datatableloder,setDatatableloder]=useState(true);
  const [pricedata,setPricedata]=useState([]);
  const [transferpriceid,setTransferpriceid]=useState();
  const [hourpriceid,setHourpriceid]=useState();
  const [meetpriceid,setMeetpriceid]=useState();
  const [surgepriceid,setSurgepriceid]=useState();
  const [vehiclemodelid,setvehiclemodelid]=useState();
  const [notifyrole,setNotifyrole]=useState();
  const profiledata= JSON.parse(localStorage.getItem('profiledata'));


  const paginationRowOptions = [50, 100, 500, 1000];
  const columns = [
    {name: 'S.No.',selector: row => row.id,sortable: true,width:"80px"},
    {name: 'Type',selector: row => row.Type,sortable: true,minWidth:"200px"},
    {name: 'Last Updated',selector: row => row.lastupdate,sortable: true,minWidth:"240px"},
    {name: 'Action',selector:(row) => <> {profiledata?.permission.filter(e => e.name === 'Role')[0]?.permission?.edit === 1?(row?.action?.type=='transfer'?<button type="button" onClick={()=>setTransferpriceid(row?.action?.id)} className="btn font-13px golden-bg d-flex align-items-center justify-content-center gap-2 text-nowrap com-btn golden-btn-respn-show" data-bs-toggle="modal" data-bs-target="#editTransferpriceModal">Edit</button>:row?.action?.type=='hour'?<button type="button" onClick={()=>setHourpriceid(row?.action?.id)} className="btn font-13px golden-bg d-flex align-items-center justify-content-center gap-2 text-nowrap com-btn golden-btn-respn-show" data-bs-toggle="modal" data-bs-target="#editHourpriceModal">Edit</button>:row?.action?.type=='meetgreet'?<button type="button" onClick={()=>setMeetpriceid(row?.action?.id)} className="btn font-13px golden-bg d-flex align-items-center justify-content-center gap-2 text-nowrap com-btn golden-btn-respn-show" data-bs-toggle="modal" data-bs-target="#editMeetpriceModal">Edit</button>:row?.action?.type=='surgecharge'?<button type="button" onClick={()=>setSurgepriceid(row?.action?.id)} className="btn font-13px golden-bg d-flex align-items-center justify-content-center gap-2 text-nowrap com-btn golden-btn-respn-show" data-bs-toggle="modal" data-bs-target="#editSurgepriceModal">Edit</button>:row?.action?.type=='vehicle_model'?<button type="button" onClick={()=>setvehiclemodelid(row?.action?.id)} className="btn font-13px golden-bg d-flex align-items-center justify-content-center gap-2 text-nowrap com-btn golden-btn-respn-show" data-bs-toggle="modal" data-bs-target="#editvehiclemodelModal">Edit</button>:"" ):''}</>,minWidth:"130px"},
  ];
  
  const getprices=async()=>{
     let res = await CallApi({},'get_price');
     if(res?.status ==200){
      setDatatableloder(false);
      let array=[];
      res?.data?.length?res?.data.map((value,key)=>{
        array.push({
          'id':key+1,
          'Type':<span className="lettercapital">{value?.type}</span>,
          'lastupdate':<Moment format="MMM DD, YYYY : hh:mm A">{value?.updatedAt}</Moment>,
          'action':{'id':value?._id,'type':value?.type}
        })
      })
      :''
      setPricedata(array);
     }
  }
  useEffect(()=>{
    getprices();
   
  },[notifyrole])
  return ( 
    <>
    <div className="main-page-body position-relative">
      <h1 className="com-heading">Price Manangement</h1>

      <div className="com-card-box">
        <div className="row align-items-center">
          <div className="col-md-4">
            <h1 className="com-heading mb-0">Price List</h1>
          </div>
          <div className="col-md-3 d-flex align-items-center justify-content-end ms-auto gap-2 res-all-golden-btn">
            
          </div>
        </div>
      </div>
      <div className="com-datatable com-card-box py-2">
        {datatableloder ? (
          <DataTableLoaders />
        ) : (
          <DataTable
            columns={columns}
            data={pricedata}
            pagination
            fixedHeaderScrollHeight="300px"
            className="myTable"
            responsive
            striped
            paginationRowsPerPageOptions={paginationRowOptions}
          />
        )}
      </div>
    </div>
    <EditTransferPrice transferbyid={transferpriceid} setNotifyrole={setNotifyrole}/>
    <EditHourPrice hourbyid={hourpriceid} setNotifyrole={setNotifyrole}/>
    <EditMeetPrice meetbyid={meetpriceid} setNotifyrole={setNotifyrole}/>
    <EditsuregechargePrice surgebyid={surgepriceid} setNotifyrole={setNotifyrole}/>
    <EditVehicleModel vehiclemodelid={vehiclemodelid} setNotifyrole={setNotifyrole}/>
    </>
  );
}
export default PriceManage;