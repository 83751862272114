import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';


function BalanceOverview(props) {
  const {graphdata} =props;
  const chartInstance = useRef(null);
  const activityInstance = useRef(null);
  

  const balanceChart = () => {
    
    const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sept", "Oct", "Nov","Dec"];
    for (let i = 0; i < labels; ++i) {
      labels.push(i.toString());
    }

    const datapoints1 = graphdata?.totalorders;
    const datapoints2 = graphdata?.totalRevenue;
    let combinedData=[];
    if(datapoints1 && datapoints2){
       combinedData = datapoints1.map((orderValue, index) => ({
        order: orderValue,
        revenue: datapoints2[index]
      }));
    }

    const data = {
      labels: labels,
      datasets: [
        {
          label: 'Orders',
          data: combinedData.map(point => point.order),
          borderColor: "#507ae9",
          fill: false
        }
      ]
    };

    const ctx = document.getElementById("balancechartRef").getContext('2d');

    // Check if there is an existing chart instance and destroy it
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    // Create a new chart instance
    chartInstance.current = new Chart(ctx, {
      type: 'line',
      data: data,
      options: {
        elements: {
          point: {
            radius: 0
          }
        },
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              label: (tooltipItem) => {
                const index = tooltipItem.dataIndex;
                const order = combinedData[index].order;
                const revenue = combinedData[index].revenue;
                return `Orders: ${order}, Revenue: ${revenue}`;
              }
            }
          }
        },
        interaction: {
          intersect: false
        },
        scales: {
          x: {
            display: true,
            title: {
              display: true
            },
            ticks: {
              font: {
                size: 11,
              }
            }
          },
          y: {
            title: {
              display: false,
              text: 'Value'
            },
            grid: {
              display: false
            },
            suggestedMin: 0,
            suggestedMax: 400,
            ticks: {
              font: {
                size: 11,
              }
            }
          }
        },
      }
    });
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }

  const userActivityChart = () => {
    const labels = ["S", "M", "T", "W", "TH", "F", "S"];
    const datapoints1 = graphdata?.user_activity?.totalPaidOrders;
    const datapoints2 = graphdata?.user_activity?.totalorders;
    // const datapoints3 = graphdata?.totalRevenue;
  
    // Corrected loop to add labels as strings
    for (let i = 0; i < labels; ++i) {
      labels.push(i.toString());
    }

    const data = {
      labels: labels,
      datasets: [
        {
          label: 'PaidOrders',
          data: datapoints1,
          backgroundColor: "#3762ea",
        },
        {
          label: 'TotalOrders',
          data: datapoints2,
          backgroundColor: "#e9ecf8",
        },
      ],
    };
  
    var ctx = document.getElementById("activityChartRef").getContext("2d");
  
    // Check if there is an existing chart instance and destroy it
    if (activityInstance.current) {
      activityInstance.current.destroy();
    }
  
    // Create a new horizontal bar chart instance
    activityInstance.current = new Chart(ctx, {
      type: 'bar',
      data: data,
      options: {
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
        },
        maintainAspectRatio: false,
        responsive: true,
        aspectRatio: 1,
        scales: {
          x: {
            // stacked: true,
            display: true,
            title: {
              display: true,
            },
            grid: {
              display: false,
            },
            ticks: {
              font: {
                size: 11,
              },
            },
          },
          y: {
            // stacked: true,
            title: {
              display: false,
            },
            grid: {
              display: true,
            },
            min: 0,
            ticks: {
              font: {
                size: 11,
              },
            },
          },
        },
      },
    });
  };
  

  useEffect(() => {
    balanceChart()
    userActivityChart();
    const resizeChart = () => {
      if (activityInstance.current && chartInstance.current) {
        activityInstance.current.resize();
        chartInstance.current.resize();
      }
    };
    window.addEventListener('resize', resizeChart);    
  }, [graphdata]);

  

  return (
    <div className="d-flex row">
      <div className="col-md-9 col-sm-8 col-12">
        <div className="com-card-box balance-overview mb-3 mb-sm-0">
          <div className="d-flex justify-content-between">
            <p className="com-heading">Orders Overview</p>
         
          </div>
          <div className="balance-overview-chart-box">
            <canvas
              id="balancechartRef"
              className="balance-overview-chart-box-canvas"
            ></canvas>
          </div>
        </div>
      </div>
      <div className="col-md-3 col-sm-4 col-12">
        <div className="w-100 com-card-box activity-chart-container">
          <div className="d-flex justify-content-between">
            <p className="com-heading">Customer Orders</p>
          
          </div>
          <div className="activity-chart-container-box" >
            <canvas
              id="activityChartRef"
              className="activity-chart-container-box-canvas"
              // style={{ width: "100%", height: "100%" }}
            ></canvas>
          </div>
           <p className='activity-chart-container-para'>Current Week</p>
        </div>
      </div>
    </div>
  );
}

export default BalanceOverview;
