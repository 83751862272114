import React, {useState,useEffect} from 'react';
import {CallApi} from "../common/API/APi";
import {success,error} from "../common/Toastify"; 


const BulksendMessage = (props) => {
    const {bulkmails,setNotifyrole}=props;
   const [messagedata,setMesaagedata]=useState({'mobileNumber':[],'message':''});
   const [errors,setErrors]=useState();
    
   const sendMessage=async()=>{
         if(!messagedata?.message){
            setErrors({'message':true});
            return;
          }
          
          let res = await CallApi(messagedata, 'sendmessage');
          if(res?.status === 200){
             setNotifyrole(current => !current);
            success(res?.message);
            document.querySelector(".bulkmessagecancel").click();
            setMesaagedata({'message':''});
          }else{
            error(res?.message);
          }
   } 
   const handleInputChange = (e, index) => {
    if(e.target.value.match(/^[0-9+-]+$/)){
    const newMobileNumbers = [...messagedata.mobileNumber];
    newMobileNumbers[index] = e.target.value;
    setMesaagedata({...messagedata,mobileNumber:newMobileNumbers});
    }else{
       error('Not valid number');
    }
  };
    useEffect(()=>{
      if(bulkmails?.length){
        setErrors('');
         setMesaagedata({'mobileNumber':[],'message':''});
         let arraymsg=[];
         for(let i=0;i<bulkmails?.length;i++){
            arraymsg.push(bulkmails[i]['phone']);
         }
         setMesaagedata({...messagedata,'mobileNumber':arraymsg});      
      }else{
         setMesaagedata({'mobileNumber':[],'message':''});
      }
    },[bulkmails])
   
  return(
      <div className="modal fade com-modal" id="BulksendMessageModal" tabIndex="-1" aria-labelledby="BulksendMessageModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5 golden-text" id="SendMessageModalLabel">Send Message</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body pb-0">
         
            <div className='row'>
            <div className='col-md-12 mb-4 '>
               
               <div className='multi-email-label-box'>
               {messagedata?.mobileNumber?.length?messagedata?.mobileNumber.map((value,imsg)=>{
                  return(
                   <input className='com-input-field' type='text' value={value} key={imsg} onChange={(e)=>handleInputChange(e, imsg)} />
                  );
               }):'No Phone Number is selected !'}
               </div>
            </div>
              <div className='col-md-12 mb-4'>
                <textarea className={errors?.message?"com-input-field h-auto resize-none border-danger":"com-input-field h-auto resize-none"} onChange={(e)=>setMesaagedata({...messagedata,'message':e.target.value})} value={messagedata?.message}    rows="6" placeholder='Write a message '/>
              </div>
            </div>
   
          </div>
          <div className="modal-footer text-center">
           {bulkmails?.length? <button type="button" className="golden-bg font-13px com-btn golden-border" onClick={sendMessage} >Send</button>:''}
            <button type="button" className="golden-bg font-13px com-btn com-btn-outline golden-border bg-transparent bulkmessagecancel" data-bs-dismiss="modal">Cancel</button>
          </div>
        </div>
      </div>
      </div>
  )
} 

export default BulksendMessage;
