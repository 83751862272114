import React from "react";


const BookingCard = (props) => {
  let {bookcount} =props;
  return(
    <div className="OrderCard-main-container">
      <div className="order-card com-card-box BookingCard-width analytics-box  border-bottom border-3 border-warning mb-0">
        <div className="d-flex align-items-center justify-content-between mb-3 ">
          <h4 className="analytics-box-body-show mb-0"><span>{bookcount?.totalbooking}</span></h4>
        </div>
        <p className="analytics-box-heading mb-0">TOTAL BOOKING</p>
      </div>
      <div className="order-card com-card-box BookingCard-width analytics-box border-bottom border-3 border-warning mb-0">
        <div className="d-flex align-items-center justify-content-between mb-3 ">
          <h4 className="analytics-box-body-show mb-0"><span>{bookcount?.totalupcoming}</span></h4>
        </div>
        <p className="analytics-box-heading mb-0">Upcoming BOOKING</p>
      </div>
      <div className="order-card com-card-box BookingCard-width analytics-box border-bottom border-3 border-warning mb-0">
        <div className="d-flex align-items-center justify-content-between mb-3 ">
          <h4 className="analytics-box-body-show mb-0"><span>{bookcount?.totaldonebooking}</span></h4>
        </div>
        <p className="analytics-box-heading mb-0">Done BOOKING</p>
      </div>
    </div>
  )
}

export default BookingCard;