import React, { useEffect, useState } from "react";
import {useLocation,useNavigate} from "react-router-dom";
import logo from "../assets/images/main-logo.png";
import car from "../assets/images/cloud-cars.png";
import {_APIS} from "./common/API/APi";
import { IconContext } from "react-icons";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import {BiLock} from "react-icons/bi"
import {success,error} from "./common/Toastify"; 
import { ToastContainer } from "react-toastify";

const ChangePassword = () =>{
  const location = useLocation();
  const navigate = useNavigate();
  const token = location?.pathname.split("/changepassword/")[1];
  const [inputs,setInputs]= useState({'password':'','conpassword':''});
  const [errors, setError] = useState();
  const[visible , setVisible] = useState(false);
  const[newvisible , setNewVisible] = useState(false);
  const SubmitPassword=async()=>{
               if(!inputs?.password){
                 setError({ password: true });
                  return;
               }  
               if(!inputs?.conpassword){
                setError({ conpassword: true });
                return;
              } 
              if(inputs?.password != inputs?.conpassword){
                setError({ conpassword: true });
                return;
              } 
             setError();
             const postdata={"token":token,'password':inputs?.password};
            let res = await _APIS(postdata, "changepassword");
            if(res?.status === 200){
              success(res?.massage);
              setTimeout(() => {
                navigate("/");
              }, 3000);
            }else{
              error(res?.massage);
            }
  }
  const verifytoken=(async()=>{
    if(token){
      const postdata={"token":token};
      let res = await _APIS(postdata, "verifytoken");
      if(res?.status === 400){
          error(res?.message);
          setTimeout(() => {
            navigate("/");
          }, 3000);
      } 
    }else{
      error("Something Went Wrong!");
      setTimeout(() => {
        navigate("/");
      }, 1000);
    }
  })
  useEffect(()=>{
    verifytoken();
  },[])
  return(
    
    <>
     <ToastContainer></ToastContainer>
    <div className="form-design main-page d-flex align-items-center">
      <img className="w-100 h-100 form-design-bg" src={car} alt="black cars"/>
      <div className="form-design-inner position-relative mx-auto row">
        <div className="form-design-inner-left d-flex align-items-center justify-content-center col-md-5 position-relative">
          <img src={logo} className="d-block mx-auto" alt="main-logo"/>
          <div className="dot rounded-circle position-absolute"></div>
        </div>
        <div className="form-design-inner-right d-flex align-items-center justify-content-center col-md-7 position-relative">
          <div className="dot rounded-circle position-absolute"></div>
          <div className="form-design-inner-right-inner w-100 text-center">
            <h1 className="form-design-inner-right-inner-heading mb-5">Change Password</h1>
            <div className="form-design-inner-right-inner-input mb-4">
              <IconContext.Provider value={{ className: "form-design-inner-right-inner-input-left-icon" }}>
                <BiLock/>
              </IconContext.Provider>
              <input type={newvisible ? "text" : "password"} onChange={(e)=>setInputs({...inputs,'password':e.target.value})} value={inputs?.password}  className={errors?.password? " font-13px pe-2 w-100 border-danger" : "font-13px pe-2 w-100 "} placeholder="New Password"/>
              <button className="form-design-inner-right-inner-input-password-icon position-absolute border-0 p-0 bg-transparent" onClick={() => setNewVisible(current => !current)}>
                {newvisible ? <BsEyeSlash/> : <BsEye/>}
              </button>
            </div>
            <div className="form-design-inner-right-inner-input mb-3">
              <IconContext.Provider value={{ className: "form-design-inner-right-inner-input-left-icon" }}>
                <BiLock/>
              </IconContext.Provider>
              <input type={visible ? "text" : "password"} onChange={(e)=>setInputs({...inputs,'conpassword':e.target.value})} value={inputs?.conpassword}  className={errors?.conpassword? " font-13px w-100 border-danger" : "font-13px w-100 "} placeholder="Confirm New Password"/>
              <button className="form-design-inner-right-inner-input-password-icon position-absolute border-0 p-0 bg-transparent" onClick={() => setVisible(current => !current)}>
                {visible ? <BsEyeSlash/> : <BsEye/>}
              </button>
            </div>
            <button className="btn form-design-inner-right-inner-btn golden-bg"  onClick={SubmitPassword}>Submit</button>
          </div>
        </div>
      </div>

    </div>
    </>
  )
}

export default ChangePassword;