
import React from 'react';

const DataTableLoaders = () => {
  return (
      
   <div className="com-datatable-loader align-items-center d-flex h-100 justify-content-center position-absolute start-0 top-0 w-100">
         <div className="spinner-border" role="status"> <span className="visually-hidden">Loading...</span>
         </div>
   </div>

    
  )
}

export default DataTableLoaders;