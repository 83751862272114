import React,{useState} from 'react';
import {useNavigate,Link } from "react-router-dom";
import logo from "../assets/images/main-logo.png"
import car from "../assets/images/cloud-cars.png";
import { IconContext } from "react-icons";
import {BiUser} from "react-icons/bi"
import {_APIS} from "./common/API/APi";
import {success,error} from "./common/Toastify"; 
import { ToastContainer } from "react-toastify";
import { HiOutlineLogin } from "react-icons/hi";

const ForgotPassword = () =>{
  const navigate=useNavigate();
  const [inputs,setInputs]= useState({'email':''});
  const [errors, setError] = useState();

  const SentEmail=async()=>{
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
               if(!inputs?.email || !emailRegex.test(inputs?.email)){
                 setError({ email: true });
                  return;
               }  
             setError();
             let res = await _APIS(inputs, "forgotpassword");
            if(res.status===200){
              success(res?.message);
              setTimeout(() => {
                navigate("/");
              }, 3000);
            }else{
              error(res?.message);
            }
  }
  return( 
    <>
    <ToastContainer></ToastContainer>
<div className="form-design main-page d-flex align-items-center">
<img className="w-100 h-100 form-design-bg" src={car} alt="black cars"/>
<div className="form-design-inner position-relative mx-auto row">
  <div className="form-design-inner-left d-flex align-items-center justify-content-center col-md-5 position-relative">
    <img src={logo} className="d-block mx-auto" alt="main-logo"/>
    <div className="dot rounded-circle position-absolute"></div>
  </div>
  <div className="form-design-inner-right d-flex align-items-center justify-content-center col-md-7 position-relative">
    <div className="dot rounded-circle position-absolute"></div>
    <div className="form-design-inner-right-inner w-100 text-center">
      <h1 className="form-design-inner-right-inner-heading mb-5">Forgot Password</h1>
      <div className="form-design-inner-right-inner-input mb-4">
        <IconContext.Provider value={{ className: "form-design-inner-right-inner-input-left-icon" }}>
          <BiUser/>
        </IconContext.Provider>

        <input type="email" onChange={(e)=>setInputs({...inputs,'email':e.target.value})} value={inputs?.email}  className={errors?.email? " font-13px pe-2 w-100  border-danger" : "font-13px pe-2 w-100 "} placeholder="Email"/>

      </div>
      <div className="text-end mb-5">
        <Link className="text-decoration-none mt-3 fw-semibold form-design-inner-right-inner-link" to="/"><HiOutlineLogin /> Sign In </Link>
      </div>
      <button className="btn form-design-inner-right-inner-btn golden-bg" onClick={SentEmail}>Submit</button>
    </div>
  </div>
</div>

</div>
</>
  )
}

export default ForgotPassword;