import React from "react";

const BookingIcon = () => {
  return (
    <div>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet">
   
   <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
   fill="#000000" stroke="none">
   <path d="M960 4845 c-60 -19 -109 -62 -145 -126 l-30 -54 0 -2100 0 -2100 21
   -45 c30 -64 65 -102 120 -130 l49 -25 1144 -3 c1276 -3 1214 -6 1292 65 22 20
   50 55 62 77 22 41 22 46 25 649 l3 608 77 -3 77 -3 5 -62 c6 -68 26 -104 80
   -140 31 -22 44 -23 193 -23 146 0 163 2 199 22 52 30 77 72 85 147 6 59 8 63
   42 81 43 22 82 62 106 109 15 30 16 67 13 355 -5 340 -4 332 -63 531 -5 17 1
   23 30 34 84 30 143 113 152 212 5 52 3 60 -20 83 -22 22 -35 26 -79 26 -29 0
   -84 -7 -122 -15 -38 -8 -70 -14 -71 -12 -1 1 -33 101 -70 222 -77 249 -97 295
   -152 346 -86 80 -151 99 -345 99 l-137 0 -3 503 c-3 494 -3 503 -25 543 -12
   22 -40 57 -62 77 -78 70 -16 67 -1275 66 -939 0 -1139 -3 -1176 -14z m1218
   -157 c7 -7 12 -24 12 -38 0 -47 -11 -50 -205 -50 -194 0 -205 3 -205 50 0 47
   11 50 205 50 127 0 185 -4 193 -12z m316 -4 c20 -20 20 -48 0 -68 -24 -25
   -120 -22 -134 3 -26 50 1 81 70 81 31 0 54 -6 64 -16z m906 -631 l-1 -388 -49
   96 c-74 142 -71 141 -353 137 l-224 -3 -36 -27 c-25 -20 -48 -55 -78 -117
   l-42 -90 -226 -3 c-216 -3 -228 -4 -276 -27 -59 -30 -126 -94 -153 -148 -10
   -21 -50 -137 -87 -258 -38 -121 -70 -222 -71 -224 -2 -2 -42 4 -89 14 -147 29
   -205 2 -205 -94 1 -86 68 -180 153 -216 30 -12 37 -20 32 -33 -24 -61 -58
   -224 -65 -322 -13 -154 -12 -444 0 -511 13 -70 54 -128 113 -159 44 -23 47
   -28 47 -65 0 -62 17 -108 52 -142 49 -47 75 -53 232 -53 130 0 147 2 186 23
   56 29 90 90 90 159 l0 48 520 0 520 0 0 -435 0 -435 -1250 0 -1250 0 0 1830 0
   1830 1255 0 1255 0 0 -387z m416 -575 c50 -27 92 -83 113 -150 10 -35 64 -207
   120 -384 56 -176 101 -335 101 -353 0 -25 -6 -34 -26 -41 -34 -13 -2193 -14
   -2227 -1 -50 19 -58 -15 159 669 67 211 97 253 194 273 19 4 373 6 785 6 685
   -2 753 -3 781 -19z m-494 -1268 c20 -6 47 -23 59 -38 19 -24 21 -35 16 -102
   -8 -96 -21 -142 -54 -181 -47 -56 -67 -59 -343 -59 -230 0 -254 2 -290 20 -55
   28 -78 67 -99 167 -25 120 -8 172 63 192 44 13 603 13 648 1z m-1228 -110 c97
   -22 167 -129 117 -179 -19 -19 -32 -21 -169 -21 -172 0 -182 4 -189 87 -6 58
   9 100 39 113 30 12 152 12 202 0z m2039 -9 c13 -10 17 -31 17 -87 0 -102 -4
   -104 -187 -104 -132 0 -145 2 -164 21 -48 48 19 156 112 179 53 14 203 7 222
   -9z m-1745 -1533 c19 -19 15 -66 -7 -78 -12 -6 -108 -10 -242 -10 -193 0 -225
   2 -241 17 -20 18 -23 36 -8 64 10 18 24 19 248 19 172 0 241 -3 250 -12z"/>
   </g>
   </svg>
    </div>
  );
};

export default BookingIcon;
