import React, { useRef, useEffect,useState } from "react";
import Chart from 'chart.js/auto';
import DataTable from 'react-data-table-component';
import OrderCard from "./common/analyticsBox/OrderCard";
import { BiSearch } from "react-icons/bi";
import {CallApi} from "./common/API/APi";
import DataTableLoaders from "./common/DatatableLoader";
import DeleteModal from './modal/DeleteModal';
import Moment from 'react-moment';
import {success,error} from "./common/Toastify";
import Select from 'react-select';
import SendEmailModal from "./modal/SendemailModal";
import SendMessage from "./modal/SendMessage";
import ReminderNoteModal from "./modal/ReminderNotesmodal";
import { DateRange } from "./modal/ComDatePicker";
import { Link, useLocation } from "react-router-dom";
import AssignDriverModal from "./modal/AssignDriver";
import GenrateInvoice from "./modal/GenrateInvoice";
import BulksendEmail from "./modal/Bulksendmail";
import BulksendMessage from "./modal/Bulksendmessage";
import RefundAmount from "./modal/OrderRefund";
import CallModal from "./modal/CallModal";
import { FaCarOn } from "react-icons/fa6";
import { TbMailPlus,TbMessageCirclePlus  } from "react-icons/tb";
import TrackDriver from './modal/TrackDriver.js';
import ical from 'ical-generator';
import moment from "moment-timezone";


function Orders() {
  const {state} = useLocation();
  const scrollRef = useRef();
  const profiledata= JSON.parse(localStorage.getItem('profiledata'));
  const {customer_id} = state || { customer_id: null };
  const {driver_id} = state || { driver_id: null };
  const {bookingfilters} = state || { bookingfilters: null };
  const {paidstatusfilter} = state || { paidstatusfilter: null };
  const chartInstance = useRef(null);
  const [datatableloder,setDatatableloder]=useState();
  const [transelect,setTranselect]=useState(paidstatusfilter?paidstatusfilter:'');
   const [orderdata,setOrderdata]=useState();
  const [notifyrole,setNotifyrole]=useState();
  const [deleteItem,setDeleteItem]=useState();
  const [reminderItem,setReminderItem]=useState();
  const [assigndriverid,setAssigndriverid]=useState();
  const [EmailItem,setEmailItem]=useState();
  const [search,setSearch]=useState('');
  const [datesearch,setDateSearch]=useState([]);
  const [customerdata,setCustomerdata]=useState([]);
  const [driverdata,setDriverdata]=useState([]);
  const [MessageItem,setMessageItem]=useState();
  const [graphdata,setGraphdata]=useState();
  const [countdata,setCountdata]=useState();
  const [bulkmails,setBulkmails]=useState([]);
  const [refunditem,setRefundItem]=useState();
  const [callitem,setCallItem]=useState();
  const [genratedinvoice,setGenratedinvoice]=useState();
  const [customermailid,setCustomermailid]=useState(customer_id?customer_id:"");
  const [driversid,setDriversid]=useState(driver_id?driver_id:"");
  const [staffid,setStaffid]=useState();
  const [orderfiltervalue,setorderfilter]=useState(bookingfilters?bookingfilters:"");
  const [staffdata,setStaffdata]=useState();
  const [trackorderid,setTrackorderid]=useState();
  const [platfrom,setPlatfrom]=useState();
  const frontend_path = process.env.REACT_APP_FRONTEND_URL;
  const paginationRowOptions = [50, 100, 500, 1000];
  const [limit, setLimit] = useState(paginationRowOptions[0]);
  const [TotalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const transcationoption=[ 
    { value: '', label: 'Select' },
    { value: 1, label: 'Paid' },
    { value: 2, label: 'UnPaid' }]

    const platfromoption=[ 
      { value: '', label: 'Select' },
      { value: "ios", label: "IOS App Side" },
      { value: "android", label: 'Android App Side' },
      { value: "web", label: 'Web Side' }
    ]

    const orderfilteroption=[ 
      { value: '', label: 'Select' },
      { value: 'leads', label: 'Lead Orders' },
      { value: 'upcoming', label: 'Upcomings Orders' },
      { value: 'bookdone', label: 'Completed Orders' }]

      const Checkbox = React.forwardRef(function Checkbox({onChange,row,...rest }, ref) {
        return (
            <>
                <div className="form-check com-table-check-box" style={{ backgroundColor: '' }}>
                    <input
                        type="checkbox"
                        className="form-check-input"
                        style={{ height: '20px', width: '20px' }}
                        onChange={(e) => onChange(e, row)}
                        ref={ref}
                        {...rest}
                    />
                  
                </div>
            </>
        );
    });

  const handleSelectedRowsChange = ({ selectedRows }) => {
    let array=[];
    if(selectedRows?.length){
      selectedRows.map((value)=>{
          array.push({'mail':value?.email,'phone':value?.phone});
      })
    }
    setBulkmails(array);
  }

  const copiedlink=(id)=>{
   const linkToCopy= frontend_path+id;
    navigator.clipboard.writeText(linkToCopy)
  }

  function formatDateAndTime(dateString, timeString,isdate) {
    const [time, period] = timeString.split(" ");
    let [hours, minutes] = time.split(":").map(Number);
    if (period === "PM" && hours !== 12) hours += 12;
    if (period === "AM" && hours === 12) hours = 0;
    const dateTimeString = `${dateString}T${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00`;
    const date = new Date(dateTimeString);
    const hoursFormatted = String(date.getHours() + (isdate === 'end' ? 1 : 0)).padStart(2, '0');
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const minutesFormatted = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day}T${hoursFormatted}:${minutesFormatted}:${seconds}`;
}

const addtocalendar = (obj) => {
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().split('T')[0];
  const details = `
    ${obj.name}
    pickup date and time
    ${obj.date} ${obj.time}
    tel: ${obj.phone}

    ${ obj.fromloc} 
    ${obj.flightDetails?`flight details: #${obj.flightDetails}`:''}
    ${obj.meet!=0 && obj.meet? 'MEET AND GREET' : ''}
    drop off

    ${ obj.toloc}
    ${obj.nopass} passenger ${obj.nolugg} luggage
    ${obj.carname} $${obj.amount}

    ${obj.othername ? obj.othername : ''}
  `;
  const returndetails = `
  ${obj.name}
  return date and time
  ${obj.returndate} ${obj.returntime}
  tel: ${obj.phone}

  ${obj.toloc}
  ${obj.returnflight?`return flight details: #${obj.returnflight}`:''}
  ${obj.meet!=0 && obj.meet? 'MEET AND GREET' : ''}
  drop off

  ${obj.fromloc}
  ${obj.nopass} passenger ${obj.nolugg} luggage
  ${obj.carname} $${parseFloat(obj.amount/2).toFixed(2)}

  ${obj.othername ? obj.othername : ''}
`;
  const isIOS = /iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent) && !window.MSStream;
  if ((formattedDate <= obj.date) || (obj.returnbook == 1 && formattedDate <= obj.returndate)) {
    if (isIOS) {
      handleGenerateICS(obj,details,returndetails);
    } else {
      const a = document.createElement("a");
      const start=formatDateAndTime(obj.date, obj.time, "");
      const end= formatDateAndTime(obj.date, obj.time, "end")
      a.href = 'http://www.google.com/calendar/event?action=TEMPLATE&text=' + encodeURIComponent(obj.name) + ' ' + encodeURIComponent(obj.email) + '&dates=' + start.replaceAll('-','').replaceAll(':','') + '/' + end.replaceAll('-','').replaceAll(':','') + '&details=' + encodeURIComponent(details) + '&location=' + encodeURIComponent(obj.fromloc);
      a.target = "_blank";
      a.click();

      if (obj.returnbook == 1 && formattedDate <= obj.returndate) {
        const a = document.createElement("a");
        const start=formatDateAndTime(obj.returndate, obj.returntime, "");
        const end= formatDateAndTime(obj.returndate, obj.returntime, "end");
        a.href = 'http://www.google.com/calendar/event?action=TEMPLATE&text=' + encodeURIComponent(obj.name) + ' ' + encodeURIComponent(obj.email) + '&dates=' + start.replaceAll('-','').replaceAll(':','')  + '/' + end.replaceAll('-','').replaceAll(':','') + '&details=' + encodeURIComponent(returndetails) + '&location=' + encodeURIComponent(obj.toloc);
        a.target = "_blank";
        a.click();
      }
    }
  }else {
    error("You can't create an event now!");
  }
};


const handleGenerateICS = (obj,details,returndetails) => {
  const calendar = ical();
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().split('T')[0];
  
  const startDate = new Date(formatDateAndTime(obj.date, obj.time));
  const startEvent = {
    start: new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startDate.getHours(), startDate.getMinutes()),
    end: new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startDate.getHours() + 1, startDate.getMinutes()),
    summary: obj.name + ' - Pickup',
    description: details,
    location: obj.fromloc,
  };
  calendar.createEvent(startEvent);

  if (obj.returnbook == 1 && formattedDate <= obj.returndate ) {
    const returnDate = new Date(formatDateAndTime(obj.returndate, obj.returntime));
    const returnEvent = {
      start: new Date(returnDate.getFullYear(), returnDate.getMonth(), returnDate.getDate(), returnDate.getHours(), returnDate.getMinutes()),
      end: new Date(returnDate.getFullYear(), returnDate.getMonth(), returnDate.getDate(), returnDate.getHours() + 1, returnDate.getMinutes()),
      summary: obj.name + ' - Return',
      description: returndetails,
      location: obj.toloc,
    };
    calendar.createEvent(returnEvent);
  }

  const icsData = calendar.toString();
  const blob = new Blob([icsData], { type: 'text/calendar' });
  const url = URL.createObjectURL(blob);
  window.open(url);
};

    const columns = [
      {name: 'S.No.',selector: row => row.sno,sortable: true,minWidth: "80px"},
      {name: 'Transcation Id', selector: row => row.transid, sortable: true, minWidth: "220px" },
      {name: 'Tracking Id',  selector: row => row.trackid, sortable: true,minWidth: "250px"},
      {name: 'Booking Created At',selector: row => row.BookingCreatedAt,sortable: true,minWidth: "180px"},
      {name: 'Customer Name',selector: row => row.customer,sortable: true,minWidth: "175px"},
      {name: 'Email Id',selector: row => row.email,sortable: true,minWidth: "240px"},
      {name: 'Phone',selector: row =><>{<a style={{"text-decoration": "none","color":" #000","cursor": "pointer"}} href={`tel:${row.phone}`}>{row.phone}</a>}</> ,sortable: true,minWidth: "120px"},
      {name: 'Special Note',sortable: true,minWidth: "180px", selector: (row) => <><td className ='tooltip-area'><span className ='location-td'>{row.specialNote }</span><span className='tooltip-text'>{ row.specialNote }</span></td> </> },
      {name: 'Pickup Date',selector: row => row.pickupDate,sortable: true,minWidth: "190px" },
      {name: 'Pickup Location',sortable: true,minWidth: "180px", selector: (row) => <><td className ='tooltip-area'><span className ='location-td'>{row.pickupLocation }</span><span className='tooltip-text'>{ row.pickupLocation }</span></td> </> },
      {name: 'To Location',sortable: true,minWidth: "180px", selector: (row) => <><td className ='tooltip-area'><span className ='location-td'>{row.toLocation }</span><span className ='tooltip-text'>{ row.toLocation }</span></td> </> },
      {name: 'Return Booking', sortable: true,minWidth: "190px", selector: (row) => <><td className ='tooltip-area'><span className ='location-td'>{row.returnbooking }</span><span className='tooltip-text'>{ row.returnbooking }</span></td> </>},
      {name: 'Booking Car',selector: row => row.bookingCar,sortable: true,minWidth: "240px"},
      {name: 'Booking Type',selector: row => row.bookingType,sortable: true,minWidth: "130px"},
      {name: 'No. Luggage',selector: row => row.luggage,sortable: true,minWidth: "140px"},
      {name: 'No. Passenger',selector: row => row.no_of_pass,sortable: true,minWidth: "140px"},
      {name: 'Passengers Info',sortable: true ,minWidth: "220px", selector: (row) => <><td className='tooltip-area'><span className='location-td'>{row.passengersInfo }</span><span className='tooltip-text'>{ row.passengersInfo }</span></td> </>},
      {name: 'Event Surge Charges',selector: row => row.surgecharge,sortable: true,minWidth: "200px"},
      {name: 'Early Morning and Late Night Surge',selector: row => row.timesurgecharge,sortable: true,minWidth: "270px"},
      {name: 'Meet & Greet Charges',selector: row => row.meetgreet,sortable: true,minWidth: "200px"},
      {name: 'Flight Details',selector: row => row.flightDetails,sortable: true,minWidth: "170px"},
      {name: 'Payment Status',selector: row => row.paymentStatus,sortable: true,minWidth: "150px"},
      {name: 'Billing Address',selector: row => row.billingAddress,sortable: true,minWidth: "180px"},
      {name: 'Total Amount',selector: row => row.totalAmount,sortable: true,minWidth: "150px"},
      {name: 'Total Return Amount',selector: row => row.totalReturnAmount,sortable: true,minWidth: "150px"},
      {name: 'Staff Name',selector: row => row.staff_name,sortable: true,minWidth: "175px"},
      {name: 'Driver Name',selector: row => row.drivername,sortable: true,minWidth: "175px"},
      {name: 'Driver Cost',selector: row =><>{row.drivercost?'$'+row.drivercost:''}</> ,sortable: true,minWidth: "175px"},
      {name: 'Return Trip Driver Name',selector: row => row.returndrivername,sortable: true,minWidth: "175px"},
      {name: 'Return Trip Driver Cost',selector: row =><>{row.returndrivercost?'$'+row.returndrivercost:''}</> ,sortable: true,minWidth: "175px"},
      {name: 'Payment Source',selector: row => row.paymentsource,sortable: true,minWidth: "180px"},
      {name: 'Refund Amount',selector: row =>row.refund ,sortable: true,minWidth: "175px"},
      
      {name: 'Driver Booking Status',selector: row => row.dbookstatus,sortable: true,minWidth: "175px"},

      {name: 'Driver Return Booking Status',selector: row => row.returnbookstatus,sortable: true,minWidth: "185px"},
      { name: 'Action',sortable: false , minWidth: '450px',
      selector: (row) => <>{profiledata?.permission.filter(e => e.name === 'Order')[0]?.permission?.view === 1 ? !row?.action?.transcation_id ?<button className="cell-tabs orange-bg text-white" ><Link className="text-decoration-none text-white" target="_blank" to={frontend_path+row?.action?.id}>View</Link></button> :'':''}

      {profiledata?.permission.filter(e => e.name === 'Order')[0]?.permission?.assign === 1 ?  row?.action?.transcation_id? (row?.action?.booking_status != 2 && row?.action?.booking_status != 1 && row?.action?.booking_status != 7 && row?.action?.return_booking_status != 2) || (row?.action?.return_booking_status != 7 && row?.action?.return_booking_status != 1)   ?<button className="cell-tabs text-white assign-bg" onClick={()=>setAssigndriverid({order_id:row?.action?.id,driver_id:row?.action?.driver_id,assignmodelid:row?.action?.assignmodelid,assignamount:row?.action?.assignamount,return_drivervehicle_model:row?.action?.return_driver_vehicle_model,return_driver_amount:row?.action?.return_driver_amount,bookreturn:row?.action?.returnbooking,return_driverid:row?.action?.return_driver_id})}  data-bs-toggle="modal"  data-bs-target="#assigndrivermodal">Assign Driver</button>:'':'':''}

      {profiledata?.permission.filter(e => e.name === 'Order')[0]?.permission?.mail === 1 ?<button className="cell-tabs bg-success text-white "  data-bs-toggle="modal" onClick={()=>setEmailItem({'email':row?.action?.email})} data-bs-target="#SendEmailModal">Send Email</button>:''}

      {profiledata?.permission.filter(e => e.name === 'Order')[0]?.permission?.message === 1 ? <button className="cell-tabs bg-secondary text-white" data-bs-toggle="modal" onClick={()=>setMessageItem({'to':row?.action?.phone})} data-bs-target="#SendMessageModal">Send Message</button>:''} 

      <button className="cell-tabs bg-warning text-white " onClick={()=>setReminderItem({'id':row?.action?.id,'name':row?.action?.name})}  data-bs-toggle="modal" data-bs-target="#reminderNoteModal"> Reminder Notes</button>

      {profiledata?.permission.filter(e => e.name === 'Order')[0]?.permission?.invoice === 1 ?row?.action?.transcation_id !=''? <> <button className="cell-tabs bg-info text-white" onClick={()=>setGenratedinvoice({'id':row?.action?.id,'transcation_id':row?.action?.transcation_id})} data-bs-toggle="modal" data-bs-target="#genrateinvoicemodal"> Genrate Invoice</button><button className="cell-tabs bg-primary text-white" onClick={()=>downloadZeroinvoice(row?.action?.id)}> Download Zero Invoice</button></> :'':''}

      {profiledata?.permission.filter(e => e.name === 'Order')[0]?.permission?.delete === 1 ? <button className="cell-tabs mx-3 bg-danger text-white"  data-bs-toggle="modal" onClick={()=>setDeleteItem(row?.action?.id)} data-bs-target="#deleteitemModal">Delete</button>:""}

      {profiledata?.permission.filter(e => e.name === 'Order')[0]?.permission?.refund === 1 ?row?.action?.transcation_id !== '' && row?.action?.booking_status !== 7 && row?.action?.return_booking_status !== 7 ?  <button className="cell-tabs mx-3 refund-bg text-white"  data-bs-toggle="modal" onClick={()=>setRefundItem({'id':row?.action?.id,'chargeid':row?.action?.transcation_id,'bookingamount':row?.action?.bookingamount,'totalrefundamount':row?.action?.totalrefundamount,'payment_mode':row?.action?.payment_mode})} data-bs-target="#orderRefundModal">Refund</button>:"":''}

      {!row?.action?.transcation_id?<button className="cell-tabs mx-3 customercall-bg text-white"  data-bs-toggle="modal" onClick={()=>setCallItem(row?.action?.id)} data-bs-target="#customercallModal">Customer Care Call Logs</button>:''}

      { row?.action?.booking_status==3 || row?.action?.booking_status==4 || row?.action?.booking_status==1 || row?.action?.return_booking_status==3 || row?.action?.return_booking_status==4 || row?.action?.return_booking_status==1?<button className="cell-tabs mx-3 trackdriver-btn text-white"  data-bs-toggle="modal" onClick={()=>setTrackorderid({'id':row?.action?.id,'return_order':row?.action?.return_booking_status==3 || row?.action?.return_booking_status==4 || row?.action?.return_booking_status==1?1:0})} data-bs-target="#Trackdrivermodal">Track Driver</button>:<button className="cell-tabs mx-3 trackdriver-btn text-white"   onClick={()=>error('Not access this now ')} >Track Driver</button> }

      {profiledata?.permission.filter(e => e.name === 'Order')[0]?.permission?.edit === 1 ? row?.action?.transcation_id !='' ?<button className="cell-tabs mx-3 addcalendarbtn text-white"  onClick={()=>addtocalendar({name:row?.action?.name,email:row?.action?.email,fromloc:row?.action?.fromlocation,toloc:row?.action?.tolocation,date:row?.action?.date,time:row?.action?.time,returnbook:row?.action?.returnbooking,returndate:row?.action?.returndate,returntime:row?.action?.returntime,phone:row?.action?.phone,
        notes:row?.action?.row,meet:row?.action?.meet,nopass:row?.action?.nopass,nolugg:row?.action?.nolugg,carname:row?.action?.carname,amount:row?.action?.bookingamount,othername:row?.action?.othername,flightDetails:row?.action?.flightDetails,order_id:row.action.id,returnflight:row?.action?.returnflight})} >Add To Calendar</button> :'':''}

      {!row?.action?.transcation_id?<button className="cell-tabs mx-3 copylinkbtn text-white"  onClick={()=>copiedlink(row?.action?.id)} >Copy Order Link</button>:""}
      </>
      }
    ];

    
  
  const ordersChart = () => {
    const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
    const datapoints1 = driver_id?graphdata?.PaidOrders:graphdata?.PaidOrders;
    const datapoints2 = driver_id?graphdata?.totalOrders:graphdata?.totalOrders;
    const data = {
      labels: labels,
      datasets: [
        {
            label: 'Paid Orders',
            data: datapoints1,
            borderColor: "#507ae9",
            shadowBlur: 10,
            borderWidth: 2,

        },
        {
            label: 'Total Orders',
            data: datapoints2,
            borderColor: "#000",
            borderWidth: 2,
          }
      ]
    };
    const ctx = document.getElementById("ordersChartRef").getContext('2d');
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }
    chartInstance.current = new Chart(ctx, {
      type: 'line',
      data: data,
      options: {
        responsive: true,
        width: "100%",
        height: 300,
        maintainAspectRatio: false,
        elements: {
          point: {
            radius: 0
          }
        },
        plugins: {
          legend: {
            display: false
          },
          title: {
            display: false,
            text: 'Chart.js Line Chart - Cubic interpolation mode'
          }
        },
        interaction: {
          intersect: false
        },
        scales: {
          x: {
            display: true,
            title: {
              display: true
            },
            ticks: {
              font: {
                size: 11,
              }
            }
          },
          y: {
            title: {
              display: false,
              text: 'Value'
            },
            grid: {
              display: false
            },
            suggestedMin: 0,
            suggestedMax: 160,
            ticks: {
              font: {
                size: 11,
              }
            }
          }
        },
      }
    });
  }

   const getOrders=async()=>{
    const skip = (currentPage - 1) * limit;
    setDatatableloder(true);
    const postdata={"search":search,"status":transelect?.value?transelect?.value:'',"start_date": datesearch[0]?datesearch[0]:'',
    "end_date": datesearch[1]? datesearch[1]:'','email':customermailid?.value?customermailid?.value:'',driver_id:driversid?.value?driversid?.value:'',staff_id:staffid?.value?staffid?.value:'',bookingfilter:orderfiltervalue?.value?orderfiltervalue?.value:'',limit:limit,source:platfrom?.value?platfrom?.value:'',skip:skip };
      let res = await CallApi(postdata, "getorders");
      if(res?.status === 200){
        let array=[];
        res?.data.map((value,key)=>{
          let trackid='';
          if(value?.tracking_id){
            let arrayoftrack = JSON.parse(value?.tracking_id);
            trackid = ''+Object.keys(arrayoftrack)[0]+' = '+Object.values(arrayoftrack)[0]+'';
          }
          const BookingCreatedAt = moment.utc(value?.bookingCreatedAt).tz("America/New_York");
          array.push({
                      "sno":key+1,
                      "customer":value?.customer,
                      "transid":value?.transcation_id?value?.transcation_id:'',
                      "trackid":trackid,
                      "BookingCreatedAt":<Moment format="MMM DD, YYYY : hh:mm A" >{BookingCreatedAt}</Moment>,
                      "email":value?.email,
                      "phone":value?.phone,
                      "specialNote":value?.specialNote,
                      "pickupDate":<><Moment format="MMM DD, YYYY">{value?.pickupDate}</Moment>: {value?.pickupTime}</>,
                      "pickupLocation":value?.pickupLocation,
                      "toLocation":value?.toLocation,
                      "returnbooking":value?.returnBook ==1?'Return Date : '+value?.returndate+' Return Time : '+value?.returntime+' '+(value?.returnflight?'Return Flight : '+value?.returnflight+'':'')+'':'',
                      "bookingCar":value?.bookingCar,
                      "bookingType":value?.bookingType,
                      "luggage":value?.luggage,
                      "no_of_pass":value?.passengers,
                      "passengersInfo":value?.is_passengers ==1?'I am the Passenger':(value?.is_passengers==0?'I am not the Passenger '+value?.passengersdetails+'':""),
                      "surgecharge":value?.surgecharges?"$"+value?.surgecharges:'',
                      "timesurgecharge":value?.timesurgecharge?"$"+value?.timesurgecharge:'',
                      "meetgreet":value?.meetandgreet?"$"+value?.meetandgreet:"",
                      "flightDetails":value?.flightDetails,
                      "paymentStatus": <span className={`payment-status ${value?.transcation_id ? 'paid-label' : 'unpaid-label'}`}>
                      {value?.transcation_id ? 'Paid' : 'UnPaid'}</span>,
                      "billingAddress":value?.billingAddress,
                      "totalAmount": value?.totalAmount?"$"+value?.totalAmount:'',
                      "totalReturnAmount":value?.returnBook ==1?(value?.totalAmount?"$"+parseFloat(value?.totalAmount/2).toFixed(2):''):'',
                  
                      'staff_name': value?.staff_name?value?.staff_name:'',
                      'drivername': value?.driver?value?.driver?.label:'',
                      'drivercost':value?.assignamount,
                      'returndrivername':value?.return_driver_id?.label?value?.return_driver_id?.label:'',
                       'returndrivercost':value?.return_driver_amount,
                       'paymentsource':value?.payment_mode?value?.payment_mode:'',
                      'refund': value?.refund?.length ? value.refund.map((revalue, index) => (
                      <React.Fragment key={index}>
                        Amount: $<span>{revalue?.amount}</span><br/>
                        Comment: <span>{revalue?.comment}</span><br/>
                      </React.Fragment>
                    )) : '',

                      'dbookstatus':   <span className={`payment-status ${value?.booking_status == 1 ? "paid-label" :(value?.booking_status == 0 ?   "pending-label":value?.booking_status == 3?"ready-label": value?.booking_status == 4?"pickup-label":value?.booking_status == 5?"accept-label": value?.booking_status == 6?"notaccept-label":value?.booking_status == 7?"refunded-label":value?.booking_status == 8?"aftertime-label":"unpaid-label")}`}>
                      {value?.booking_status ==1 ? "Booking Completed" :(value?.booking_status == 0 ?"Booking Pending":value?.booking_status == 3?"Ready To Start":value?.booking_status == 4?"Ready To Pickup":value?.booking_status == 5?"Accepted By Driver":value?.booking_status == 6?"Driver Not Accept ":value?.booking_status == 7?" Amount Refunded":value?.booking_status == 8?"Pickup Time Gone" : "Booking Canceled")}
                      </span>,
                      'returnbookstatus': <span className={`payment-status ${value?.return_booking_status == 1 ? "paid-label" :(value?.return_booking_status == 0 ?   "pending-label":value?.return_booking_status == 3?"ready-label": value?.return_booking_status == 4?"pickup-label":value?.return_booking_status == 5?"accept-label": value?.return_booking_status == 6?"notaccept-label":value?.return_booking_status == 7?"refunded-label":value?.return_booking_status == 8?"aftertime-label":"unpaid-label")}`}>
                      {value?.return_booking_status ==1 ? "Booking Completed" :(value?.return_booking_status == 0 ?"Booking Pending":value?.return_booking_status == 3?"Ready To Start":value?.return_booking_status == 4?"Ready To Pickup":value?.return_booking_status == 5?"Accepted By Driver":value?.return_booking_status == 6?"Driver Not Accept ":value?.return_booking_status == 7?" Amount Refunded":value?.return_booking_status == 8?"Pickup Time Gone" : "Booking Canceled")}
                      </span>,

                      "action":{'id':value?.order_id,'email':value?.email,'transcation_id':value?.transcation_id,'phone':value?.phone,'driver_id':value?.driver,'booking_status':value?.booking_status,'name':value?.customer,'baseamount':value?.base_amount,
                      'staff_name':value?.staff_name,assignmodelid:value?.vehicle_model,assignamount:value?.assignamount,'bookingamount':value?.totalAmount,'totalrefundamount':value?.totalrefundamount,'payment_mode':value?.payment_mode,'fromlocation':value?.pickupLocation,'tolocation':value?.toLocation,
                      "date":value?.pickupDate,"time":value?.pickupTime,"returndate":value?.returndate,"returntime":value?.returntime,"returnbooking":value?.returnBook,"notes":value?.flightDetails,"meet":value?.meetandgreet,"nopass":value?.passengers,'nolugg':value?.luggage,"othername":value?.passengersdetails,"carname":value?.bookingCar,"flightDetails":value?.flightDetails,"returnflight":value?.returnflight,"return_driver_vehicle_model":value?.return_driver_vehicle_model,return_driver_amount:value?.return_driver_amount,return_driver_id:value?.return_driver_id,return_booking_status:value?.return_booking_status}
          })
        })
        setOrderdata(array);
        setTotalRows(res?.TotalRows);
        setDatatableloder(false);
        
      }else{
        error("No data Found!");
      }
   }
   const getcustomer=async()=>{
    let res = await CallApi({"search":""}, "getcustomers");
    if(res?.status === 200){
      if(res?.data?.length){
        let array=[];
        array.push({ value:'', label: 'Select'});
        res?.data.map((value)=>{
          array.push({ value:value?.email, label: value?.customername });
        });
        setCustomerdata(array) ;
      }
        
    }
   }
   const getdriverdata =async()=>{
    let res = await CallApi({},'getdriver');
    if(res?.status === 200){
      if(res?.data?.length){
        let array=[];
         array.push({ value:'', label: 'Select'});
        res?.data.map((value)=>{
          array.push({'label':value?.name,'value':value?.id});
        })
        setDriverdata(array)

      }
    }
  }
  const getstaffdata =async()=>{
    let res = await CallApi({status: 1,user_type: 1},'getadmins');
    if(res?.status === 200){
      if(res?.data?.length){
        let array=[];
         array.push({ value:'', label: 'Select'});
        res?.data.map((value)=>{
          array.push({'label':value?.name,'value':value?.id});
        })
        setStaffdata(array)

      }
    }
  }
 
   const downloadZeroinvoice=async(id)=>{
    success("Invoice will send in your mail!");
    let res = await CallApi({"id":id}, "zeroinvoice");
    if(res?.status != 200){
      error("Something Went Wrong!");
    }
   }
 
   const getgraphorders=async()=>{
    let res = await CallApi({'driver_id':driver_id?.value,"start_date": datesearch[0]?datesearch[0]:'',
    "end_date": datesearch[1]? datesearch[1]:'',}, "orderanalytic");
    if(res?.status === 200){
      setGraphdata(res?.analytic);
      setCountdata(res?.Counts);
    
    }
   }
    useEffect(()=>{
      getOrders();
    },[transelect,notifyrole,datesearch,customermailid,driversid,staffid,orderfiltervalue,limit,platfrom,currentPage])
    useEffect(()=>{
      getgraphorders();
    },[notifyrole,datesearch])
   
   
  useEffect(() => {
    getcustomer();
    getdriverdata();
    getstaffdata();
  }, []);

  useEffect(() => {
    if (customer_id) {
      const timeoutId = setTimeout(() => {
        scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 500);

      return () => clearTimeout(timeoutId);
    }
  }, [customer_id]);
  
  useEffect(() => {
    if (bookingfilters) {
      const timeoutId = setTimeout(() => {
        scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 500);

      return () => clearTimeout(timeoutId);
    }
  }, [bookingfilters]);

  useEffect(() => {
    if (driver_id) {
      const timeoutId_driver = setTimeout(() => {
        scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 500);

      return () => clearTimeout(timeoutId_driver);
    }
  }, [driver_id]);

  useEffect(() => {
    ordersChart();
  }, [graphdata]);

 
  return (
    <div className="main-page-body position-relative">
      <div className="col-lg-9 col-md-9 ms-auto col-sm-12 col-12 order-datatable-filter-container">
          <button type="button" className="btn font-13px golden-bg d-flex align-items-center justify-content-center gap-2 text-nowrap com-btn ms-auto mb-3 me-2"><Link className="text-decoration-none text-black" target="_blank" to={frontend_path+profiledata?.id}><FaCarOn/> Create New Booking</Link></button>
        <div className="mb-2 me-2 orderdate-div">
            <DateRange setDateSearch={setDateSearch} setCurrentPage={setCurrentPage} isorder={1} />
         </div>
      </div>
   
      <div className="com-card-box">
        <h1 className="com-heading">Orders</h1>
        <div className="d-flex orders-container-chart-box">
          <canvas
            id="ordersChartRef"
            className="orders-container-chart-box-canvas"
            style={{ width: "100%" }}
          ></canvas>
        </div>
      </div>
      <div className="row com-card-box mx-0">
          <OrderCard   countdata={countdata} driver_id={driver_id} setorderfilter={setorderfilter} setTranselect={setTranselect} />
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="com-card-box d-flex gap-2  align-items-center justify-content-end ">
          {bulkmails?.length?
                    <>
                  <button type="button"  data-bs-target="#BulksendEmailModal" data-bs-toggle="modal"  className="btn font-13px golden-bg d-flex align-items-center justify-content-center gap-2 golden-btn-respn-show"><TbMailPlus /> Send Email</button>
                  <button type="button"  data-bs-target="#BulksendMessageModal" data-bs-toggle="modal"  className="btn font-13px golden-bg d-flex align-items-center justify-content-center gap-2 golden-btn-respn-show"><TbMessageCirclePlus /> Send Message</button>
                  </>
                   :
                   <>
                   <button type="button" onClick={()=>error('No email ids is selected!')}  className="btn font-13px golden-bg d-flex align-items-center justify-content-center gap-2 golden-btn-respn-show"><TbMailPlus /> Send Email</button>
                   <button type="button" onClick={()=>error('No phone numbers is selected!')}  className="btn font-13px golden-bg d-flex align-items-center justify-content-center gap-2 golden-btn-respn-show"><TbMessageCirclePlus /> Send Message</button>
                   </>
                  }
          </div>
          <div className="com-card-box">
            <div className="row row-box-respnon-show">
              <div className="col-lg-3 col-md-3 mb-sm-0 d-flex gap-1">
                <div className="input-group">
                  <div className="input-group-text bg-transparent border-end-0">
                    <BiSearch />
                  </div>
                  <input type="text" className="form-control border-start-0 ps-0 font-13px" value={search} onKeyPress={(e) => e.key === "Enter" && getOrders()} onChange={(e) => {setSearch(e.target.value);setCurrentPage(1)}}placeholder="Search Name,Email,Transcation ID..."/>
                </div>
              </div>
              <div className="col-lg-9 col-md-9 ms-auto col-sm-12 col-12 order-datatable-filter-container">

              <Select
                  onChange={(e)=>{setPlatfrom(e);setCurrentPage(1)}}
                  options={platfromoption}
                  classNamePrefix="com-select"
                  isSearchable= {true}
                  placeholder="Select Platfrom"
                />

              <Select
                  onChange={(e)=>{setStaffid(e);setCurrentPage(1)}}
                  options={staffdata}
                  classNamePrefix="com-select"
                  isSearchable= {true}
                  placeholder="Select Staff"
                />

              <Select
                  onChange={(e)=>{setDriversid(e);setCurrentPage(1)}}
                  value={driversid}
                  options={driverdata}
                  classNamePrefix="com-select"
                  isSearchable= {true}
                  placeholder="Select Driver"
                />
                <Select
                onChange={(e)=>{setCustomermailid(e);setCurrentPage(1)}}
                  value={customermailid}
                  options={customerdata}
                  classNamePrefix="com-select"
                  isSearchable= {true}
                  placeholder="Select Customer"
                />
                <Select
                  onChange={(e)=>{setTranselect(e);setCurrentPage(1)}}
                  value={transelect}
                  options={transcationoption}
                  classNamePrefix="com-select"
                  isSearchable= {false}
                  placeholder="Select Status"
                />
                    
              <Select
                  onChange={(e)=>{setorderfilter(e);setCurrentPage(1)}}
                  value={orderfiltervalue}
                  options={orderfilteroption}
                  classNamePrefix="com-select"
                  isSearchable= {true}
                  placeholder="Orders Filter"
                />
              </div>
            </div>
          </div>
          <div className="com-datatable com-card-box py-2" ref={scrollRef}>
          
            {datatableloder?
                <DataTableLoaders/>
                :
                 <DataTable
                columns={columns}
                data={orderdata}
                pagination
                paginationServer
                fixedHeaderScrollHeight="300px"
                className="myTable"
                selectableRows
                selectableRowsComponent={(props) => (
                  <Checkbox {...props} />
                )}
                onSelectedRowsChange={handleSelectedRowsChange}     
                responsive
                striped
                paginationRowsPerPageOptions={paginationRowOptions}
                paginationPerPage={limit}
                paginationTotalRows={TotalRows}
                onChangeRowsPerPage={(newLimit) => {
                  setLimit(newLimit);
                  setCurrentPage(1);
                }}
                onChangePage={(page) => {setCurrentPage(page)}}
                paginationDefaultPage={currentPage}
               />
                }
          </div>
        </div>
      </div>


      <ReminderNoteModal reminderItem={reminderItem} setNotifyrole={setNotifyrole} />
 
      <SendEmailModal EmailItem={EmailItem} setNotifyrole={setNotifyrole} />
      <SendMessage MessageItem={MessageItem} setNotifyrole={setNotifyrole} />
        <AssignDriverModal assigndriverid={assigndriverid} setAssigndriverid={setAssigndriverid} setNotifyrole={setNotifyrole} />
      <DeleteModal apiname={'deleteorders'} deleteItem={deleteItem} setNotifyrole={setNotifyrole} />
       <GenrateInvoice genratedinvoice={genratedinvoice}/>

       <BulksendEmail bulkmails={bulkmails} setNotifyrole={setNotifyrole}/>
            <BulksendMessage bulkmails={bulkmails} setNotifyrole={setNotifyrole}/>
            <RefundAmount refunditem={refunditem} setNotifyrole={setNotifyrole}  />
            <CallModal callitem={callitem} setNotifyrole={setNotifyrole} />
            
            <TrackDriver trackorderid={trackorderid} setTrackorderid={setTrackorderid}/>

    </div>
  );
}

export default Orders;
