import { injectStyle } from "react-toastify/dist/inject-style";
import { toast } from "react-toastify";
if (typeof window !== "undefined") {
  injectStyle();
}

export const success = function (
  querystring = "Successfully!!",
  position = "top-right"
) {
  if (typeof querystring == "object") {
    Object.values(querystring)?.map((result) => {
      toast.success(result[0], {
        position: position,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
  } else {
    toast.success(querystring, {
      position: position,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

export const error = function (
  querystring = "Something Wrong!! ",
  position = "top-right"
) {

  if (typeof querystring == "object") {

    Object.values(querystring)?.map((result) => {
      toast.error(result[0], {
        position: position,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,  
        progress: undefined,
      });
    });
  } else {
    toast.error(querystring, {
      position: position,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};