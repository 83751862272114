import React,{useState,useEffect} from 'react';
import {CallApi} from "../common/API/APi";
import {success,error} from "../common/Toastify"; 


const EditHourPrice = (props) => {
  const {hourbyid,setNotifyrole}=props;
  const [hourprice,setHourprice]=useState();
  const gethourbyid=async()=>{
          let res= await CallApi({'id':hourbyid},'get_pricelistbyid');
          if(res?.status ==200){
            setHourprice(res?.data?.hour);
          }
  }

  const updatesamount=(cartype,name,targetvalue,keys)=>{
    let dataarray=[...hourprice];
    dataarray.map((value)=>{
        if(value?.cartype == cartype){
          dataarray[keys][name]=targetvalue;
        }
    })
    setHourprice(dataarray);
}
const updatehourdata=async()=>{
  let inputs= document.querySelectorAll(".hour-car-amount-box-show input");
  for(let i=0;i<inputs.length;i++){
    if(inputs[i].value == ''){
      error("Amount should not be empty!");
      return;
    }
  }
  let res= await CallApi({'hour':hourprice,'id':hourbyid},'update_pricelist');
  if(res?.status ==200){
    success(res?.message);
    setNotifyrole(current => !current);

    document.querySelector(".updatehourcancel").click();
  }else{
    error(res?.message);
  }
}
  useEffect(()=>{
         if(hourbyid){
          gethourbyid();
         }
  },[hourbyid])
return(
  <div className="modal fade com-modal" id="editHourpriceModal" tabIndex="-1" aria-labelledby="editHourpriceModal" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5 golden-text" id="editHourpriceModal">Edit Hour</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body pb-0">
        <form id="">
          {
            hourprice?.length?hourprice.map((value,key)=>{
              return(
                  <div className="row" key={key}>
                      <p className="order-refund-amount-box-para mb-0 ms-2">
                        {value?.carname}
                      </p>        
                      <div className=" hour-car-amount-box d-flex justify-content-start align-items-center ms-auto my-1" >
                          <p className="hour-car-amount-box-para m-0  text-nowrap font-15px">
                              Amount($) :
                          </p>
                          <div className="hour-car-amount-box-show d-flex justify-content-start  align-items-center  gap-1 ms-auto">
                              <input type="number" value={value?.amount}   onChange={(e)=>updatesamount(value?.cartype,'amount',e.target.value,key)} className= "com-input-field" style={{height: "32px",width: "60%"}}/>
                          </div>
                      </div>
                  </div>
                  );
                }):''
              }
        </form>

      </div>
      <div className="modal-footer text-center">
        <button type="button" className="golden-bg font-13px com-btn golden-border" onClick={updatehourdata}>Update</button>
        <button type="button" className="golden-bg font-13px com-btn com-btn-outline golden-border bg-transparent updatehourcancel" data-bs-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>
);
}
export default EditHourPrice;