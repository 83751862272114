import React,{ useState,useEffect} from "react";
import DataTable from 'react-data-table-component';
import { BiSearch } from "react-icons/bi"
import {CallApi} from "./common/API/APi";
import Select from "react-select";
import DataTableLoaders from "./common/DatatableLoader";
import Moment from "react-moment";
import DeleteModal from "./modal/DeleteModal";
import StatusModal from "./modal/StatusModal";
import { IoMdAddCircleOutline } from "react-icons/io";
import AddExpensesType from "./modal/Addexpensestype";
import EditExpensesType from "./modal/EditExpensesType";
import moment from "moment-timezone";



const ExpensesType = () => {
  const profiledata = JSON.parse(localStorage.getItem("profiledata"));
  const statusoption = [
    { value: 1, label: "Active" },
    { value: 2, label: "Suspend" },
  ];
  const [search,setSearch]=useState('');
  const [datatableloder,setDatatableloder]=useState();
  const [expensestypedata,setExpensestypedata]=useState();
  const [statusvalue, setStatusvalue] = useState({ value: 1, label: "Active" });
  const [deleteItem, setDeleteItem] = useState();
  const [statusItem, setStatusItem] = useState();
  const [notifyrole, setNotifyrole] = useState();
  const [editid, setEditid] = useState();


  const paginationRowOptions = [50, 100, 500, 1000];

const columns = [
  
  {name: 'S.No.',selector: row => row.id,sortable: true,width:"80px"},
  {name: ' Expenses Type',selector: row => row.expensestype,sortable: true,minWidth:"200px"},
  {name: 'Added By',selector: row => row.addedby,sortable: true,minWidth:"130px"},
  {name: 'Created At',selector: row => row.createdat,sortable: true,minWidth:"160px"},
  {name: 'Updated At',selector: row => row.updatedat,sortable: true,minWidth:"160px"},
  {
    name: "Action",
    sortable: false,
    minWidth: "300px",
    selector: (row) => (
      <>
     {" "}
        {profiledata?.permission.filter((e) => e.name === "ExpensesType")[0]
          ?.permission?.edit === 1 ? (
        <button
          className="cell-tabs bg-secondary text-white"
          onClick={() => setEditid(row?.action?.id)}
          data-bs-toggle="modal"
          data-bs-target="#editExpensesModal"
        >
          Edit
        </button>
         ) : (
          ""
        )}
         {profiledata?.permission.filter((e) => e.name === "ExpensesType")[0]
          ?.permission?.delete === 1 ? 
        
        <button
          className="cell-tabs mx-3 bg-danger text-white"
          onClick={() => setDeleteItem(row?.action?.id)}
          data-bs-toggle="modal"
          data-bs-target="#deleteitemModal"
        >
          Delete
        </button>
         : (
          ""
        )}
          {profiledata?.permission.filter((e) => e.name === "ExpensesType")[0]
          ?.permission?.edit === 1 ? (
          row?.action?.status === 1 ? (
            <button
              data-bs-toggle="modal"
              data-bs-target="#statusModal"
              onClick={() =>
                setStatusItem({ id: row?.action?.id, status: 2 })
              }
              className="cell-tabs text-white bg-success"
            >
              Active
            </button>
          ) : (
            <button
              data-bs-toggle="modal"
              data-bs-target="#statusModal"
              onClick={() =>
                setStatusItem({ id: row?.action?.id, status: 1 })
              }
              className="cell-tabs bg-warning"
            >
              Suspend
            </button>
          )
        ) : (
          ""
        )}
      </>
    ),
  },
];

const getexpensestype=async()=>{
  setDatatableloder(true);
  let res = await CallApi({"search":search,"status":statusvalue?.value}, "get_expense_type");
  if(res?.status === 200){
    let array=[];
    if( res?.data?.length){
      res?.data.map((value,key)=>{
        const CreatedAt = moment.utc(value?.createdAt).tz("America/New_York");
        const UpdateAt = moment.utc(value?.updatedAt).tz("America/New_York");
        array.push({
            
                    "id":key+1,
                    "expensestype":value?.expense_type,
                    "addedby":value?.addedBy,
                    "createdat": <Moment format="MMM DD, YYYY : hh:mm A" >{CreatedAt}</Moment>,
                    "updatedat":<Moment format="MMM DD, YYYY : hh:mm A" >{UpdateAt}</Moment>,
                    "action":{ id: value?._id, status: value?.status }
        })
      })
     
    
    }  
    setExpensestypedata(array);
    setDatatableloder(false);
  }
 }

 useEffect(()=>{
  getexpensestype();
 },[statusvalue,notifyrole])

  return (
          <>
            <div className="main-page-body position-relative">
              <h1 className="com-heading">Expenses Type</h1>

              <div className="com-card-box">
                <div className="d-flex justify-content-between align-items-center gap-2">
                  <div className="col-md-4">
                    <h1 className="com-heading mb-0">Expenses Type List</h1>
                  </div>
                  <div className="col-md-3 d-flex align-items-center justify-content-end ms-auto gap-2 ">
                  <button
                    type="button"
                    className="btn font-13px golden-bg d-flex align-items-center justify-content-center gap-2 text-nowrap com-btn golden-btn-respn-show"
                    data-bs-toggle="modal"
                    data-bs-target="#addExpensesModal"
                  >
                    <IoMdAddCircleOutline />
                    Add Expenses Type
                  </button>
                  </div>
                </div>
              </div>

              <div className="com-card-box">
                <div className="row">
                  <div className="col-lg-3 col-md-5 col-sm-6 mb-3 mb-sm-0">
                    <div className="input-group">
                      <div className="input-group-text bg-transparent border-end-0"><BiSearch/></div>
                      <input type="text" className="form-control border-start-0 ps-0 font-13px" onKeyPress={(e) => e.key === "Enter" && getexpensestype() } value={search} onChange={(e)=>setSearch(e.target.value)} placeholder="Search Expenses Type..."/>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-6 ms-auto col-sm-6 col-12 order-datatable-filter-container">
                  <Select
                    defaultValue={statusvalue}
                    onChange={setStatusvalue}
                    options={statusoption}
                    classNamePrefix="com-select"
                    isSearchable={false}
                  />
                    <div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="com-datatable com-card-box py-2">
                {datatableloder?
                <DataTableLoaders/>
                :
                <DataTable
                columns={columns}
                data={expensestypedata}
                pagination
                fixedHeaderScrollHeight="300px"
                className="myTable"
                responsive
                striped
                paginationRowsPerPageOptions= {paginationRowOptions}
              />
                }
              </div>
            
            </div>
             <DeleteModal 
             apiname={"delete_expense_type"}
             deleteItem={deleteItem}
             setNotifyrole={setNotifyrole}/>
            <StatusModal 
            apiname={"update_expense_type"}
            statusItem={statusItem}
            setNotifyrole={setNotifyrole}/>
            <AddExpensesType  setNotifyrole={setNotifyrole}/>
            <EditExpensesType
                 editid={editid}
                 setNotifyrole={setNotifyrole}
                 setEditid={setEditid}
            />
          </>

    
  )
}

export default ExpensesType;