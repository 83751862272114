import { React, useEffect, useState } from "react";
import Select from "react-select";
import { CallApi } from "../common/API/APi";
import { success, error } from "../common/Toastify";

const AddExpenseschannel = (props) => {
  let { setNotifyrole } = props;
  const [statusvalue, setStatusvalue] = useState({ value: 1, label: "Active" });
  const [extypevalue, setExtypevalue] = useState([]);
  const [expenseschanneldata, setExpenseschanneldata] = useState({
    exp_type_select: "",
    expchannel: ""
  });
  const [errors, setError] = useState();
  const statusoption = [
    { value: 1, label: "Active" },
    { value: 2, label: "Suspend" },
  ];
  const SubmitExpenseschannel = async () => {
    if (!expenseschanneldata?.exp_type_select) {
      setError({ exptype: true });
      return;
    }
    if (!expenseschanneldata?.expchannel) {
      setError({ expname: true });
      return;
    }
    expenseschanneldata["status"] = statusvalue?.value;
    expenseschanneldata["exp_type"] = expenseschanneldata?.exp_type_select?.value;
    delete expenseschanneldata?.exp_type_select;
    
    let res = await CallApi(expenseschanneldata, "add_subexpensechannel");
    if (res?.status === 200) {
      setNotifyrole((current) => !current);
      success(res?.message);
      setError('');
      setExpenseschanneldata( {exp_type_select: null,
        expchannel: ""});
      document.getElementById("addExpenseschannelform").reset();
      document.querySelector(".addallexchannelcancel").click();
    } else {
      error(res?.message);
    }
  };
  const getexpensestype = async () => {
    let res = await CallApi({ search: "", status: 1 }, "get_expense_type");
    let array = [];
    if (res?.status === 200) {
      res?.data.map((value) => {
        array.push({ value: value?._id, label: value?.expense_type });
      });
      setExtypevalue(array);
    }
  };
  useEffect(() => {
    getexpensestype();
  }, []);
  return (
    <div
      className="modal fade com-modal"
      id="addallExpenseschannelModal"
      tabIndex="-1"
      aria-labelledby="addallExpenseschannelModal"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1
              className="modal-title fs-5 golden-text"
              id="addallExpenseschannelModal "
            >
              Add Expenses Channel
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form id="addExpenseschannelform">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <Select
                  value={expenseschanneldata?.exp_type_select}
                    onChange={(e) =>
                      setExpenseschanneldata({ ...expenseschanneldata, exp_type_select: e })
                    }
                    options={extypevalue}
                    classNamePrefix={
                      errors?.exptype
                        ? " com-select border-danger"
                        : "com-select"
                    }
                    placeholder="Expenses Type"
                    isSearchable={false}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <input
                    type="text"
                    className={
                      errors?.expname
                        ? " com-input-field border-danger"
                        : "com-input-field"
                    }
                    value={expenseschanneldata?.expchannel}
                    onChange={(e) =>
                      setExpenseschanneldata({ ...expenseschanneldata, expchannel: e.target.value })
                    }
                    placeholder="Expenses Channel"
                  />
                </div>
          
                <div className="col-md-6 mb-3">
                  <Select
                    defaultValue={statusvalue}
                    onChange={setStatusvalue}
                    options={statusoption}
                    classNamePrefix="com-select"
                    isSearchable={false}
                  />
                </div>

              </div>
            </form>
          </div>
          <div className="modal-footer text-center mt-3">
            <button
              type="button"
              className="golden-bg font-13px com-btn golden-border"
              onClick={SubmitExpenseschannel}
            >
              Create Expenses Channel
            </button>
            <button
              type="button"
              className="golden-bg font-13px com-btn com-btn-outline golden-border bg-transparent addallexchannelcancel"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddExpenseschannel;
