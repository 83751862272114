import React, {useState,useEffect} from 'react';
import Select from "react-select";
import {CallApi} from "../common/API/APi";
import {success,error} from "../common/Toastify"; 

const EditRoleDetail = (props) => {


  const {editid,setEditid,setNotifyrole}=props;
  const [editdatas, setEditdata] = useState();
  let Pages= ['Order','Role', 'Staff','Customer','Dashboard','Driver','Finance','Setting','ExpensesType','Expenses','ExpensesChannel','Price','CRMBackup'];

  const editoptions = [
    { value: 1, label: 'Active' },
    { value: 2, label: 'Suspend' },
  ];

  const geteditroledata=async()=>{
  const  postdata={"id":editid}
    let res = await CallApi(postdata, "getrolesbyid");
    if(res?.status === 200){
      let permissionarray =[];
      Pages?.map((values)=>{
        let array=[];
        array= res?.data?.permission.filter(e => e.name === values);
        if(array.length){
          permissionarray.push(array[0]);
        }else{
          permissionarray.push({
            "name": values,
            "permission": {
                "view": 0,
                "add": 0,
                "edit": 0,
                "delete": 0
            }
          })

        }
            
      })
   
      const new_obj = { ...res?.data, permission:permissionarray }
         
      setEditdata(new_obj);
    }else{
      error(res?.message);
    }
  }
  function editmaincheck(key,event,name) {
    const array={...editdatas};
    const Allinput = document.querySelectorAll('.editmaincurd_'+key+' div input');
    if(event.target.checked){
      array?.permission.map((value)=>{
              if(value?.name == name){
                if(name == 'Order'){
                  value['permission'] = {'add':1,'edit':1,'delete':1,'view':1,'mail':1,'message':1,'assign':1,'invoice':1,'refund':1};
                }else if(name=='Customer'){
                  value['permission'] = {'add':1,'edit':1,'delete':1,'view':1,'mail':1,'message':1};
                }else{
                  value['permission'] = {'add':1,'edit':1,'delete':1,'view':1};
                }
                
              }
      });
      Array.from(Allinput).map((e)=>{
           e.checked =true;
      })
    }else{
      Array.from(Allinput).map((e)=>{
        e.checked =false;
      })
      array?.permission.map((value)=>{
        if(value?.name == name){
          if(name == 'Order'){
            value['permission'] = {'add':0,'edit':0,'delete':0,'view':0,'mail':0,'message':0,'assign':0,'invoice':0,'refund':0};
          }else if(name=='Customer'){
            value['permission'] = {'add':0,'edit':0,'delete':0,'view':0,'mail':0,'message':0};
          }else{
            value['permission'] = {'add':0,'edit':0,'delete':0,'view':0};
          }
        }
      });
    }
    setEditdata(array);

  }
  const EditInputs=(e,index,task)=>{
    const array={...editdatas};
    array?.permission.map((value,key)=>{
           if(key === index){
                if(e.target.checked){
                   value['permission'][task] = 1
                }else{
                   value['permission'][task] = 0
                }
           }
    })
    setEditdata(array);
    subinputcheck(index);
}
const subinputcheck=(key)=>{
  key=key+1;
  const Allinput = document.querySelectorAll('.editmaincurd_'+key+' div input');
  const maininput = document.querySelectorAll('.editpagename input');
  const array=[];
  Array.from(Allinput).map((e)=>{
    if(e.checked == true){
      array.push(1);
    }
  })
  if(array.length === 4){
  Array.from(maininput).map((value)=>{
         let attr= value.getAttribute('id');
              if(parseInt(attr) === key){
                value.checked =true;
              }
    }) 
  }else{
    Array.from(maininput).map((value)=>{
      let attr= value.getAttribute('id');
           if(parseInt(attr) === key){
             value.checked =false;
           }
     }) 
  }
}
const SubmitEditRole =async()=>{
           if(!editdatas?.rolename){
            return;
           }
           editdatas['status']=editdatas?.status_select?.value;
           delete editdatas?.status_select;
           editdatas['id'] = editdatas['_id'];
           delete editdatas?._id;
          
        let res = await CallApi(editdatas, "updateroles");
        if(res?.status === 200){
          setEditid();
          setNotifyrole(current => !current);
          success(res?.message);
          ProfileData();
          document.querySelector(".editcancel").click();
        }else{
          error(res?.message);
        }

}

const ProfileData=async()=>{
  let res = await CallApi({}, "profile");
   let filepath=process.env.REACT_APP_PATH_URL;
  if(res?.status === 200){
    localStorage.setItem('profiledata',JSON. stringify({'permission':res?.data?.permission,"name":res?.data?.name,"rolename":res?.data?.rolename,"id":res?.data?.id,'img':filepath+res?.data?.profile_image,'user_type':res?.data?.user_type}));
  }
}

  useEffect(()=>{
    if(editid){
      geteditroledata();
    }
  },[editid]);

  return(
    <div className="modal fade com-modal" id="EditRoleModal" tabIndex="-1" aria-labelledby="editRoleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5 golden-text" id="editRoleModalLabel">Edit Role</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className='row'>
              <div className='col-md-6 mb-md-0 mb-2'>
                <input type="text" className='com-input-field' value={editdatas?.rolename} onChange={(e)=>setEditdata({...editdatas,rolename:e.target.value})}  placeholder='Role Name'/>
              </div>
              {editdatas?.no_staff==0?
              <div className='col-md-6'>
                <Select
                  value={editdatas?.status_select}
                  onChange={(e)=>setEditdata({...editdatas,status_select:e})}
                  options={editoptions}
                  getOptionLabel={(e) => (
                    <div className="">
                      <span>{e.label}</span>
                    </div>
                  )}
                  classNamePrefix="com-select"
                  isSearchable= {false}
                />
              </div>
              :''}
            </div>

           {
           editdatas?.permission?.length?editdatas?.permission?.map((data,key)=>{
                 let check='';
                if(data?.permission?.view === 1 && data?.permission?.add === 1 && data?.permission?.edit === 1 && data?.permission?.delete === 1 ){
                  check=true;
                }
              return(

                <div className={`border-bottom mt-4 pb-3 editpermissiondiv ${editdatas?.rolename=='Admin' && data?.name=='Role'?'pe-none opacity-50':''}`}  key={key}>
                <div className="form-check mb-3 editpagename" data-name={data?.name}>
                  <input className="form-check-input" type="checkbox" checked={check?'checked':''}   onChange={(e)=>editmaincheck(key+1,e,data?.name)}   value="" id={key+1} />

                  <label className="form-check-label" htmlFor={key+1} >{data?.name}</label>

                </div>
                <div className={`d-flex gap-2 gap-sm-3 ps-4 flex-wrap editmaincurd_${key+1}`}>
                  <div className="form-check font-12px">
                    <input className="form-check-input up_view" checked={data?.permission?.view === 1 ?"checked":''} onChange={(e)=>EditInputs(e,key,'view')} type="checkbox"  id={`up_manageview-${key+1}`} />
                    <label className="form-check-label" htmlFor={`up_manageview-${key+1}`}>View</label>
                  </div>
                  <div className="form-check font-12px">
                    <input className="form-check-input up_add" type="checkbox" checked={data?.permission?.add === 1 ?"checked":''} onChange={(e)=>EditInputs(e,key,'add')} id={`up_manageadd-${key+1}`}  />
                    <label className="form-check-label" htmlFor={`up_manageadd-${key+1}`}>Add</label>
                  </div>
                  <div className="form-check font-12px">
                    <input className="form-check-input up_edit" type="checkbox" checked={data?.permission?.edit === 1 ?"checked":''} onChange={(e)=>EditInputs(e,key,'edit')} id={`up_manageedit-${key+1}`} />
                    <label className="form-check-label" htmlFor={`up_manageedit-${key+1}`}>Edit</label>
                  </div>
                  <div className="form-check font-12px">
                    <input className="form-check-input up_delete" type="checkbox" checked={data?.permission?.delete === 1 ?"checked":''} onChange={(e)=>EditInputs(e,key,'delete')} id={`up_managedelete-${key+1}`} />
                    <label className="form-check-label" htmlFor={`up_managedelete-${key+1}`}>Delete</label>
                  </div>

                  {data?.name=='Order' || data?.name=='Customer' ? 
                  <>
                    <div className="form-check font-12px">
                      <input className="form-check-input up_mail" onChange={(e)=>EditInputs(e,key,'mail')} checked={data?.permission?.mail === 1 ?"checked":''}  type="checkbox" id={`up_managesendemail-${key+1}`} />
                      <label className="form-check-label" htmlFor={`up_managesendemail-${key+1}`}>Manage Email</label>
                  </div>
                  <div className="form-check font-12px">
                   <input className="form-check-input up_message" onChange={(e)=>EditInputs(e,key,'message')} checked={data?.permission?.message === 1 ?"checked":''}  type="checkbox" id={`up_managesendmess-${key+1}`} />
                   <label className="form-check-label" htmlFor={`up_managesendmess-${key+1}`}>Manage Message</label>
                  </div>
                   </>
                   :''}
                   {data?.name =='Order'?
                   <>
                  <div className="form-check font-12px">
                   <input className="form-check-input up_assign" onChange={(e)=>EditInputs(e,key,'assign')} checked={data?.permission?.assign === 1 ?"checked":''} type="checkbox" id={`up_manageassign-${key+1}`} />
                   <label className="form-check-label" htmlFor={`up_manageassign-${key+1}`}>Assign Driver</label>
                  </div>
                  <div className="form-check font-12px">
                   <input className="form-check-input up_invoice" onChange={(e)=>EditInputs(e,key,'invoice')} checked={data?.permission?.invoice === 1 ?"checked":''}  type="checkbox" id={`up_manageinvoice-${key+1}`} />
                   <label className="form-check-label" htmlFor={`up_manageinvoice-${key+1}`}>Manage Invoice</label>
                  </div>
                  <div className="form-check font-12px">
                   <input className="form-check-input up_assign" onChange={(e)=>EditInputs(e,key,'refund')} checked={data?.permission?.refund === 1 ?"checked":''} type="checkbox" id={`up_managerefund-${key+1}`} />
                   <label className="form-check-label" htmlFor={`up_managerefund-${key+1}`}>Refund</label>
                  </div>
                </>
                 :''}
                </div>
              </div>
              )
            }):''
           }
          </div>
          <div className="modal-footer text-center">
            <button type="button" className="golden-bg font-13px com-btn golden-border"  onClick={SubmitEditRole}>Save Role</button>
            <button type="button" className="golden-bg font-13px com-btn com-btn-outline golden-border bg-transparent editcancel" onClick={()=>setEditid()} data-bs-dismiss="modal">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  )
} 

export default EditRoleDetail;
