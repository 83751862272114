import axios from 'axios';



export const CallApi = function (querystring = "", Url = "") {

  return new Promise( (resolve, reject) => {
   
    var data = querystring;
    var config = {
      method: 'post',
      url: process.env.REACT_APP_API_URL + Url,
      headers: {

        'authorization':localStorage.getItem('token'),
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
         
      },
      data: data
    };
     axios(config)
      .then(function (response) {
        if(response?.data?.status == 401){
           localStorage.clear(); 
           window.location.href='/';
        }else{
          resolve(response?.data);
        }
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
export const CallFormDataApi = function (querystring = "", Url = "") {
  

  return new Promise( (resolve, reject) => {
 
    var data = querystring;
    var config = {
      method: 'post',
      url: process.env.REACT_APP_API_URL + Url,
      headers: {

        'authorization':localStorage.getItem('token'),
        'content-type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
         
      },
      data: data
    };
     axios(config)
    .then(function (response) {
      if(response?.data?.status == 401){
        resolve(response?.data);
       
      }else{
        resolve(response?.data);
      }
    })
      .catch(function (error) {
        reject(error);
      });
  });
};
export const _APIS = function (querystring = "", Url = "") {
  return new Promise( (resolve, reject) => {
    var data = querystring;
    var config = {
      method: 'post',
      url: process.env.REACT_APP_API_URL + Url,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      data: data
    };
     axios(config)
      .then(function (response) {
        resolve(response?.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
