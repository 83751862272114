import React,{useState,useEffect} from "react";
import { DateRange } from "./modal/ComDatePicker";
import { FiUploadCloud } from "react-icons/fi";
import {CallApi} from "./common/API/APi";
import { success,error } from "./common/Toastify";


const financePnl = () => {
  const path_file=process.env.REACT_APP_PATH_URL;
  const [datesearch,setDateSearch]=useState([]);
  const [pnldata,setpnldata]=useState();
  const pandldata=async()=>{
    setpnldata('');
    let res = await CallApi({"from_date": datesearch[0]?datesearch[0]:'',
  "to_date": datesearch[1]? datesearch[1]:''}, "profit_loss");
     if(res?.status ==200){
        setpnldata(res?.data);
     }
  }
   const downloadpdf=async()=>{
    success("Your Request is Submited, Soon You Download The PDF Via Mail!");
    let res = await CallApi({"from_date": datesearch[0]?datesearch[0]:'',
  "to_date": datesearch[1]? datesearch[1]:''}, "refund_pdf");
     if(res?.status !=200){
        error(res?.message);
     }
  }
  const downloadexcel=async()=>{
    let res = await CallApi({"from_date": datesearch[0]?datesearch[0]:'',
    "to_date": datesearch[1]? datesearch[1]:''}, "finance_export");
     if(res?.status ==200){
        const a = document.createElement("a");
        a.href = path_file+res?.file;
        a.download = "ExpenseList.xlsx"; 
        a.click();
     }else{
        error(res?.message);
     }
  }

  useEffect(()=>{
    if(datesearch[0] && datesearch[1]){
        pandldata();
    }
   
  },[datesearch])
    return (
        <div className="main-page-body position-relative">
            <div className="com-card-box">
                <div className="d-flex justify-content-between align-items-center gap-2">
                    <div className="col-md-4">
                        <h1 className="com-heading mb-0">Profit and Loss</h1>
                    </div>
                
                </div>
            </div>

            <div className="com-card-box">
                <div className="row">
                    
                <div className=" order-datatable-filter-container">
                  
                     <button
                        onClick={downloadpdf}
                        type="button"
                        className="btn font-13px golden-bg d-flex align-items-center justify-content-center gap-2 text-nowrap com-btn"
                        >
                        <FiUploadCloud /> Download PDF
                     </button>

                    <button
                            onClick={downloadexcel}
                            type="button"
                            className="btn font-13px golden-bg d-flex align-items-center justify-content-center gap-2 text-nowrap com-btn"
                            >
                            <FiUploadCloud /> Download Expense List
                     </button>
                  
                <DateRange setDateSearch={setDateSearch} filterby={'lastmonth'} />
                </div>
                </div>
            </div>
            <div className="com-card-box">
                <div className="row">
                <div className="pnl-com-show-field d-flex justify-content-start gap-1 align-items-center">
                    <p className="pnl-com-show-field-para">
                    Total Booking Received :
                    </p>
                    <p className="pnl-com-show-field-amount">${pnldata?.totalbookingcount}</p>
                </div>
                <div className="pnl-com-show-field d-flex justify-content-start gap-1 align-items-center">
                    <p className="pnl-com-show-field-para">Total Expenses :</p>
                    <p className="pnl-com-show-field-amount">${pnldata?.totalexpensecount}</p>
                </div>
                <div className="pnl-com-show-field d-flex justify-content-start gap-1 align-items-center">
                    <p className="pnl-com-show-field-para">Total Driver Expenses :</p>
                    <p className="pnl-com-show-field-amount">${pnldata?.totalDriverCount}</p>
                </div>
                <div className="pnl-com-show-field d-flex justify-content-start gap-1 align-items-center" >
                    <p className="pnl-com-show-field-para">Total Refund :</p>
                    <p className="pnl-com-show-field-amount">${pnldata?.totalRefundCount}</p>
                </div>
                <div className={pnldata?.profit_loss_status==1?"pnl-com-show-field d-flex justify-content-start gap-1 align-items-center green-color ":"pnl-com-show-field d-flex justify-content-start gap-1 align-items-center danger-color "}>
                    <p className="pnl-com-show-field-para">{pnldata?.profit_loss_status==1?'Profit':'Loss'} :</p>
                    <p className="pnl-com-show-field-amount">${pnldata?.profitLossvalue}</p>
                </div>
                </div>
            </div>
        </div>
    );
};

export default financePnl;


