import React from 'react';
import {useNavigate} from "react-router-dom";
import { BsExclamationCircle } from "react-icons/bs";
import {CallApi} from "../common/API/APi";
import {success,error} from "../common/Toastify"; 


const StatusModal = (props) => {
  const {apiname,statusItem,setNotifyrole}=props;
  const profiledata= JSON.parse(localStorage.getItem('profiledata'));
  let navigate=useNavigate();

  const changestatusitem=async()=>{
    if(statusItem){
      const  postdata={"id":statusItem?.id,"status":statusItem?.status}
      let res = await CallApi(postdata, apiname);
      if(res?.status === 200){
        if((apiname=='updateadminstatus' && profiledata?.id == statusItem?.id) && statusItem?.status ==2){
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 500);
          success("This Account is Suspended!");
        }else{
        setNotifyrole(current => !current);
        success(res?.message);
        }
      
      }else{
        error(res?.message);
      }
    }
   
    }

  return (
    <div
      className="modal fade com-modal status-item-modal"
      id="statusModal"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="deleteitemModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog  modal-dialog-centered "
        style={{ width: "350px" }}
      >
        <div className="modal-content">
          <div className="modal-body">
            <div className="text-center mb-2 mt-3">
              <BsExclamationCircle className="exclama-deleteIcon" />
            </div>
            <p className="text-center mt-4 font-15px">
              Are you sure you want to change <b>Status</b> ?
            </p>
          </div>
          <div className="pb-4 gap-3 text-center d-flex justify-content-center">
            <button
              onClick={changestatusitem}
              data-bs-dismiss="modal"
              type="button"
              className="font-13px com-btn danger-bg delete-item-modal-footer-btn"
            >
              Yes
            </button>
            <button
              type="button"
              className="font-13px com-btn danger-color bg-white delete-item-modal-footer-btn"
              data-bs-dismiss="modal"
            >
              No,Keep it.
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatusModal