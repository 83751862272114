import React, {useState,useEffect} from 'react';
import Select from 'react-select';
import {CallApi} from "../common/API/APi";
import {success,error} from "../common/Toastify"; 

const EditDriver = (props) => {
  const {editid,setNotifyrole,setEditid} = props;
  const [vehiclemodeldata, setVehicelmodeldat] = useState([]);
    const editoptions = [
        { value: 1, label: 'Active' },
        { value: 2, label: 'Suspend' },
      ];
      const [editdriverdata, setEditdriverdata] = useState();
      const [errors, setError] = useState();
      const UpdateDriver =async()=>{
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
      

                  if(!editdriverdata?.name){
                    setError({ name: true });
                    return;
                  }  
                 if(!editdriverdata?.email || !emailRegex.test(editdriverdata?.email)){
                   setError({ email: true });
                    return;
                 } 
                 if(!editdriverdata?.phone ){
                  setError({ phone: true });
                  return;
                } 
                if(!editdriverdata?.address){
                  setError({ address: true });
                  return;
                  
                }
                if(!editdriverdata?.vehicle_model_select?.value){
                  console.log("ddd");
                  setError({ vehicle_model: true });
                  return;
                } 
                if(!editdriverdata?.vehicle_number){
                  console.log("dddddddddddddddddd");

                  setError({ vehicle_number: true });
                  return;
                }   
                editdriverdata['status'] = editdriverdata?.status_select?.value;
                delete editdriverdata?.status_select;

                editdriverdata['vehicle_model'] = editdriverdata?.vehicle_model_select?.value;
                delete editdriverdata?.vehicle_model_select;

            let res = await CallApi(editdriverdata, "updateadmin");
            if(res?.status === 200){
              success(res?.message);
              document.querySelector(".updatecancel").click();
              setError('');
              setEditid();
            setNotifyrole(current => !current);
            }else{
              error(res?.message);
            }
      }
      const getdriverdata =async()=>{
        setError();
        let res = await CallApi({'id':editid}, "getadminbyid");
        if(res?.status === 200){
          setEditdriverdata(res?.data);
        }
      }
      const getvehicledata=async()=>{
        let res = await CallApi({}, "getvehiclemodel");
        if(res?.status === 200){
          let array=[];
          res?.data.map((value)=>{
            array.push({label:value?.name,value:value?.id})      
          })
          setVehicelmodeldat(array)
        }
      }
      useEffect(()=>{
        if(editid){
          getvehicledata();
          getdriverdata();
          
        }
      },[editid])
  return(
    <div className="modal fade com-modal" id="editDriverModal" tabIndex="-1" aria-labelledby="editDriverModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5 golden-text" id="editDriverModalLabel">Edit Driver</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
          <form id="editDriverform">
            <div className='row'>
              <div className='col-md-6 mb-3'>
              <input type="text" className={errors?.name? " com-input-field border-danger" : "com-input-field"} value={editdriverdata?.name} onChange={(e)=>setEditdriverdata({...editdriverdata,name:e.target.value})} placeholder='Name'/>
              </div>
              <div className='col-md-6 mb-3'>
              <input type="email" className={errors?.email? " com-input-field border-danger " : "com-input-field pe-none"} value={editdriverdata?.email} onChange={(e)=>setEditdriverdata({...editdriverdata,email:e.target.value})} placeholder='Email'/>
              </div>
              <div className='col-md-6 mb-3'>
              <input type="text" className={errors?.phone? " com-input-field border-danger" : "com-input-field"} value={editdriverdata?.phone} onChange={(e)=>setEditdriverdata({...editdriverdata,phone:e.target.value})} placeholder='Contact Number'/>
              </div>
              
              <div className='col-md-6 mb-4'>
              <Select
                  value={editdriverdata?.status_select}
                  onChange={(e)=>setEditdriverdata({...editdriverdata,status_select:e})}
                  options={editoptions}
                  getOptionLabel={(e) => (
                    <div className="">
                      <span>{e.label}</span>
                    </div>
                  )}
                  classNamePrefix="com-select"
                  isSearchable= {false}
                />
              </div>
              <div className='col-md-12 mb-4'>
                <textarea className={errors?.address? " com-input-field h-auto resize-none border-danger" : "com-input-field h-auto resize-none"} value={editdriverdata?.address?editdriverdata?.address:""} onChange={(e)=>setEditdriverdata({...editdriverdata,address:e.target.value})} rows="3" placeholder='Address'/>
              </div>
              <div className='col-md-6 mb-3'>
              <Select
                  value={editdriverdata?.vehicle_model_select}
                  onChange={(e)=>setEditdriverdata({...editdriverdata,vehicle_model_select:e})}
                  options={vehiclemodeldata}
                  classNamePrefix="com-select"
                  placeholder='Vehicle Model'
                  isSearchable= {false}
                />
              </div>
              <div className='col-md-6 mb-3'>
               <input type="text" className={errors?.vehicle_number? " com-input-field border-danger" : "com-input-field"} value={editdriverdata?.vehicle_number} onChange={(e)=>setEditdriverdata({...editdriverdata,vehicle_number:e.target.value})} placeholder='Vehicle Number'/>
              </div>
            </div>
            </form>
          </div>
          <div className="modal-footer text-center mt-3">
            <button type="button" className="golden-bg font-13px com-btn golden-border" onClick={UpdateDriver}>Update Driver</button>
            <button type="button" className="golden-bg font-13px com-btn com-btn-outline golden-border bg-transparent updatecancel" data-bs-dismiss="modal">Cancel</button> 
          </div>
        </div>
      </div>
    </div>
  )
} 

export default EditDriver;
