import React from 'react';
import { Navigate } from 'react-router-dom';

export const ProtectedRoute = ({component: Component,permissionss }) => {
  const profiledata= JSON.parse(localStorage.getItem('profiledata'));
  const isAuthenticated= localStorage.getItem('token');
    if(!isAuthenticated){
       return <Navigate to="/" />
    }else{
      if( permissionss=='Profile' ){
        return <Component/>
      }else{
        if(profiledata?.user_type == 1){
          if(permissionss !='Bookings'){
              if(profiledata?.permission.filter(e => e.name == permissionss)[0]?.permission?.view == 1){
                return <Component/>
              }else{
                return <Navigate to="/" />
              }
          }else{
            return <Navigate to="/dashboard" />
          }
        }else if(profiledata?.user_type == 2){
          if(permissionss =='Bookings'){
             return <Component/>
          }else{
            return <Navigate to="/bookings" />
          }
        }else{
          return <Navigate to="/" />
        }
        
      }
      
    }
   };

   export const UnProtectedRoute = ({component: Component }) => {
      const isAuthenticated= localStorage.getItem('token');
       if(isAuthenticated){
          return <Navigate to="/dashboard" />
       }else{
         return <Component/>
       }
      };