import React,{useState,useEffect} from "react";
import { DateRange } from "./modal/ComDatePicker";
import DataTable from 'react-data-table-component';
import { BiSearch } from "react-icons/bi";
import { FiUploadCloud } from "react-icons/fi";
import DataTableLoaders from "./common/DatatableLoader";
import {CallApi} from "./common/API/APi";
import {success,error} from "./common/Toastify";
import Moment from 'react-moment';


const Finance = () => {
   const [search,setSearch]=useState('');
   const [datesearch,setDateSearch]=useState([]);
  const [datatableloder,setDatatableloder]=useState();
  const [orderdata,setOrderdata]=useState([]);
  const [totalamount,setTotalamount]=useState(0);
  const path_file=process.env.REACT_APP_PATH_URL;
  const [TotalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

const columns = [
  {name: 'S.No.',selector: row => row.id,sortable: true,width:"80px"},
  {name: 'Transcation ID',selector: row => row.transid,sortable: true,minWidth:"200px"},
  {name: 'UserEmail',selector: row => row.email,sortable: true,minWidth:"240px"},
  {name: 'Amount',selector: row => row.amount,sortable: true,minWidth:"130px"},
  {name: 'Driver Amount',selector: row => row.driveramount,sortable: true,minWidth:"130px"},
  {name: 'Payment Date',selector: row => row.date,sortable: true,minWidth:"130px"}
];

const paginationRowOptions = [50, 100, 500, 1000];
const [limit, setLimit] = useState(paginationRowOptions[0]);


const getorders=async()=>{
   setDatatableloder(true);
   const skip = (currentPage - 1) * limit;
   let res = await CallApi({"search":search,"from_date": datesearch[0]?datesearch[0]:'',
   "to_date": datesearch[1]? datesearch[1]:'',limit:limit,skip:skip}, "finance");
   if(res?.status === 200){
     let array=[];
     if( res?.data?.length){
       res?.data.map((value,key)=>{
         array.push({
                     "id":key+1,
                     "transid":value?.transcation_id,
                     "email":value?.email,
                     "amount":value?.amount?'$'+value?.amount:'$0',
                     "driveramount":value?.assignamount?'$'+value?.assignamount:'$0',
                     "date":<Moment format="MMM DD, YYYY : hh:mm A">{value?.payment_date}</Moment>
         })
       })
      
      
     
     }  
     setTotalamount( res?.totalamount);
     setOrderdata(array);
     setTotalRows(res?.TotalRows);
     setDatatableloder(false);
   }
  }
  const downloadcsv=async()=>{
    success("Wait a minute! Backend process is start");
    let res = await CallApi({}, "export_finance_csv");    
    if(res?.status === 200){
      const a = document.createElement("a");
      a.href = path_file+res?.file;
      a.download = "Customers.xlsx"; 
      a.click();
    }else{
      error("Something Went Wrong!");
    }
    }   
    const downloadpdf=async()=>{  
    success("Your Request is Submited, Soon You Download The PDF Via Mail!");
  let res = await CallApi({}, "export_finance_pdf");  
  if(res?.status != 200){
    error("Something Went Wrong!");
  }
  } 

    useEffect(()=>{
        getorders();

    },[datesearch,limit,currentPage])
  return (
    <div className="main-page-body position-relative">
      <h1 className="com-heading">Transactions</h1>

      <div className="com-card-box">
        <div className="row align-items-center">
          <div className="col-md-4">
            <h1 className="com-heading mb-0">Transaction List</h1>
          </div>
          <div className="col-md-3 d-flex align-items-center justify-content-end ms-auto gap-2 res-all-golden-btn">
            <button
              type="button"
              className="btn font-13px golden-bg d-flex align-items-center justify-content-center gap-2 golden-btn-respn-show"
              onClick={downloadpdf}
            >
              <FiUploadCloud /> Download PDF
            </button>
            <button
              type="button"
              className="btn font-13px golden-bg d-flex align-items-center justify-content-center gap-2 golden-btn-respn-show"
              onClick={downloadcsv}
            >
              <FiUploadCloud /> Download CSV
            </button>
          </div>
        </div>
      </div>

      <div className="com-card-box">
        <div className="row">
          <div className="col-lg-3 col-md-5 col-sm-6 mb-3 mb-sm-0">
            <div className="input-group">
              <div className="input-group-text bg-transparent border-end-0">
                <BiSearch />
              </div>
              <input
                type="text"
                className="form-control border-start-0 ps-0 font-13px"
                value={search}
                onChange={(e) => {setSearch(e.target.value);setCurrentPage(1)}}
                onKeyPress={(e) => e.key === "Enter" && getorders() } 
                placeholder="Search Transcation ID And Email...."
              />
            </div>
          </div>
          <div className="col-lg-5 col-md-6 ms-auto col-sm-6 col-12 order-datatable-filter-container">
            <div>
              <DateRange setDateSearch={setDateSearch} filterby={"last30"} setCurrentPage={setCurrentPage}  />
            </div>
          </div>
        </div>
      </div>
      <div className="com-datatable com-card-box py-2">
        {datatableloder ? (
          <DataTableLoaders />
        ) : (
          <DataTable
            columns={columns}
            data={orderdata}
            pagination
            paginationServer
            fixedHeaderScrollHeight="300px"
            className="myTable"
            responsive
            striped
            paginationRowsPerPageOptions={paginationRowOptions}
            paginationPerPage={limit}
            paginationTotalRows={TotalRows}
            onChangeRowsPerPage={(newLimit) => {
              setLimit(newLimit);
              setCurrentPage(1);
            }}
            onChangePage={(page) => {setCurrentPage(page)}}
            paginationDefaultPage={currentPage}
          />
        )}
       <div>Total Amount: ${totalamount?totalamount:0}</div>
      </div>
    </div>
  );
}

export default Finance;