import React, {useState,useEffect} from 'react';
import {CallApi} from "../common/API/APi";
import {success,error} from "../common/Toastify"; 



const SendMessage = (props) => {
    const {MessageItem,setNotifyrole}=props;
   const [messagedata,setMesaagedata]=useState({'mobileNumber':'','message':''});
   const [errors,setErrors]=useState();
    
   const sendMessage=async()=>{
           if(!messagedata?.mobileNumber){
              setErrors({'mobileNumber':true});
              return;
           }
         if(!messagedata?.message){
            setErrors({'message':true});
            return;
          }
          
          let res = await CallApi(messagedata, 'sendmessage');
          if(res?.status === 200){
             setNotifyrole(current => !current);
            success(res?.message);
            document.querySelector(".messagecancel").click();
            setMesaagedata({'message':''});
          }else{
            error(res?.message);
          }
   } 
   const handleInputChange = (e) => {
    if(e.target.value.match(/^[0-9+-]+$/)){
      setMesaagedata({...messagedata,'mobileNumber':e.target.value})
    }else{
       error('Not valid number');
    }
  };
    useEffect(()=>{
      if(MessageItem?.to){
         setMesaagedata({...messagedata,'mobileNumber':MessageItem?.to,'message':''});      
      }
    },[MessageItem?.to])
   
  return(
      <div className="modal fade com-modal" id="SendMessageModal" tabIndex="-1" aria-labelledby="SendMessageModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5 golden-text" id="SendMessageModalLabel">Send Message</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body pb-0">
         
            <div className='row'>
              <div className='col-md-12 mb-4'>
                <input type="text"  className={errors?.mobileNumber?" com-input-field border-danger":" com-input-field "}  onChange={(e)=>handleInputChange(e)} value={messagedata?.mobileNumber}  placeholder='To'/>
              </div>
              <div className='col-md-12 mb-4'>
                <textarea className={errors?.message?"com-input-field h-auto resize-none border-danger":"com-input-field h-auto resize-none"} onChange={(e)=>setMesaagedata({...messagedata,'message':e.target.value})} value={messagedata?.message}    rows="6" placeholder='Write a message '/>
              </div>
            </div>
   
          </div>
          <div className="modal-footer text-center">
            <button type="button" className="golden-bg font-13px com-btn golden-border" onClick={sendMessage} >Send</button>
            <button type="button" className="golden-bg font-13px com-btn com-btn-outline golden-border bg-transparent messagecancel" data-bs-dismiss="modal">Cancel</button>
          </div>
        </div>
      </div>
      </div>
  )
} 

export default SendMessage;
