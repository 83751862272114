import React, { useState, useRef,useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BiSolidCalendar } from 'react-icons/bi';


export const DateRange = (props) => {
    const {setDateSearch,filterby,setCurrentPage}=props;
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const datePickerRef = useRef(null);

    // const formatDate = (date) => {
    //     const adjustedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    //     return adjustedDate.toISOString().split('T')[0];  
    // };
    const changedate = (update) => {
        setStartDate(update[0]);
        setEndDate(update[1]);
        if(update[0] && update[1]){
            setDateSearch([update[0],update[1]]);
            if(props.setCurrentPage){
                setCurrentPage(1);

            }
        }else if(update[0] ==null && update[1]==null ){
            setDateSearch([]);

        }
    };
     useEffect(()=>{
        if(filterby){
            setStartDate(new Date(new Date().setDate(new Date().getDate() - 30)));
            setEndDate(new Date());
            setDateSearch([new Date(new Date().setDate(new Date().getDate() - 30)),new Date()]);
        }
     },[filterby])
    return (
        <div className="dateranger">
          <span>
            <DatePicker
                selectsRange={true}
                monthsShown={2}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                    changedate(update);
                }}
                dateFormat="yyyy-MM-dd" // Use uppercase MM for months
                ref={datePickerRef}
                placeholderText={'Select Date Range'} 
            />
            </span>
            <span className="dateranger-icon">
              <BiSolidCalendar />
            </span>
        </div>
    );
};
