import React, { useState, useEffect } from "react";
import UserImg from "../assets/images/user-circle.png";
import { LuSave } from "react-icons/lu";
import { CallApi, CallFormDataApi } from "./common/API/APi";
import { success, error } from "./common/Toastify";
// import { BiSolidPencil } from "react-icons/bi";


const Profile = () => {
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    contact: "",
    address: "",
    image: UserImg,
  });
  const [passinputs, setPassInputs] = useState({
    oldpassword: "",
    newpassword: "",
  });
  // const [base64, setBase64] = useState('');
  const [errors, setError] = useState();
  // let filepath=process.env.REACT_APP_PATH_URL;

  // const onImageChange = async (event) => {
  //   if (event.target.files && event.target.files[0]) {
  //     const base64image = await readImageAsBase64(event.target.files[0]);
  //     setBase64(base64image);
  //     setInputs({
  //       ...inputs,
  //       image: URL.createObjectURL(event.target.files[0]),
  //     });
  //   }
  // };
  // async function readImageAsBase64(file) {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.onload = (event) => {
  //       resolve(event.target.result);
  //     };
  //     reader.onerror = (error) => {
  //       reject(error);
  //     };
  //     reader.readAsDataURL(file);
  //   });
  // }

  // const OnPencilClick = () => {
  //   document.getElementById("profileImg").click();
  // };

  const SaveProfile = async () => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;


    if (!inputs?.name) {
      setError({ name: true });
      return;
    }
    if (!inputs?.email || !emailRegex.test(inputs?.email)) {
      setError({ email: true });
      return;
    }
    if (!inputs?.contact ) {
      setError({ contact: true });
      return;
    }
    setError();
    const Data = new FormData();
    Data.append("name", inputs?.name);
    Data.append("email", inputs?.email);
    Data.append("address", inputs?.address);
    Data.append("phone", inputs?.contact);
    // Data.append("profile_img", base64);
    let res = await CallFormDataApi(Data, "updateprofile");
    if (res?.status == 200) {
      ProfileData();
      success(res?.message);
    } else {
      error("Something Went Wrong");
    }
  };

  const ProfileData=async()=>{
    let res = await CallApi({}, "profile");
     let filepath=process.env.REACT_APP_PATH_URL;
    if(res?.status === 200){
      localStorage.setItem('profiledata',JSON. stringify({'permission':res?.data?.permission,"name":res?.data?.name,"rolename":res?.data?.rolename,"id":res?.data?.id,'img':filepath+res?.data?.profile_image ,   'user_type':res?.data?.user_type}));
    }
  }
  const GetProfile = async () => {
    let res = await CallApi({}, "profile");
    if (res?.status === 200) {
      setInputs({
        name: res?.data?.name,
        email: res?.data?.email,
        contact: res?.data?.phone,
        address: res?.data?.address,
        // image: filepath+res?.data?.profile_image,
      });
    }
  };
  const changepassword=async()=>{
    if (!passinputs?.oldpassword) {
      setError({ oldpass: true });
      return;
    }
    if (!passinputs?.newpassword) {
      setError({ newpass: true });
      return;
    } 
    setError();
    let res = await CallApi(passinputs, "updatepassword");
    if (res?.status == 200) {
      success(res?.message);
    } else {
      error(res?.message);
    }
  }
  useEffect(() => {
    GetProfile();
  }, []);
 
  return (
          <div>
            <div className="main-page-body position-relative">
              <h1 className="com-heading">Profile</h1>
              <div className="com-card-box golden-border">
                <div className="row">
                  <div className="col-md-12 mb-4">
                    <button
                      type="button"
                      className="btn font-13px ms-auto golden-bg d-flex align-items-center justify-content-center gap-2 text-nowrap"
                      onClick={SaveProfile}
                    >
                      <LuSave /> Save
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 profile-page-left">
                    <div className="profile-page-left-user mx-auto">
                      <label
                        className="h-100 w-100 cursor-pointer"
                        htmlFor="profileImg"
                      >
                        {/* {inputs.image ? ( */}
                          <img
                            className="h-100 w-100 rounded-circle cursor-pointer"
                            src={UserImg}
                            alt="Profile"
                          />
                        {/* ) : ( */}
                          {/* <span className="default-profile-image-text">
                            Upload Image
                          </span>
                        )} */}
                      </label>
                      {/* <input
                        id="profileImg"
                        type="file"
                        className="d-none"
                        // onChange={onImageChange}
                      /> */}
                      {/* <span
                        className="profile-page-left-user-edit-pencil"
                        // onClick={OnPencilClick}
                      >
                        <BiSolidPencil />
                      </span> */}
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label className="form-label">Name</label>
                        <input
                          type="text"
                          className={
                            errors?.name
                              ? "form-control border-danger"
                              : "form-control"
                          }
                          onChange={(e) => {
                            setInputs({ ...inputs, name: e.target.value });
                          }}
                          value={inputs?.name}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label">Email</label>
                        <input
                          type="email"
                          className={
                            errors?.email
                              ? "form-control border-danger pe-none"
                              : "form-control pe-none"
                          }
                          onChange={(e) => {
                            setInputs({ ...inputs, email: e.target.value });
                          }}
                          value={inputs?.email}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label">Phone No.</label>
                        <input
                          type="text"
                          className={
                            errors?.contact
                              ? "form-control border-danger"
                              : "form-control"
                          }
                          onChange={(e) => {
                            setInputs({ ...inputs, contact: e.target.value });
                          }}
                          value={inputs?.contact}
                        />
                      </div>
                      <div className="col-md-12 mb-3">
                        <label className="form-label">Address</label>
                        <textarea
                          className="form-control resize-none"
                          rows="3"
                          onChange={(e) => {
                            setInputs({ ...inputs, address: e.target.value });
                          }}
                          value={inputs?.address}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="com-card-box golden-border">
                <div className="row">
                  <div className="col-md-12 mb-4">
                    <button
                      type="button"
                      className="btn font-13px ms-auto golden-bg d-flex align-items-center justify-content-center gap-2 text-nowrap"
                      onClick={changepassword}
                    >
                      <LuSave /> Update Password
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label className="form-label">Old Password</label>
                        <input
                          type="password"
                          className={
                            errors?.oldpass
                              ? "form-control border-danger"
                              : "form-control"
                          }
                          onChange={(e) => {
                            setPassInputs({ ...passinputs, oldpassword: e.target.value });
                          }}
                          value={passinputs?.oldpassword}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label">New Password</label>
                        <input
                          type="password"
                          className={
                            errors?.newpass
                              ? "form-control border-danger"
                              : "form-control"
                          }
                          onChange={(e) => {
                            setPassInputs({ ...passinputs, newpassword: e.target.value });
                          }}
                          value={passinputs?.newpassword}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    
  );
};

export default Profile;
