import React,{useEffect, useState} from 'react';
import DataTable from 'react-data-table-component';
import { IoMdAddCircleOutline } from "react-icons/io"
import AddNewRole from './modal/AddRolesModal';
import EditDetail from './modal/EditRoleDetail';
import DeleteModal from './modal/DeleteModal';
import StatusModal from './modal/StatusModal';
import { BiSearch } from "react-icons/bi"
import Select from 'react-select';
import {error} from "./common/Toastify";
import {CallApi} from "./common/API/APi";
import DataTableLoaders from "./common/DatatableLoader";
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import moment from "moment-timezone";




const Roles = () => {
  const profiledata= JSON.parse(localStorage.getItem('profiledata'));

  const columns = [
    {name: 'S.No.',selector: row => row.sno,sortable: true,width:"80px"},
    {name: 'No. Of Staff',selector: row => row.nostaff,sortable: true,width:"140px"},
    {name: 'Role',selector: row => row.role,sortable: true, minWidth:"140px"},
    {name: 'Permission',selector: row => row.permission,sortable: true,minWidth:"240px"},
    {name: 'Created On',selector: row => row.createdOn,sortable: true,minWidth:"200px"},
    {name: 'Last Updated',selector: row => row.lastUpdated,sortable: true,minWidth:"190px"},
    {name: 'Created By',selector: row => row.createdBy,sortable: true, minWidth:"130px"},
    {name: 'Status',selector: row => row.status,sortable: true,minWidth:"150px"},
    {
      name: 'Action',sortable: false , minWidth: '220px',
      selector: (row) => <> {profiledata?.permission.filter(e => e.name === 'Role')[0]?.permission?.edit === 1? <button className="cell-tabs bg-secondary text-white" onClick={()=>setEditid(row?.action?.id)}   data-bs-toggle="modal" data-bs-target="#EditRoleModal">Edit</button>:''}

     {profiledata?.permission.filter(e => e.name === 'Role')[0]?.permission?.delete === 1? (row?.action?.count==0?<button className="cell-tabs mx-3 bg-danger text-white" onClick={()=>setDeleteItem(row?.action?.id)} data-bs-toggle="modal" data-bs-target="#deleteitemModal">Delete</button>:''):''}

     {profiledata?.permission.filter(e => e.name === 'Role')[0]?.permission?.edit === 1?(row?.action?.count==0?(row?.action?.status===1?<button data-bs-toggle="modal" data-bs-target="#statusModal" onClick={()=>setStatusItem({"id":row?.action?.id,"status":2})} className="cell-tabs text-white bg-success">Active</button>:<button  data-bs-toggle="modal" data-bs-target="#statusModal"  onClick={()=>setStatusItem({"id":row?.action?.id,"status":1})} className="cell-tabs bg-warning">Suspend</button>):''):''}</>,

    }
  ];
  const statusoption = [
    { value: 1, label: 'Active' },
    { value: 2, label: 'Suspend' },
  ];
  const [roledata,setRoledata]=useState();
  const [search,setSearch]=useState('');
  const [notifyrole,setNotifyrole]=useState();
  const [statusvalue,setStatusvalue]=useState( { value: 1, label: 'Active' });
  const [datatableloder,setDatatableloder]=useState();
  const [editid,setEditid]=useState( );
  const [deleteItem,setDeleteItem]=useState();
  const [statusItem,setStatusItem]=useState();

  const paginationRowOptions = [50, 100, 500, 1000];
  
   const getRoles=async()=>{
    setDatatableloder(true);
    const postdata={"search":search,"status":statusvalue?.value};
      let res = await CallApi(postdata, "getroles");
      if(res?.status === 200){
        let array=[];
        if(res?.data?.length){
        res?.data.map((value,key)=>{
          const permission_name = [];
         value?.permission.map((pvalue)=>{
              if(pvalue?.permission?.view ===1 || pvalue?.permission?.add ===1 || pvalue?.permission?.edit ===1 || pvalue?.permission?.delete ===1){
                permission_name.push(pvalue?.name);
              }
           })
           const CreatedAt = moment.utc(value?.createdAt).tz("America/New_York");
           const UpdateAt = moment.utc(value?.updatedAt).tz("America/New_York");
          array.push({
                      "sno":key+1,
                      "nostaff": value?.no_staff !=0? <Link to="/staff" className='text-decoration-none text-black' state={{'role_ids':{'value':value?._id,'label':value?.rolename}}}>{value?.no_staff}</Link>:0,
                      "role":value?.rolename,
                      "permission":permission_name.toString(),
                      "createdOn":<Moment format="MMM DD, YYYY : hh:mm A" >{CreatedAt}</Moment>,
                      "lastUpdated":<Moment format="MMM DD, YYYY : hh:mm A" >{UpdateAt}</Moment>,
                      "createdBy":value?.createdBy,
                      "status":value?.status==1?"Active":'Suspend',
                      "action":{'id':value?._id,'status':value?.status,'count':value?.no_staff?value?.no_staff:0}
          })
        })
      }
        setRoledata(array);
        setDatatableloder(false);
        
      }else{
        error("No data Found!");
      }
   }
    useEffect(()=>{
        getRoles();
    },[statusvalue,notifyrole])


  return (
    
    <>
        
        <div className="main-page-body position-relative">
          <div className="com-card-box">
            <div className="d-flex justify-content-between align-items-center gap-2">
              <div className="col-md-4">
                <h1 className="com-heading mb-0">Roles</h1>
              </div>
              {profiledata?.permission.filter(e => e.name === 'Role')[0]?.permission?.add === 1?
              <div className="col-md-3 d-flex align-items-center justify-content-end ms-auto gap-2">
                <button type="button" className="btn font-13px golden-bg d-flex align-items-center justify-content-center gap-2 text-nowrap com-btn" data-bs-toggle="modal" data-bs-target="#addRoleModal"><IoMdAddCircleOutline/>Add New</button>
              </div>:""
               }

            </div>
          </div>
      
          <div className="row mt-4">
            <div className="col-md-12">
              <div className="com-card-box">
                <div className="row d-flex gap-2">
                  <div className="col-lg-3 col-md-5">
                    <div className="input-group">
                      <div className="input-group-text bg-transparent border-end-0"><BiSearch/></div>
                      <input type="text" className="form-control border-start-0 ps-0 font-13px" value={search} onKeyPress={(e) => e.key === "Enter" && getRoles()} onChange={(e)=>setSearch(e.target.value)} placeholder="Search Rolename..."/>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-3 ms-auto">
                      <Select
                      defaultValue={statusvalue}
                      onChange={setStatusvalue}
                      options={statusoption}
                      classNamePrefix="com-select"
                      isSearchable= {false}
                    />
                  </div>
                </div>
              </div>

              <div className="com-datatable com-card-box py-1">
                {datatableloder?
                <DataTableLoaders/>
                :
                <DataTable
                  columns={columns}
                  data={roledata}
                  pagination
                  className="myTable"
                  paginationRowsPerPageOptions= {paginationRowOptions}
                />
                }
              </div>
            </div>
          </div>
        </div>
        
      <AddNewRole setNotifyrole={setNotifyrole}/>
      <EditDetail setNotifyrole={setNotifyrole} editid={editid}  setEditid={setEditid}/>
      <DeleteModal apiname={'deleteroles'} deleteItem={deleteItem} setNotifyrole={setNotifyrole} />
      <StatusModal apiname={'updatedrolestatus'} statusItem={statusItem} setNotifyrole={setNotifyrole} />
      </>
    
  )
}

export default Roles;