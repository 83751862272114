import React, {useState,useEffect} from 'react';
import Select from 'react-select';
import {CallApi} from "../common/API/APi";
import {success,error} from "../common/Toastify"; 

const EditStaff = (props) => {
     
   const {editid,setNotifyrole,setEditid} = props;

  const editoptions = [
    { value: 1, label: 'Active' },
    { value: 2, label: 'Suspend' },
  ];
  const [rolesdata, setRolesdata] = useState([]);

  const [editstaffdata, setEditstaffdata] = useState();
  const [errors, setError] = useState();

  const UpdateSTaff =async()=>{
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
                if(!editstaffdata?.name){
                  setError({ name: true });
                  return;
                }  
               if(!editstaffdata?.email || !emailRegex.test(editstaffdata?.email)){
                 setError({ email: true });
                  return;
               } 
               if(!editstaffdata?.phone){
                setError({ phone: true });
                return;
              } 
              if(!editstaffdata?.address){
                setError({ address: true });
                return;
              } 
              
              editstaffdata['status'] = editstaffdata?.status_select?.value;
              editstaffdata['role_id'] = editstaffdata?.role_select?.value;
              
              delete editstaffdata?.status_select;
              delete editstaffdata?.role_select;
          let res = await CallApi(editstaffdata, "updateadmin");
          if(res?.status === 200){
            success(res?.message);
            document.querySelector(".updatecancel").click();
            setEditid();
          setNotifyrole(current => !current);
          }else{
            error(res?.message);
          }
    }
    const getrolesdata =async()=>{
      let res = await CallApi({}, "getroledata");
      if(res?.status === 200){
        const array=[];
           res?.data?.length?res?.data.map((value)=>{
            array.push({value: value?.id, label: value?.name})   
           }):""
          setRolesdata(array);
      }
    }
    const getstaffdata =async()=>{
      setError();
      let res = await CallApi({'id':editid}, "getadminbyid");
      if(res?.status === 200){
         setEditstaffdata(res?.data);

      }
    }
    useEffect(()=>{
      if(editid){
        getstaffdata();
        getrolesdata();
      }
    },[editid])


  return(
    <div className="modal fade com-modal" id="editStaffModal" tabIndex="-1" aria-labelledby="editStaffModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5 golden-text" id="editStaffModalLabel">Edit Staff</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body pb-0">
         
            <div className='row'>
              <div className='col-md-6 mb-4'>
                <input type="text" className={errors?.name? " com-input-field border-danger" : "com-input-field"} value={editstaffdata?.name} onChange={(e)=>setEditstaffdata({...editstaffdata,name:e.target.value})} placeholder='Name'/>
              </div>
              <div className='col-md-6 mb-4'>
                <Select
                  value={editstaffdata?.role_select}
                  onChange={(e)=>setEditstaffdata({...editstaffdata,role_select:e})}
                  options={rolesdata}
                  getOptionLabel={(e) => (
                    <div className="">
                      <span>{e.label}</span>
                    </div>
                  )}
                  classNamePrefix={errors?.role_id?"com-select border-danger":"com-select"}
                  isSearchable= {false}
                />
              </div>
              <div className='col-md-6 mb-4'>
                <input type="email" className={errors?.email? " com-input-field border-danger" : "com-input-field"} value={editstaffdata?.email} onChange={(e)=>setEditstaffdata({...editstaffdata,email:e.target.value})} placeholder='Email'/>
              </div>
              <div className='col-md-6 mb-4'>
                <input type="text" className={errors?.phone? " com-input-field border-danger" : "com-input-field"} value={editstaffdata?.phone} onChange={(e)=>setEditstaffdata({...editstaffdata,phone:e.target.value})} placeholder='Contact Number'/>
              </div>
              <div className='col-md-6 mb-4'>
              <Select
                  value={editstaffdata?.status_select}
                  onChange={(e)=>setEditstaffdata({...editstaffdata,status_select:e})}
                  options={editoptions}
                  getOptionLabel={(e) => (
                    <div className="">
                      <span>{e.label}</span>
                    </div>
                  )}
                  classNamePrefix="com-select"
                  isSearchable= {false}
                />
              </div>
              <div className='col-md-12 mb-4'>
                <textarea className={errors?.address? " com-input-field h-auto resize-none border-danger" : "com-input-field h-auto resize-none"} value={editstaffdata?.address?editstaffdata?.address:""} onChange={(e)=>setEditstaffdata({...editstaffdata,address:e.target.value})} rows="3" placeholder='Address'/>
              </div>
            </div>
   
          </div>
          <div className="modal-footer text-center">
            <button type="button" className="golden-bg font-13px com-btn golden-border"  onClick={UpdateSTaff}  >Update Staff</button>
            <button type="button" className="golden-bg font-13px com-btn com-btn-outline golden-border bg-transparent updatecancel" onClick={()=>setEditid()} data-bs-dismiss="modal">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  )
} 

export default EditStaff;
