import {React,useEffect,useState} from 'react';
import Select from 'react-select';
import {CallApi} from "../common/API/APi";
import {success,error} from "../common/Toastify"; 

const AddExpenses = (props) => {
  const {editid,setNotifyrole,setEditid} = props;

  const [extypevalue, setExtypevalue] = useState([]);
  const [upexpensesdata, setupExpensesdata] = useState();
  const [errors, setError] = useState();
    const statusoption = [
        { value: 1, label: 'Active' },
        { value: 2, label: 'Suspend' },
      ];
      const upSubmitExpensesType =async()=>{
                  if(!upexpensesdata?.exp_type_select){
                    setError({ exptype: true });
                    return;
                  } 
                  if(!upexpensesdata?.exp){
                    setError({ expname: true });
                    return;
                  }   
                  if(!upexpensesdata?.amount){
                    setError({ amount: true });
                    return;
                  }   
                  upexpensesdata['status']= upexpensesdata?.status_select?.value;
                  upexpensesdata['exp_type']= upexpensesdata?.exp_type_select?.value;
                  delete upexpensesdata?.status_select;
                  delete upexpensesdata?.exp_type_select;
            let res = await CallApi(upexpensesdata, "update_expenses");
            if(res?.status === 200){
              setEditid('');
              setNotifyrole(current => !current);
              success(res?.message);
              document.getElementById("updateExpensesform").reset();
              document.querySelector(".updateallexcancel").click();
            }else{
              error(res?.message);
            }
      }
      const getexpensestype=async()=>{
        let res = await CallApi({"search":'',"status":1}, "get_expense_type");
        let array=[];
        if(res?.status === 200){
                 res?.data.map((value)=>{
                  array.push({'value':value?._id,'label':value?.expense_type});
                 })
                 setExtypevalue(array);
        }
      }
      const getexpensesdata =async()=>{
        setError();
        let res = await CallApi({'id':editid}, "get_expenses_byid");
        if(res?.status === 200){
          setupExpensesdata(res?.data);
        }
      }
      useEffect(()=>{
        if(editid){
          getexpensestype();
          getexpensesdata();
        }
      },[editid])
  return(
    <div className="modal fade com-modal" id="updateExpensesModal" tabIndex="-1" aria-labelledby="updateExpensesModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5 golden-text" id="updateExpensesModalLabel">Edit Expenses Type</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
          <form id="updateExpensesform">
            <div className='row'>
              <div className='col-md-6 col-12 col-xs-12 mb-3'>
                <Select
                  value={upexpensesdata?.exp_type_select}
                  onChange={(e)=>setupExpensesdata({...upexpensesdata,'exp_type_select':e})}
                  options={extypevalue}
                  classNamePrefix={errors?.exptype? " com-select border-danger" : "com-select"}
                  placeholder='Expenses Type'
                  isSearchable= {false}
                />
              </div>
              <div className='col-md-6 col-12 col-xs-12 mb-3'>
                <input type="text" className={errors?.expname? " com-input-field border-danger" : "com-input-field"} value={upexpensesdata?.exp} onChange={(e)=>setupExpensesdata({...upexpensesdata,'exp':e.target.value})} placeholder='Expenses'/>
              </div>
              <div className='col-md-6 col-12 col-xs-12 mb-3'>
                <input type="text" className={errors?.amount? " com-input-field border-danger" : "com-input-field"} value={upexpensesdata?.amount} onChange={(e)=>setupExpensesdata({...upexpensesdata,'amount':e.target.value})} placeholder='Amount'/>
              </div>
              <div className='col-md-6 col-12 col-xs-12 mb-3'>
                <Select
                    value={upexpensesdata?.status_select}
                    onChange={(e)=>setupExpensesdata({...upexpensesdata,status_select:e})}
                  options={statusoption}
                  classNamePrefix="com-select"
                  isSearchable= {false}
                />
              </div>
              <div className='col-md-12 col-12 col-xs-12 mb-3'>
                <textarea type="text" rows="3" className="com-input-field h-auto resize-none" value={upexpensesdata?.comment} onChange={(e)=>setupExpensesdata({...upexpensesdata,'comment':e.target.value})} placeholder='Comment'/>
              </div>
              
            </div>
            </form>
          </div>
          <div className="modal-footer text-center mt-3">
            <button type="button" className="golden-bg font-13px com-btn golden-border" onClick={upSubmitExpensesType} >Update Expenses</button>
            <button type="button" className="golden-bg font-13px com-btn com-btn-outline golden-border bg-transparent updateallexcancel" data-bs-dismiss="modal">Cancel</button> 
          </div>
        </div>
      </div>
    </div>
  )
} 

export default AddExpenses;
