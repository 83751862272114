// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-footer {
  width: 100%;
  bottom: 0;
  font-size: 13px;
  padding: 16px 0;
  border-top: 1px solid #dde1ef;
}`, "",{"version":3,"sources":["webpack://./src/components/common/footer/footer.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,SAAA;EACA,eAAA;EACA,eAAA;EACA,6BAAA;AADF","sourcesContent":["\n\n.main-footer{\n  width: 100%;\n  bottom: 0;\n  font-size: 13px;\n  padding: 16px 0;\n  border-top: 1px solid #dde1ef;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
