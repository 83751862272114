import {React} from "react";
import "./header.scss";
import {Link,useNavigate} from "react-router-dom";
import UserIcon from "../../../assets/images/user.png"
import { TbLogout } from "react-icons/tb"
import { BiBell } from "react-icons/bi"
import { CgProfile } from "react-icons/cg"
import { ToastContainer } from "react-toastify";
import Moment from 'react-moment';


const Header = (props) => {
  let {notificationdata,Setnotifytab,notifytab}=props;
 
  const profiledata= JSON.parse(localStorage.getItem('profiledata'));
  let navigate=useNavigate();
  const Logout=()=>{
    localStorage.clear();
    setTimeout(() => {
      navigate("/");
    }, 1000);
  }

 
  return(
    <>
     <ToastContainer></ToastContainer>
    <header className="main-header position-fixed top-0 end-0 d-flex align-items-center bg-white">
      <nav className="navbar navbar-expand-lg navbar-light p-0 w-100">
          <div className="navbar-collapse d-flex" id="navbarSupportedContent">
            <div className="main-header-right d-flex align-items-center ms-auto">
            {notificationdata?.Notification?.length?
                <div className="dropdown common-dropdown notification-area dropstart" onClick={()=>Setnotifytab(false)}>
                  <button className="notification-area-btn dropdown-toggle after-before-none main-header-right-icon hover-bg me-3" type="button" id="notify" data-bs-toggle="dropdown" aria-expanded="false"><BiBell/>
                 {(notificationdata?.Status && notifytab)?<span className='notification-area-count'><span className='notification-area-count-num'></span></span>:''} </button>
                
                  <div className="dropdown-menu" aria-labelledby="notify">
                    <h3 className='notification-area-heading golden-text'>Notifications</h3>
                      <ul className='notification-area-list'>
                          {notificationdata?.Notification.map((value,key)=>{
                            let color='';
                             if(value?.role_id){
                                color='rolebackground';
                             }else if(value?.ReceiverId){
                              color='staffbackground';
                             }else if(value?.driver_id){
                              color='assignbackground';
                              
                             }else{
                              color='orderbackground';
                             }
                             return(
                                <li key={key} className='notification-area-list-single hover-bg '>
                                <Link to={value?.navigate?value?.navigate:'#'} className={`notification-area-list-single-a ${color} `}>
                                  <span className='notification-area-list-single-text'>{value?.message}</span>
                                  <div className='notification-area-list-single-foot'>
                                    <span className='notification-area-list-single-foot-time'><Moment format="MMM DD, YYYY : hh:mm A">{value?.createdAt}</Moment></span>
                                    <span className='notification-area-list-single-foot-user'>
                                      <i className="fa fa-user notification-area-list-single-foot-user-icon" aria-hidden="true"></i>
                                      {value?.CreatedBy}
                                    </span>
                                  </div>
                                </Link>
                              </li>
                             );
                          })}
                      </ul>
                  </div>
                 
                </div>

                 :  
                <div className="dropdown common-dropdown notification-area dropstart">
                  <button className="notification-area-btn dropdown-toggle after-before-none main-header-right-icon hover-bg me-3" type="button"    id="notify" data-bs-toggle="dropdown" aria-expanded="false"><BiBell/>
                 </button>
                  
                  <div className="dropdown-menu" aria-labelledby="notify">
                    <h3 className='notification-area-heading golden-text'>Notifications</h3>
                    <ul className='notification-area-list'>
                      <li  className='notification-area-list-single hover-bg'>
                        <p className="notification-area-list-single-text text-center p-2">No Notifications</p>
                      </li>
                    </ul>
                  </div>
                </div>
             }
           
              <div className="main-header-right-user-img">
                <img src={UserIcon} alt=""/>

              </div>
              <div className="btn-group main-header-right-dropdown" >
                <button type="button" className="btn p-0 py-3 text-start bg-transparent border-0 text-dark dropdown-toggle after-before-none fw-semibold text-capitalize" data-bs-toggle="dropdown" aria-expanded="false">
                 {profiledata?.name} <span className="d-block main-gray text-capitalize">{profiledata?.rolename}</span>
                </button>
                <ul className="main-header-right-dropdown-list dropdown-menu dropdown-menu-end p-0 overflow-hidden">
                  <li className="main-gray px-3 py-2 text-nowrap">Welcome {profiledata?.name}!</li>
                  <li><Link className="dropdown-item py-2" to="/profile" type="button"><CgProfile/>Profile</Link></li>
                  <li><button className="dropdown-item py-2 main-header-right-dropdown-list-logout" onClick={Logout} type="button"> <TbLogout/> Logout</button></li>
                </ul>
              </div>
            </div>
          </div>
      </nav>
    </header>
  
    </>
  )
}

export default Header;