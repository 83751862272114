import {React,useEffect,useState} from 'react';
import ReminderModal from './ReminderNotesmodal';
import {error} from "../common/Toastify";
import {CallApi} from "../common/API/APi";
import Moment from 'react-moment';


const CustomerReminder = (props) => {
 const {custemail} =props;
 const [ordersdata,setOrdersdata]=useState();
 const [cusorderid,setCusorderid]=useState();
 const  getordersbycust=async()=>{
  let res = await CallApi({'customer_email':custemail?.email}, "getordersby_customers");    
  if(res?.status === 200){
       setOrdersdata(res?.data);
  }else{
    error(res?.message);
  }
 }
 useEffect(()=>{
       if(custemail){
           getordersbycust();
       }
 },[custemail])

  return (<>
    <div className="modal fade com-modal" id="customerreminderModal" tabIndex="-1" aria-labelledby="customerreminderModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className='reminder-head'>
              <p className="modal-title golden-text" id="customerreminderModalLabel">Customer Reminder</p>
              <span className='fs-5 golden-text'> - </span>
              <p className="modal-title golden-text" >{custemail?.name}</p>
            </div>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body pb-0">
            <div className='customer-reminder mb-4 table-responsive'>
              <table className="customer-reminder-table table">
                <thead>
                  <tr>
                    <th className='w-50'>Order ID</th>
                    <th>Last Updated Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {ordersdata?.length?ordersdata.map((value,keys)=>{
                    return(
                      <tr key={keys}>
                      <td>{value?.orderid}</td>
                      <td>{value?.last_chat?<Moment format={'MMMM DD,YYYY hh:mm A'}>{value?.last_chat}</Moment>:''}</td>
                      <td>
                        <button className='btn font-12px golden-bg d-flex align-items-center justify-content-center gap-2 golden-btn-respn-show' data-bs-toggle="modal" data-bs-target="#reminderNoteModal" onClick={()=>setCusorderid({'id':value?.orderid,'name':custemail?.name})}>Reminder Notes</button>
                      </td>
                    </tr>
                    );
                  }):<center>-</center>}
                 
                
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ReminderModal reminderItem={cusorderid}/>
</>
  );
};

export default CustomerReminder;
