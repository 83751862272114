import React,{useEffect, useState} from "react";

import Sidebar from "./sidebar/Sidebar";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import { Outlet } from "react-router-dom";
import {CallApi} from "../common/API/APi";


const Layout = () => {
  const [notificationdata,setNotificationdata]=useState();
  const[notifytab,Setnotifytab]=useState();

  const [alert,setalert]=useState();
  const getNotification =async()=>{
    let res = await CallApi({}, "notification");
    if(res?.status === 200){
      setNotificationdata(res?.data);
      if(res?.data?.Status){
        Setnotifytab(true);

      }
    }

  }
  useEffect(()=>{
    getNotification();
  },[alert])
  return(
    <>

      <div className="main-page">
        <div className="main-page-row d-flex">
          <Sidebar  setalert={setalert}/>
          <div className="main-page-row-right">
            <Header notificationdata={notificationdata} Setnotifytab={Setnotifytab} notifytab={notifytab} />
            <div className="main-page-body-scroll">
              <Outlet/>
              <Footer/>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Layout;