import React from 'react';
import { BsExclamationCircle } from "react-icons/bs";
import {CallApi} from "../common/API/APi";
import {success,error} from "../common/Toastify"; 

const DeleteModal = (props) => {
  const {apiname,deleteItem,setNotifyrole}=props;

  const deleteitemsData=async()=>{
    if(deleteItem){
      const  postdata={"id":deleteItem}
      let res = await CallApi(postdata, apiname);
      if(res?.status === 200){
        setNotifyrole(current => !current);
        success(res?.message);
      }else{
        error(res?.message);
      }
    }
   
    }

  return (
    <div
      className="modal fade com-modal delete-item-modal"
      id="deleteitemModal"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="deleteitemModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog  modal-dialog-centered delete-item-modal-dialog"
      >
        <div className="modal-content">
          <div className="modal-body">
            <div className="text-center mb-2 mt-3">
              <BsExclamationCircle className="exclama-deleteIcon" />
            </div>
            <p className="text-center mt-4 font-15px">
              Are you sure you want to <b>Delete</b> ?
            </p>
          </div>
          <div className="pb-4 gap-3 text-center d-flex justify-content-center">
            <button
              onClick={deleteitemsData}
              data-bs-dismiss="modal"
              type="button"
              className="font-13px com-btn danger-bg delete-item-modal-footer-btn"
            >
              Yes, Delete!
            </button>
            <button
              type="button"
              className="font-13px com-btn danger-color bg-white delete-item-modal-footer-btn"
              data-bs-dismiss="modal"
            >
              No,Keep it.
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteModal