import React,{useEffect, useState} from "react";
import DataTable from 'react-data-table-component';
import { BiSearch } from "react-icons/bi"
import { FiUploadCloud } from "react-icons/fi"
import { Link } from "react-router-dom";
import {CallApi} from "./common/API/APi";
import SendEmailModal from "./modal/SendemailModal";
import DataTableLoaders from "./common/DatatableLoader";
import {success,error} from "./common/Toastify";
import { DateRange } from "./modal/ComDatePicker";
import SendMessage from "./modal/SendMessage";
import CustomerReminder from "./modal/CustomerRminder";
import BulksendEmail from "./modal/Bulksendmail";
import BulksendMessage from "./modal/Bulksendmessage";
import { TbMailPlus,TbMessageCirclePlus  } from "react-icons/tb";
import { FaCarOn } from "react-icons/fa6";
import Moment from "react-moment";

const Customers = () => {
  const profiledata= JSON.parse(localStorage.getItem('profiledata'));
  const [search,setSearch]=useState('');
  const [customerdata,setCustomerdata]=useState([]);
  const [EmailItem,setEmailItem]=useState();
  const [notifyrole,setNotifyrole]=useState();
  const [datatableloder,setDatatableloder]=useState();
  const [custemail,setCustemail]=useState();
  const [datesearch,setDateSearch]=useState([]);
  const [MessageItem,setMessageItem]=useState();
  const [bulkmails,setBulkmails]=useState([]);
  const path_file=process.env.REACT_APP_PATH_URL;
  const paginationRowOptions = [50, 100, 500, 1000];
  const [limit, setLimit] = useState(paginationRowOptions[0]);
  const [TotalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);


  const Checkbox = React.forwardRef(function Checkbox({onChange,row,...rest }, ref) {
    return (
        <>
            <div className="form-check com-table-check-box" style={{ backgroundColor: '' }}>
                <input
                    type="checkbox"
                    className="form-check-input"
                    style={{ height: '20px', width: '20px' }}
                    onChange={(e) => onChange(e, row)}
                    ref={ref}
                    {...rest}
                />
              
            </div>
        </>
    );
});


const columns = [
  
  {name: 'S.No.',selector: row => row.id,sortable: true,width:"80px"},
  {name: 'Customer Name',selector: row => row.customer,sortable: true,minWidth:"200px"},
  {name: 'Email Id',selector: row => row.email,sortable: true,minWidth:"240px"},
  {name: 'Phone',selector: row =><>{<a style={{"text-decoration": "none","color":" #000","cursor": "pointer"}} href={`tel:${row.phone}`}>{row.phone}</a>}</>,sortable: true,minWidth:"130px"},
  {name: 'Customer Retention ',selector: row => row.custretention,sortable: true,minWidth:"190px"},
  {name: 'Total Order',selector: row => row.totalorder,sortable: true,minWidth:"130px"},
  {name: 'Register Date',selector: row => row.RegisterDate,sortable: true,minWidth:"160px"},
  {name: 'Last Order Date',selector: row => row.LastOrderDate,sortable: true,minWidth:"160px"},

  { name: 'Action',sortable: false , minWidth: '300px',

  selector: (row) => <> <Link to="/Order" state={{"customer_id":row?.action?.customer}}><button className="cell-tabs bg-info text-white ">Orders</button></Link> {profiledata?.permission.filter(e => e.name === 'Customer')[0]?.permission?.mail === 1 ?<button className="cell-tabs bg-success text-white " data-bs-toggle="modal" onClick={()=>setEmailItem({'email':row?.action?.email})} data-bs-target="#SendEmailModal">Send Email</button>:'' }{profiledata?.permission.filter(e => e.name === 'Customer')[0]?.permission?.message === 1 ?<button className="cell-tabs bg-warning text-white " onClick={()=>setMessageItem({'to':row?.action?.phone})} data-bs-target="#SendMessageModal" data-bs-toggle="modal" >Send Message</button>:""} <button className="cell-tabs bg-secondary text-white " data-bs-target="#customerreminderModal" data-bs-toggle="modal" onClick={()=>setCustemail({'email':row?.action?.email,'name':row?.action?.name})} >Reminder Notes</button> </>,
  button: true}
];

const getcustomer=async()=>{
  setDatatableloder(true);
  const skip = (currentPage - 1) * limit;
  let res = await CallApi({"search":search,"start_date": datesearch[0]?datesearch[0]:'',
  "end_date": datesearch[1]? datesearch[1]:'',limit:limit,skip:skip}, "getcustomers");
  if(res?.status === 200){
    let array=[];
    if( res?.data?.length){
      res?.data.map((value,key)=>{
        array.push({
            
                    "id":key+1,
                    "customer":value?.customername,
                    "email":value?.email,
                    "phone":value?.phone,
                    "custretention":value?.total_orders,
                    "totalorder": value?.total_orders!=0? <Link to="/Order" className='text-decoration-none text-black' state={{"customer_id":{"value":value?.email,'label':value?.customername}}}>{value?.total_orders}</Link>:0,
                    
                    "RegisterDate":<Moment format="MMM DD, YYYY : hh:mm A">{value?.register_date}</Moment>,
                    "LastOrderDate":value?.update_date?<Moment format="MMM DD, YYYY : hh:mm A">{value?.update_date}</Moment>:"",
                    "action":{'id':value?.id,'customer':{"value":value?.email,'label':value?.customername},'email':value?.email,'phone':value?.phone,'name':value?.customername}
        })
      })
     
    
    }  
    setCustomerdata(array);
    setTotalRows(res?.TotalRows);
    setDatatableloder(false);
  }
 }
 const downloadcsv=async()=>{
  success("Wait a minute! Backend process is start");
  let res = await CallApi({}, "exportcsv");    
  if(res?.status === 200){
    const a = document.createElement("a");
    a.href = path_file+res?.file;
    a.download = "Customers.xlsx"; 
    a.click();
  }else{
    error("Something Went Wrong!");
  }
 }   
 const downloadpdf=async()=>{  
  success("Your Request is Submited, Soon You Download The PDF Via Mail!");
let res = await CallApi({}, "exportpdf");  
if(res?.status != 200){
 error("Something Went Wrong!");
}
}  

 useEffect(()=>{
  getcustomer();
 },[notifyrole,datesearch,limit,currentPage])

 const handleSelectedRowsChange = ({ selectedRows }) => {
  let array=[];
  if(selectedRows?.length){
    selectedRows.map((value)=>{
        array.push({'mail':value?.email,'phone':value?.phone});
    })
  }
  setBulkmails(array);
}
  return (
    
            <div className="main-page-body position-relative">
              <h1 className="com-heading">Customers</h1>

              <div className="com-card-box">
                <div className="row align-items-center">
                  <div className="col-md-4">
                    <h1 className="com-heading mb-0">Customers List</h1>
                  </div>
                  <div className="col-md-3 d-flex align-items-center justify-content-end ms-auto gap-2 res-all-golden-btn ">

                  <button type="button" className="btn font-13px golden-bg d-flex align-items-center justify-content-center gap-2 golden-btn-respn-show"><Link className="text-decoration-none text-black" target="_blank" to={process.env.REACT_APP_FRONTEND_URL+profiledata?.id}><FaCarOn/> Create New Booking</Link> </button>

                  <button type="button" onClick={downloadpdf} className="btn font-13px golden-bg d-flex align-items-center justify-content-center gap-2 golden-btn-respn-show"><FiUploadCloud/> Download PDF</button>
                  <button type="button" onClick={downloadcsv} className="btn font-13px golden-bg d-flex align-items-center justify-content-center gap-2 golden-btn-respn-show"><FiUploadCloud/> Download CSV</button>
                  {bulkmails?.length?
                    <>
                  <button type="button"  data-bs-target="#BulksendEmailModal" data-bs-toggle="modal"  className="btn font-13px golden-bg d-flex align-items-center justify-content-center gap-2 golden-btn-respn-show"><TbMailPlus /> Send Email</button>
                  <button type="button" data-bs-target="#BulksendMessageModal" data-bs-toggle="modal"  className="btn font-13px golden-bg d-flex align-items-center justify-content-center gap-2 golden-btn-respn-show"><TbMessageCirclePlus /> Send Message</button>
                  </>
                   :
                   <>
                   <button type="button" onClick={()=>error('No email ids is selected!')}  className="btn font-13px golden-bg d-flex align-items-center justify-content-center gap-2 golden-btn-respn-show"><TbMailPlus /> Send Email</button>
                   <button type="button" onClick={()=>error('No phone numbers is selected!')}  className="btn font-13px golden-bg d-flex align-items-center justify-content-center gap-2 golden-btn-respn-show"><TbMessageCirclePlus /> Send Message</button>
                   </>
                  }
                
               
                  </div>
                </div>
              </div>

              <div className="com-card-box">
                <div className="row">
                  <div className="col-lg-3 col-md-5 col-sm-6 mb-3 mb-sm-0">
                    <div className="input-group">
                      <div className="input-group-text bg-transparent border-end-0"><BiSearch/></div>
                      <input type="text" className="form-control border-start-0 ps-0 font-13px" value={search} onKeyPress={(e) => e.key === "Enter" && getcustomer() } onChange={(e)=>{setSearch(e.target.value);setCurrentPage(1);}} placeholder="Search Customer Name And Email..."/>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-6 ms-auto col-sm-6 col-12 order-datatable-filter-container">
                    <div>
                      <DateRange setDateSearch={setDateSearch}  setCurrentPage={setCurrentPage}/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="com-datatable com-card-box py-2">
                
                  
            {datatableloder?
                <DataTableLoaders/>
                :
                <DataTable
                columns={columns}
                data={customerdata}
                pagination
                paginationServer
                fixedHeaderScrollHeight="300px"
                className="myTable"
                selectableRows
                selectableRowsComponent={(props) => (
                  <Checkbox {...props} />
                )}
                onSelectedRowsChange={handleSelectedRowsChange}          
                responsive
                striped
                paginationRowsPerPageOptions= {paginationRowOptions}
                paginationPerPage={limit}
                paginationTotalRows={TotalRows}
                onChangeRowsPerPage={(newLimit) => {
                  setLimit(newLimit);
                  setCurrentPage(1);
                }}
                onChangePage={(page) => {setCurrentPage(page)}}
                paginationDefaultPage={currentPage}
              />
                }
              </div>
            <SendEmailModal EmailItem={EmailItem} setNotifyrole={setNotifyrole} />
            <SendMessage MessageItem={MessageItem} setNotifyrole={setNotifyrole} />
            <CustomerReminder custemail={custemail}/>
            <BulksendEmail bulkmails={bulkmails} setNotifyrole={setNotifyrole}/>
            <BulksendMessage bulkmails={bulkmails} setNotifyrole={setNotifyrole}/>
            
            </div>
    
  )
}

export default Customers;