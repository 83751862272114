import React from "react";

const DoneIcon = () => {
  return (
    <div>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet">
   
   <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
   fill="#000000" stroke="none">
   <path d="M2323 5110 c-598 -58 -1146 -316 -1571 -740 -397 -397 -640 -882
   -729 -1460 -24 -161 -24 -539 0 -700 89 -577 330 -1059 727 -1458 397 -397
   882 -640 1460 -729 161 -24 539 -24 700 0 577 89 1059 330 1458 727 397 397
   640 882 729 1460 24 161 24 539 0 700 -89 577 -330 1059 -727 1458 -394 395
   -885 641 -1450 727 -125 19 -468 27 -597 15z m562 -415 c462 -72 869 -279
   1200 -610 334 -334 538 -738 612 -1210 12 -78 17 -170 17 -315 0 -145 -5 -237
   -17 -315 -74 -472 -278 -876 -612 -1210 -334 -334 -738 -538 -1210 -612 -151
   -24 -479 -24 -630 0 -472 74 -876 278 -1210 612 -334 334 -538 738 -612 1210
   -24 151 -24 479 0 630 74 472 278 876 612 1210 368 368 805 572 1350 629 81 8
   403 -4 500 -19z"/>
   <path d="M3470 3414 c-22 -9 -257 -237 -647 -627 l-613 -611 -257 256 c-142
   140 -272 262 -288 271 -103 52 -219 15 -270 -87 -30 -60 -32 -115 -5 -170 12
   -27 142 -163 367 -387 274 -273 356 -349 387 -359 51 -16 128 -7 169 21 48 33
   1363 1347 1400 1399 28 40 32 53 32 112 0 58 -4 72 -30 109 -56 79 -159 110
   -245 73z"/>
   </g>
   </svg>
    </div>
  );
};

export default DoneIcon;
