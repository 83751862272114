import React, { useEffect, useState } from 'react';
import { GoogleMap, Marker, useLoadScript, Polyline } from '@react-google-maps/api';
import car from '../../assets/images/UberBlack.webp';

const mapContainerStyle = {
  width: '100%',
  height: '500px'
};

const center = {
  lat: 26.907524,
  lng: 75.739639
};

const GoogleMapcom = ({ currentLocation }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
  });

  const [path, setPath] = useState([]);
  const [carIcon, setCarIcon] = useState(null);

  useEffect(() => {
    if (isLoaded) {
      setCarIcon({
        url: car,
        scaledSize: new window.google.maps.Size(50, 50),
      });
    }
  }, [isLoaded]);

  useEffect(() => {
    if (isLoaded && currentLocation?.currentloc && currentLocation?.toloc) {
      const directionsService = new window.google.maps.DirectionsService();

      directionsService.route(
        {
          origin: currentLocation.currentloc,
          destination: currentLocation.toloc,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            const routePath = result.routes[0].overview_path.map(point => ({
              lat: point.lat(),
              lng: point.lng()
            }));
            setPath(routePath);
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    }
  }, [currentLocation,isLoaded]);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps</div>;

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={15}
      center={currentLocation?.currentloc || center}
    >
      {currentLocation?.currentloc && (
        <>
          <Marker
            position={currentLocation.currentloc}
            icon={carIcon}
          />
          <Marker position={currentLocation.toloc} />
          <Polyline
            path={path}
            options={{
              strokeColor: '#FF0000',
              strokeOpacity: 1.0,
              strokeWeight: 8,
            }}
          />
        </>
      )}
    </GoogleMap>
  );
};

export default GoogleMapcom;
