import {React,useState} from 'react';
import Select from 'react-select';
import {CallApi} from "../common/API/APi";
import {success,error} from "../common/Toastify"; 

const AddDriver = (props) => {
    let {setNotifyrole} =props;
  const [statusvalue, setStatusvalue] = useState({value: 1, label: 'Active'});
  const [expensesdata, setExpensesdata] = useState('');
  const [errors, setError] = useState();
    const statusoption = [
        { value: 1, label: 'Active' },
        { value: 2, label: 'Suspend' },
      ];
      const SubmitExpensesType =async()=>{
                  if(!expensesdata){
                    setError({ expensestype: true });
                    return;
                  }   
            let res = await CallApi({'expense_type':expensesdata,'status':statusvalue?.value}, "add_expense_type");
            if(res?.status === 200){
              setNotifyrole(current => !current);
              success(res?.message);
              document.getElementById("addExpensesTypeform").reset();
              document.querySelector(".addexcancel").click();
            }else{
              error(res?.message);
            }
      }
  return(
    <div className="modal fade com-modal" id="addExpensesModal" tabIndex="-1" aria-labelledby="addExpensesModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5 golden-text" id="addExpensesModalLabel">Add Expenses Type</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
          <form id="addExpensesTypeform">
            <div className='row'>
              <div className='col-md-6 mb-3'>
              <input type="text" className={errors?.expensestype? " com-input-field border-danger" : "com-input-field"} value={expensesdata} onChange={(e)=>setExpensesdata(e.target.value)} placeholder='Expenses Type'/>
              </div>
           
              <div className='col-md-6 mb-3'>
              <Select
                  defaultValue={statusvalue}
                  onChange={setStatusvalue}
                  options={statusoption}
                  classNamePrefix="com-select"
                  isSearchable= {false}
                />
              </div>
            </div>
            </form>
          </div>
          <div className="modal-footer text-center mt-3">
            <button type="button" className="golden-bg font-13px com-btn golden-border" onClick={SubmitExpensesType} >Create Expenses Type</button>
            <button type="button" className="golden-bg font-13px com-btn com-btn-outline golden-border bg-transparent addexcancel" data-bs-dismiss="modal">Cancel</button> 
          </div>
        </div>
      </div>
    </div>
  )
} 

export default AddDriver;
