import { React,useState,useEffect } from "react";
import {CallApi} from "../common/API/APi";
import {success,error} from "../common/Toastify"; 
import axios from "axios";

const OrderRefund = (props) => {
   const {refunditem,setNotifyrole}= props;
      const[refundata,setRefundata]=useState();
      const [errors, setError] = useState();


   const submitrefund=async()=>{
    if(!refundata?.refund){
        setError({ refund: true });
        return;
      }
      if(parseInt(refundata?.refund) > parseInt(refunditem?.bookingamount)){
        setError({ refund: true });
        return;
      }
      if(parseInt(refundata?.totalrefundamount) >= parseInt(refunditem?.bookingamount)){
        setError({ refund: true });
        error('You already refunded all amount!');
        return;
      }
      if(!refundata?.refund_comment){
        setError({ refund_comment: true });
        return;
      }
      refundata['id']=refunditem?.id;
      if(refunditem?.payment_mode == 'stripe'){
        refundata.refund=refundata.refund*100;

        await  axios({
        url: "https://api.stripe.com/v1/refunds",
        method: "POST",
        headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_STRIPE_SKEY}`,
             'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: new URLSearchParams({
            charge: refunditem?.chargeid,
            amount: refundata?.refund.toString(),
          }),
    })
        .then(async() => {
            refundata.refund=refundata.refund/100;
            let res = await CallApi(refundata, "refund");
            if(res?.status === 200){
              setNotifyrole(current => !current);
              success("Successfully refund the amount,check in your stripe dashboard!");
              document.getElementById("refundform").reset();
              document.querySelector(".cancelrefund").click();
            }else{
              error(res?.message);
            }
        })
        .catch((err) => {
            error(err);
        });
    }else if(refunditem?.payment_mode == 'payPal'){

        let res = await CallApi(refundata, "refund");
        if(res?.status === 200){
            setNotifyrole(current => !current);
            success("Refund amount added in expense but you have to refund the amount through paypal account!");
            document.getElementById("refundform").reset();
            document.querySelector(".cancelrefund").click();
        }else{
            error(res?.message);
        }
    }else{
         error("Missing transcation id!");
         return;
    }

   }

   useEffect(()=>{
    if(refunditem){
        setRefundata('');
        setError('');
    }
    
   },[refunditem])
    return (
        <div
        className="modal fade com-modal"
        id="orderRefundModal"
        tabIndex="-1"
        aria-labelledby="orderRefundModalLabel"
        aria-hidden="true"
        >
        <div className="modal-dialog">
            <div className="modal-content">
            <div className="modal-header">
                <h1
                className="modal-title fs-5 golden-text"
                id="orderRefundModalLabel"
                >
                Order Refund
                </h1>
                <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ></button>
            </div>
            <div className="modal-body">
                <form id="refundform">
                <div className="row">
                    <div className=" order-refund-amount-box d-flex justify-content-start align-items-center">
                        <p className="order-refund-amount-box-para m-0 ">
                            Refund Amount :
                        </p>
                        <div className="d-flex justify-content-start  align-items-center  gap-1 ms-2">
                            <span className="order-refund-amount-box-para  ">$</span>
                            <input type="text" className={errors?.refund? " com-input-field border-danger" : "com-input-field"} value={refundata?.refund?refundata?.refund:''} onChange={(e)=>setRefundata({...refundata,'refund':e.target.value})} />
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className=" order-refund-amount-box ">
                        <p className="order-refund-amount-box-para mb-1 ">
                            Comment
                        </p>
                        <textarea rows="4"  value={refundata?.refund_comment?refundata?.refund_comment:''} onChange={(e)=>setRefundata({...refundata,'refund_comment':e.target.value})} className={errors?.refund_comment?"com-input-field resize-none overflow-auto h-auto border-danger":'com-input-field resize-none overflow-auto h-auto'} />
                    </div>
                </div>
                </form>
            </div>
            <div className="modal-footer text-center mt-3">
                <button
                type="button"
                className="golden-bg font-13px com-btn golden-border"
                onClick={submitrefund}
                >
                Submit
                </button>
                <button
                type="button"
                className="golden-bg font-13px com-btn com-btn-outline golden-border bg-transparent cancelrefund"
                data-bs-dismiss="modal"
                >
                Cancel
                </button>
            </div>
            </div>
        </div>
        </div>
    );
};

export default OrderRefund;
