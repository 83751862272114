import React,{useState,useEffect} from 'react';
import {CallApi} from "../common/API/APi";
import {success,error} from "../common/Toastify"; 


const EditVehicleModel = (props) => {
  const {vehiclemodelid,setNotifyrole}=props;
  const [vehiclemodeldata,setVehiclemodeldata]=useState([]);
  const getvehiclemodelidbyid=async()=>{
          let res= await CallApi({'id':vehiclemodelid},'get_pricelistbyid');
          if(res?.status ==200){
            setVehiclemodeldata(res?.data?.vehicle_model);
          }
  }
  const updatesamount=(type,targetvalue,keys)=>{
          let dataarray=[...vehiclemodeldata];
          dataarray.map((value)=>{
              if(value?.id == type){
                dataarray[keys]['name']=targetvalue;
              }
          })
          setVehiclemodeldata(dataarray);
  }

  const updatevehiclemodeldata=async()=>{
    let inputs= document.querySelectorAll(".transfer-car-amount-box-show input");
    for(let i=0;i<inputs.length;i++){
      if(inputs[i].value == ''){
        error("Transfer distance and amount should not be empty!");
        return;
      }
    }
    let res= await CallApi({'vehicle_model':vehiclemodeldata,'id':vehiclemodelid},'update_pricelist');
    if(res?.status ==200){
      success(res?.message);
      setNotifyrole(current => !current);
      document.querySelector(".updatevehiclecancel").click();
    }else{
      error(res?.message);
    }
}
  useEffect(()=>{
         if(vehiclemodelid){
          getvehiclemodelidbyid();
         }
  },[vehiclemodelid])
return(
  <div className="modal fade com-modal" id="editvehiclemodelModal" tabIndex="-1" aria-labelledby="editTransferpriceModal" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5 golden-text" id="editTransferpriceModal">Edit Transfer</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body pb-0">
        <form id="">
          {vehiclemodeldata?.length?vehiclemodeldata.map((value,key)=>{
            return(
              <div className="row" key={key}>     
                  <div className=" transfer-car-amount-box d-flex justify-content-start align-items-center ms-auto my-1" >
                      <p className="transfer-car-amount-box-para m-0  text-nowrap font-15px">
                          Car Name {key+1}:
                      </p>
                      <div className="transfer-car-amount-box-show d-flex justify-content-start  align-items-center  gap-1 ms-auto">
                          <input type="text"  value={value?.name} onChange={(e)=>updatesamount(value?.id,e.target.value,key)} className= "com-input-field" style={{height: "32px",width: "60%"}}/>
                      </div>
                  </div>
              </div>
            );
          }):''}
          
          
        </form>

      </div>
      <div className="modal-footer text-center">
        <button type="button" className="golden-bg font-13px com-btn golden-border" onClick={updatevehiclemodeldata} >Update</button>
        <button type="button" className="golden-bg font-13px com-btn com-btn-outline golden-border bg-transparent updatevehiclecancel" data-bs-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>
);
}
export default EditVehicleModel;