import React from "react";

const CarDriverIcon = () => {
  return (
    <div>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M2310 5113 c-566 -39 -987 -167 -1285 -388 -87 -64 -223 -205 -271
-281 -93 -145 -135 -283 -135 -444 0 -194 61 -340 196 -475 90 -91 198 -153
320 -184 l70 -18 6 -319 c6 -341 12 -394 66 -559 82 -253 261 -506 466 -658
l67 -50 0 -117 0 -117 -157 -6 c-110 -4 -181 -11 -233 -25 -394 -104 -675
-382 -780 -772 -21 -78 -23 -112 -28 -392 l-4 -308 1952 0 1952 0 -4 308 c-4
270 -7 316 -25 382 -93 337 -306 593 -603 721 -140 60 -234 80 -412 86 l-158
6 0 118 0 118 48 32 c26 18 95 80 153 138 160 160 265 330 332 536 54 165 60
218 66 559 l6 319 53 13 c257 66 447 247 517 494 28 96 26 270 -3 373 -87 307
-352 565 -738 721 -329 132 -745 199 -1209 194 -99 -1 -200 -3 -225 -5z m730
-322 c368 -49 660 -147 859 -288 130 -94 208 -181 262 -297 125 -267 -14 -533
-307 -587 -31 -6 -559 -8 -1330 -7 l-1279 3 -58 22 c-178 67 -268 189 -268
365 0 102 27 186 92 283 193 289 633 474 1244 525 175 14 616 4 785 -19z m540
-1487 c0 -3 -14 -33 -32 -66 -36 -68 -113 -142 -182 -177 -103 -51 -105 -51
-806 -51 -701 0 -703 0 -806 51 -86 43 -186 152 -209 227 l-6 22 1020 0 c562
0 1021 -3 1021 -6z m-1850 -556 l95 -33 725 0 c778 0 763 -1 893 52 33 14 85
40 115 60 l55 35 -7 -87 c-31 -386 -299 -750 -651 -886 -160 -62 -215 -72
-395 -72 -183 0 -246 12 -405 77 -353 143 -610 497 -641 881 l-7 86 64 -40
c35 -22 107 -55 159 -73z m575 -1214 c67 -14 132 -19 255 -19 171 0 279 16
398 56 l52 18 0 -225 c0 -123 -3 -224 -7 -224 -5 0 -33 7 -63 15 -85 23 -271
45 -380 45 -109 0 -295 -22 -380 -45 -30 -8 -58 -15 -62 -15 -5 0 -8 101 -8
224 l0 225 53 -18 c28 -10 93 -27 142 -37z m-495 -423 l0 -89 -91 -53 c-235
-138 -449 -354 -586 -591 l-45 -78 -89 0 -89 0 0 110 c0 294 116 519 346 670
128 84 253 118 437 119 l117 1 0 -89z m1798 73 c276 -57 501 -269 578 -543 8
-31 18 -118 21 -199 l6 -142 -91 0 -90 0 -45 78 c-137 237 -351 453 -586 591
l-91 53 0 89 0 89 110 0 c62 0 145 -7 188 -16z m-858 -299 c96 -14 181 -34
234 -55 l30 -11 -227 -227 -227 -227 -225 225 c-123 123 -221 227 -217 231 18
16 190 58 297 72 74 10 248 6 335 -8z m-751 -583 c-104 -1 -274 -1 -376 0
l-186 3 60 75 c75 92 167 184 253 249 l65 50 188 -187 187 -187 -191 -3z
m1483 229 c76 -72 167 -176 192 -218 6 -10 -71 -13 -371 -13 l-378 0 189 189
190 190 44 -32 c25 -18 85 -70 134 -116z"/>
<path d="M2110 4360 l0 -150 -150 0 -150 0 0 -150 0 -150 150 0 150 0 0 150 0
150 150 0 150 0 0 -150 0 -150 150 0 150 0 0 150 0 150 150 0 150 0 0 -150 0
-150 150 0 150 0 0 150 0 150 -150 0 -150 0 0 150 0 150 -150 0 -150 0 0 -150
0 -150 -150 0 -150 0 0 150 0 150 -150 0 -150 0 0 -150z"/>
<path d="M2260 2260 l0 -150 300 0 300 0 0 150 0 150 -300 0 -300 0 0 -150z"/>
</g>
</svg>
    </div>
  );
};

export default CarDriverIcon;
