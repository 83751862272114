// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-editor-container {
  position: relative;
  height: 176px;
  border: 1px solid rgba(204, 204, 204, 0.2705882353);
  border-radius: 10px;
}

.ql-container {
  position: absolute;
  top: 0;
  height: 120px;
  width: 100%;
  border: none !important;
}

.ql-toolbar.ql-snow {
  position: absolute;
  top: 130px;
  border: none;
  width: 100%;
  border-top: 1px solid rgba(204, 204, 204, 0.2705882353) !important;
}

@media (max-width: 768px) {
  .ql-toolbar.ql-snow .ql-formats {
    margin-right: 3px;
  }
  .ql-toolbar.ql-snow {
    padding: 8px 4px;
  }
  .ql-snow .ql-picker.ql-font {
    width: 90px;
  }
  .ql-snow .ql-picker-label {
    font-size: 12px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/Editor/TextEditor.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,aAAA;EACA,mDAAA;EACA,mBAAA;AACJ;;AACA;EACI,kBAAA;EACA,MAAA;EACA,aAAA;EACA,WAAA;EAEA,uBAAA;AACJ;;AACA;EACI,kBAAA;EACA,UAAA;EACA,YAAA;EACA,WAAA;EACA,kEAAA;AAEJ;;AACA;EACI;IACI,iBAAA;EAEN;EAAE;IACI,gBAAA;EAEN;EAAE;IACI,WAAA;EAEN;EAAE;IACI,eAAA;EAEN;AACF","sourcesContent":[".text-editor-container {\n    position: relative;\n    height: 176px;\n    border: 1px solid rgba(204, 204, 204, 0.2705882353) ;\n    border-radius: 10px;\n}\n.ql-container {\n    position: absolute;\n    top: 0;\n    height: 120px;\n    width: 100%;\n    // border: 1px solid rgba(204, 204, 204, 0.2705882353) !important;\n    border: none !important;\n}\n.ql-toolbar.ql-snow {\n    position: absolute;\n    top: 130px;\n    border: none;\n    width: 100%;\n    border-top: 1px solid rgba(204, 204, 204, 0.2705882353) !important;\n}\n\n@media(max-width: 768px) {\n    .ql-toolbar.ql-snow .ql-formats {\n        margin-right: 3px;\n    }\n    .ql-toolbar.ql-snow {\n        padding: 8px 4px;\n    }\n    .ql-snow .ql-picker.ql-font {\n        width: 90px;\n    }\n    .ql-snow .ql-picker-label {\n        font-size: 12px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
