import React, {useState,useEffect} from 'react';
import Select from 'react-select';
import {CallApi} from "../common/API/APi";
import {success,error} from "../common/Toastify"; 

const AddNewStaff = (props) => {
const{setNotifyrole}=props;
  const statusoption = [
    { value: 1, label: 'Active' },
    { value: 2, label: 'Suspend' },
  ];
  const [rolesdata, setRolesdata] = useState([]);
  const [statusvalue, setStatusvalue] = useState({value: 1, label: 'Active'});
  const [addstaffdata, setAddstaffdata] = useState({'name':'','email':'','phone':'','password':'','role_id':'','address':'','user_type':1});
  const [errors, setError] = useState();

  const SubmitSTaff =async()=>{
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
                if(!addstaffdata?.name){
                  setError({ name: true });
                  return;
                } 
                if(!addstaffdata?.role_id){
                  setError({ role_id: true });
                  return;
                } 
               if(!addstaffdata?.email || !emailRegex.test(addstaffdata?.email)){
                 setError({ email: true });
                  return;
               } 
               if(!addstaffdata?.phone){
                setError({ phone: true });
                return;
              } 
              if(!addstaffdata?.password){
                setError({ password: true });
                return;
              } 
              if(!addstaffdata?.address){
                setError({ address: true });
                return;
              }   
              addstaffdata['status'] = statusvalue?.value;
          let res = await CallApi(addstaffdata, "admin");
          if(res?.status === 200){
            setNotifyrole(current => !current);
            success(res?.message);
            document.getElementById("addstaffform").reset();
            document.querySelector(".addcancel").click();
          }else{
            error(res?.message);
          }
    }
    const getrolesdata =async()=>{
      let res = await CallApi({}, "getroledata");
      if(res?.status === 200){
        const array=[];
           res?.data?.length?res?.data.map((value)=>{
            array.push({value: value?.id, label: value?.name})   
           }):""
          setRolesdata(array);
      }
    }
    useEffect(()=>{
      getrolesdata();
    },[])


  return(
    <div className="modal fade com-modal" id="addStaffModal" tabIndex="-1" aria-labelledby="addStaffModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5 golden-text" id="addStaffModalLabel">Add New Staff</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body pb-0">
          <form id="addstaffform">
            <div className='row'>
              <div className='col-md-6 mb-3'>
                <input type="text" className={errors?.name? " com-input-field border-danger" : "com-input-field"} value={addstaffdata?.name} onChange={(e)=>setAddstaffdata({...addstaffdata,name:e.target.value})} placeholder='Name'/>
              </div>
              <div className='col-md-6 mb-3'>
                <Select
                  onChange={(e)=>setAddstaffdata({...addstaffdata,role_id:e.value})}
                  options={rolesdata}
                  placeholder="Role Name"
                  classNamePrefix={errors?.role_id?"com-select border-danger":"com-select"}
                  isSearchable= {false}
                />
              </div>
              <div className='col-md-6 mb-3'>
                <input type="email" className={errors?.email? " com-input-field border-danger" : "com-input-field"} value={addstaffdata?.email} onChange={(e)=>setAddstaffdata({...addstaffdata,email:e.target.value})} placeholder='Email'/>
              </div>
              <div className='col-md-6 mb-3'>
                <input type="text" className={errors?.phone? " com-input-field border-danger" : "com-input-field"} value={addstaffdata?.phone} onChange={(e)=>setAddstaffdata({...addstaffdata,phone:e.target.value})} placeholder='Contact Number'/>
              </div>
               <div className='col-md-6 mb-3'>
                <input type="password" className={errors?.password? " com-input-field border-danger" : "com-input-field"} value={addstaffdata?.password} onChange={(e)=>setAddstaffdata({...addstaffdata,password:e.target.value})} placeholder='Password'/>
              </div>
              <div className='col-md-6 mb-3'>
              <Select
                  defaultValue={statusvalue}
                  onChange={setStatusvalue}
                  options={statusoption}
                  classNamePrefix="com-select"
                  isSearchable= {false}
                />
              </div>
              <div className='col-md-12 mb-3'>
                <textarea className={errors?.address? " com-input-field h-auto resize-none border-danger" : "com-input-field h-auto resize-none"} value={addstaffdata?.address} onChange={(e)=>setAddstaffdata({...addstaffdata,address:e.target.value})} rows="3" placeholder='Address'/>
              </div>
            </div>
            </form>
          </div>
          <div className="modal-footer text-center">
            <button type="button" className="golden-bg font-13px com-btn golden-border" onClick={SubmitSTaff} >Create Staff</button>
            <button type="button" className="golden-bg font-13px com-btn com-btn-outline golden-border bg-transparent addcancel" data-bs-dismiss="modal">Cancel</button> 
          </div>
        </div>
      </div>
    </div>
  )
} 

export default AddNewStaff;
