import { React, useEffect, useState } from "react";
import Select from "react-select";
import { CallApi } from "../common/API/APi";
import { success, error } from "../common/Toastify";

const AddExpenses = (props) => {
  let { setNotifyrole } = props;
  const [statusvalue, setStatusvalue] = useState({ value: 1, label: "Active" });
  const [extypevalue, setExtypevalue] = useState([]);
  const [ExchangeChanneleData, setExchangeChannelData] = useState();
  const [expensesdata, setExpensesdata] = useState({
    exp_type_select: "",
    expchannel_select:'',
    exp: "",
    amount: "",
    comment: "",
  });
  const [errors, setError] = useState();
  const statusoption = [
    { value: 1, label: "Active" },
    { value: 2, label: "Suspend" },
  ];
  const SubmitExpensesType = async () => {
    if (!expensesdata?.exp_type_select) {
      setError({ exptype: true });
      return;
    }
    if (!expensesdata?.expchannel_select) {
      setError({ expchannel: true });
      return;
    }
    if (!expensesdata?.exp) {
      setError({ expname: true });
      return;
    }
    if (!expensesdata?.amount) {
      setError({ amount: true });
      return;
    }
  
    expensesdata["status"] = statusvalue?.value;
    expensesdata["exp_type"] = expensesdata?.exp_type_select?.value;
    expensesdata["expchannel"] = expensesdata?.expchannel_select?.value;
   delete expensesdata?.expchannel_select;
   delete expensesdata?.exp_type_select;


    let res = await CallApi(expensesdata, "add_expenses");
    if (res?.status === 200) {
      setNotifyrole((current) => !current);
      success(res?.message);
      setExpensesdata({
        exp_type_select: null,
        expchannel_select:null,
        exp: "",
        amount: "",
        comment: "",
      })
      document.getElementById("addExpensesform").reset();
      document.querySelector(".addallexcancel").click();
    } else {
      error(res?.message);
    }
  };
  const getexpensestype = async () => {
    let res = await CallApi({ search: "", status: 1 }, "get_expense_type");
    let array = [];
    if (res?.status === 200) {
      res?.data.map((value) => {
        array.push({ value: value?._id, label: value?.expense_type });
      });
      setExtypevalue(array);
    }
  };
  
const getexpensechannel=async()=>{
  let res = await CallApi({"id":expensesdata?.exp_type_select?.value}, "getactiveexpensechannel");
  let array=[];
  if(res?.status === 200){
           res?.data.map((value)=>{
            array.push({'value':value?._id,'label':value?.expchannel});
           })
           setExchangeChannelData(array);
  }
}

  useEffect(() => {
    getexpensestype();
  }, []);
  useEffect(() => {
    getexpensechannel();
  }, [expensesdata?.exp_type_select?.value]);
  return (
    <div
      className="modal fade com-modal"
      id="addallExpensesModal"
      tabIndex="-1"
      aria-labelledby="addallExpensesModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1
              className="modal-title fs-5 golden-text"
              id="addallExpensesModalLabel"
            >
              Add Expenses
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form id="addExpensesform">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <Select
                  value={expensesdata?.exp_type_select}
                    onChange={(e) =>
                      setExpensesdata({ ...expensesdata, exp_type_select: e })
                    }
                    options={extypevalue}
                    classNamePrefix={
                      errors?.exptype
                        ? " com-select border-danger"
                        : "com-select"
                    }
                    placeholder="Expenses Type"
                    isSearchable={false}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <Select
                  value={expensesdata?.expchannel_select}
                    onChange={(e) =>
                      setExpensesdata({ ...expensesdata, expchannel_select: e })
                    }
                    options={ExchangeChanneleData}
                    classNamePrefix={
                      errors?.expchannel
                        ? " com-select border-danger"
                        : "com-select"
                    }
                    placeholder="Expenses Channel"
                    isSearchable={false}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <input
                    type="text"
                    className={
                      errors?.expname
                        ? " com-input-field border-danger"
                        : "com-input-field"
                    }
                    value={expensesdata?.exp}
                    onChange={(e) =>
                      setExpensesdata({ ...expensesdata, exp: e.target.value })
                    }
                    placeholder="Expenses"
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <input
                    type="text"
                    className={
                      errors?.amount
                        ? " com-input-field border-danger"
                        : "com-input-field"
                    }
                    value={expensesdata?.amount}
                    onChange={(e) =>
                      setExpensesdata({
                        ...expensesdata,
                        amount: e.target.value,
                      })
                    }
                    placeholder="Amount"
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <Select
                    defaultValue={statusvalue}
                    onChange={setStatusvalue}
                    options={statusoption}
                    classNamePrefix="com-select"
                    isSearchable={false}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <textarea
                    type="text"
                    rows="3"
                    className="com-input-field h-auto resize-none"
                    value={expensesdata?.comment}
                    onChange={(e) =>
                      setExpensesdata({
                        ...expensesdata,
                        comment: e.target.value,
                      })
                    }
                    placeholder="Comment"
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer text-center mt-3">
            <button
              type="button"
              className="golden-bg font-13px com-btn golden-border"
              onClick={SubmitExpensesType}
            >
              Create Expenses
            </button>
            <button
              type="button"
              className="golden-bg font-13px com-btn com-btn-outline golden-border bg-transparent addallexcancel"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddExpenses;
