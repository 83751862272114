import React, {useState,useEffect} from 'react';
import {CallApi} from "../common/API/APi";
import {success,error} from "../common/Toastify"; 
import TextEditor from "../common/Editor/TextEditor"


const BulksendEmail = (props) => {
    const {bulkmails,setNotifyrole} = props;
   const [mailitems,setEmailitems]=useState({'to':[],'subject':'','message':''});
   const [errors,setErrors]=useState();
   const [editordata,setEditordata]=useState('');

    
   const sendEmail=async()=>{
             setErrors('');
             document.querySelector(".text-editor-container").style.border='1px solid #cccccc45 ';
           if(!mailitems?.subject){
            setErrors({'subject':true});
            return;
         }
         if(!mailitems?.message){
            document.querySelector(".text-editor-container").style.border='1px solid #e63945 ';
            return;
          }
          
          
          let res = await CallApi(mailitems, 'sendmail');
          if(res?.status === 200){
             setNotifyrole(current => !current);
            success(res?.message);
            setEditordata('');
            document.querySelector(".mailbulkcancel").click();
            setEmailitems({'to':[],'subject':'','message':''});
          }else{
            error(res?.message);
          }
   } 
    useEffect(()=>{
      if(bulkmails?.length){
        setErrors('');
        setEmailitems({'to':[],'subject':'','message':''});
        let array=[];
        for(let i=0;i<bulkmails?.length;i++){
         array.push(bulkmails[i]['mail']);
        }
        setEmailitems({...mailitems,'to':array});      
      }else{
         setEmailitems({'to':[],'subject':'','message':''});
      }
    },[bulkmails])
    useEffect(()=>{
      setEmailitems({...mailitems,'message':editordata})
    },[editordata])
   
  return(
      <div className="modal fade com-modal" id="BulksendEmailModal" tabIndex="-1" aria-labelledby="BulksendEmailModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5 golden-text" id="BulksendEmailModalLabel">Send Email</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body pb-0">
         
            <div className='row'>
              <div className='col-md-12 mb-4 '>
               
                     <div className='multi-email-label-box'>
                     {mailitems?.to?.length?mailitems?.to.map((value,imail)=>{
                        return(
                         <span className='single-email-label' key={imail} >{value}</span>
                        );
                     }):'No email id is selected !'}
                     </div>
              </div>
              <div className='col-md-12 mb-4'>
                <input type="text" className={errors?.subject?" com-input-field border-danger":" com-input-field"} onChange={(e)=>setEmailitems({...mailitems,'subject':e.target.value})} value={mailitems?.subject}    placeholder='Subject'/>
              </div>
              <div className='col-md-12 mb-4'>
                <TextEditor placeholder={'Compose email'} setEditordata={setEditordata} />
              </div>
            </div>
   
          </div>
          <div className="modal-footer text-center">
          {bulkmails?.length? <button type="button" className="golden-bg font-13px com-btn golden-border" onClick={sendEmail} >Send</button>:''}
            <button type="button" className="golden-bg font-13px com-btn com-btn-outline golden-border bg-transparent mailbulkcancel" data-bs-dismiss="modal">Cancel</button>
          </div>
        </div>
      </div>
      </div>
  )
} 

export default BulksendEmail;
