import {React,useState,useEffect} from'react';
import {CallApi} from "../common/API/APi";
import {success,error} from "../common/Toastify"; 

const GenrateInvoice=(props)=>{
   const {genratedinvoice} =props;
   const [transcationid,setTranscationid]=useState();
  
   const genrateinvoice=async()=>{
      if(!transcationid){
         return;
      }
      success("Invoice will send in your mail!");
      let res = await CallApi({'id':genratedinvoice?.id,'transcation_id':genratedinvoice?.transcation_id?'':transcationid}, 'genrate_invoice_transcationid');
      if(res?.status == 200){
        document.querySelector(".invoicecancel").click();
      }else{
        error("Something Went Wrong!");
      }
    
   } 
    
   useEffect(()=>{
       document.getElementById("transcation_by_id").readOnly=false;
        if(genratedinvoice?.id){
         if(genratedinvoice?.transcation_id){
            document.getElementById("transcation_by_id").readOnly=true;
         }
         
           setTranscationid(genratedinvoice?.transcation_id);
        }
   },[genratedinvoice?.id])

   return(
      <div className="modal fade com-modal" id="genrateinvoicemodal" tabIndex="-1" aria-labelledby="genrateinvoiceLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5 golden-text" id="genrateinvoiceLabel">Generate Invoice</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body pb-0">
           
              <div className='row'>
                <div className='col-md-12 mb-4'>
                  <input  type="text" className=" com-input-field " id="transcation_by_id" value={transcationid} onChange={(e)=>setTranscationid(e.target.value)} placeholder='Transcation Id'/>
                </div>
              </div>
     
            </div>
            <div className="modal-footer text-center">
              <button type="button" className="golden-bg font-13px com-btn golden-border"onClick={genrateinvoice} >Generate</button>
              <button type="button" className="golden-bg font-13px com-btn com-btn-outline golden-border bg-transparent invoicecancel" data-bs-dismiss="modal">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    )
}
export default GenrateInvoice;