import React,{useEffect,useState} from 'react';
import {CallApi} from "../common/API/APi";
import {success,error} from "../common/Toastify"; 


const EditsuregechargePrice = (props) => {
  const {surgebyid,setNotifyrole}=props;
  const [surgeprice,setSurgeprice]=useState();
  const getsurgebyid=async()=>{
          let res= await CallApi({'id':surgebyid},'get_pricelistbyid');
          if(res?.status ==200){
            setSurgeprice(res?.data?.surgecharge);
          }
  }

const updatesurgedata=async()=>{
  let inputs= document.querySelectorAll(".surge-car-amount-box-show input");
  for(let i=0;i<inputs.length;i++){
    if(inputs[i].value == ''){
      error("Amount should not be empty!");
      return;
    }
  }

  let res= await CallApi({'surgecharge':surgeprice,'id':surgebyid},'update_pricelist');
  if(res?.status ==200){
    success(res?.message);
    setNotifyrole(current => !current);
    document.querySelector(".updatesurgecancel").click();
  }else{
    error(res?.message);
  }
}
  useEffect(()=>{
         if(surgebyid){
          getsurgebyid();
         }
  },[surgebyid])
return(
  <div className="modal fade com-modal" id="editSurgepriceModal" tabIndex="-1" aria-labelledby="editSurgepriceModal" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5 golden-text" id="editSurgepriceModal">Edit Surge Charge</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body pb-0">
        <form id="">
          
                  <div className="row" >
                              
                        <div className="surge-car-amount-box-show d-flex   align-items-center  gap-1 ms-auto">
                        <p className="order-refund-amount-box-para mb-0 ms-2">
                            Event Surge Charge(From) Amount($)
                          </p>
                              <input type="number"  value={surgeprice?.eventfromsurgecharge} onChange={(e)=>setSurgeprice({...surgeprice,eventfromsurgecharge:e.target.value})}  className= "com-input-field" style={{height: "32px",width: "32%"}}/>
                        </div>
                      
                  </div>
                  <div className="row" >
                          
                        <div className="surge-car-amount-box-show d-flex   align-items-center  gap-1 ms-auto">
                        <p className="order-refund-amount-box-para mb-0 ms-2">
                          Event Surge Charge(To) Amount($)
                        </p>  
                              <input type="number"  value={surgeprice?.eventtosurgecharge} onChange={(e)=>setSurgeprice({...surgeprice,eventtosurgecharge:e.target.value})}  className= "com-input-field" style={{height: "32px",width: "32%"}}/>
                        </div>
                      
                  </div>
                  <div className="row" >
                            
                        <div className="surge-car-amount-box-show d-flex   align-items-center  gap-1 ms-auto">
                        <p className="order-refund-amount-box-para mb-0 ms-2">
                          Return Event Surge Charge Amount($)
                        </p> 
                              <input type="number" value={surgeprice?.returneventsurgecharge} onChange={(e)=>setSurgeprice({...surgeprice,returneventsurgecharge:e.target.value})}    className= "com-input-field" style={{height: "32px",width: "32%"}}/>
                        </div>
                      
                  </div>
                  <div className="row" >
                            
                        <div className="surge-car-amount-box-show d-flex   align-items-center  gap-1 ms-auto">
                        <p className="order-refund-amount-box-para mb-0 ms-2">
                           Early And Late Surge Charge Amount($)
                        </p> 
                              <input type="number"   value={surgeprice?.timesurgecharge} onChange={(e)=>setSurgeprice({...surgeprice,timesurgecharge:e.target.value})}   className= "com-input-field" style={{height: "32px",width: "32%"}}/>
                        </div>
                      
                  </div>
        </form>

      </div>
      <div className="modal-footer text-center">
        <button type="button" className="golden-bg font-13px com-btn golden-border" onClick={updatesurgedata}>Update</button>
        <button type="button" className="golden-bg font-13px com-btn com-btn-outline golden-border bg-transparent updatesurgecancel" data-bs-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>
);
}
export default EditsuregechargePrice;