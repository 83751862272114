import React from 'react';

import LoaderLogo from "../../assets/images/main-logo.png"

const Loader = () => {
   return(
      <div className='loader d-flex align-items-center justify-content-center position-fixed top-0 start-0'>
         <img src={LoaderLogo} className='loader-img' alt=""/>
      </div>
   )
}

export default Loader;