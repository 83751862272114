import React, {useState,useEffect} from 'react';
import {error} from "../common/Toastify";
import {CallApi} from "../common/API/APi";
import { IoIosSend } from "react-icons/io";
import Moment from 'react-moment';
import moment from "moment-timezone";


const ReminderModal = (props) => {
     const {reminderItem}=props;
     const [updatedata,setUpdatedata]=useState();
     const [remaindermess,setRemaindermess]=useState('');
     const updatenotes=async()=>{
    if(!remaindermess){
         return;
    }
      const postdata={"id":reminderItem?.id,"message":remaindermess,date_time:new Date()};
        let res = await CallApi(postdata, "reminder_notes");
        if(res?.status === 200){
          setRemaindermess('');
          getnotes();
        }else{
          error(res?.message);
        }
     }

     const getnotes=async()=>{
      setUpdatedata([]);
      setRemaindermess('');
      const postdata={"id":reminderItem?.id};
        let res = await CallApi(postdata, "getnotes");
        if(res?.status === 200){
          setUpdatedata(res?.data)
        }else{
          error(res?.message);
        }
     }

     useEffect(()=>{
      if(reminderItem?.id){
        getnotes();
      }
    },[reminderItem?.id])
  return(
    <div className="modal fade com-modal" id="reminderNoteModal" tabIndex="-1" aria-labelledby="reminderNoteModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className='reminder-head'>
              <p className="modal-title  golden-text" id="reminderNoteModalLabel">Reminder Notes</p>
              <span className=' golden-text'> - </span>
              <p className="modal-title  golden-text" >{reminderItem?.name}</p>

            </div>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body pb-0">
          <div className='reminder-history-box mb-4' >
            {updatedata?.length?updatedata.map((value,keys)=>{
          const date_time = moment.utc(value?.date_time).tz("America/New_York");

              return(
                
                    <div className='reminder-history-box-single' key={keys}>
                      <p className="reminder-history-box-single-para">{value?.message}</p>
                      <div className='reminder-history-box-single-info' >
                        <span className='reminder-history-box-single-info-show'>{value?.admin}, {<Moment format={'MMMM DD,YYYY hh:mm A'} >{date_time}</Moment>}</span>
                      </div>
                    </div>
               
              )
            }):""}
          
          </div>
            <div className='mb-5'>
              <div className='mb-4 reminder-text-editor'>
                <textarea className="resize-none reminder-text-editor-box" value={remaindermess} onChange={(e)=>setRemaindermess(e.target.value)}   rows="1" placeholder='Message'/>
                <button type="button" className="golden-bg font-13px golden-border reminder-text-editor-submit-btn" onClick={updatenotes} ><IoIosSend /></button>
              </div>
            </div>
   
          </div>
        </div>
      </div>
    </div>
  )
} 

export default ReminderModal;
