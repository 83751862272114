import React,{ useState,useEffect} from "react";
import DataTable from 'react-data-table-component';
import { BiSearch } from "react-icons/bi"
import {CallApi} from "./common/API/APi";
import DataTableLoaders from "./common/DatatableLoader";
import Moment from "react-moment";
import DeleteModal from "./modal/DeleteModal";
import StatusModal from "./modal/StatusModal";
import { IoMdAddCircleOutline } from "react-icons/io";
import AddExpenses from "./modal/Addexpense";
import EditExpenses from "./modal/EditExpenses";
import { DateRange } from "./modal/ComDatePicker";
import Select from 'react-select';
import moment from "moment-timezone";




const AllExpenses = () => {
  const profiledata = JSON.parse(localStorage.getItem("profiledata"));

  const [search,setSearch]=useState('');
  const [datatableloder,setDatatableloder]=useState();
  const [expensesdata,setExpensesdata]=useState();
  const [datesearch,setDateSearch]=useState([]);
  const [deleteItem, setDeleteItem] = useState();
  const [statusItem, setStatusItem] = useState();
  const [notifyrole, setNotifyrole] = useState();
  const [editid, setEditid] = useState();
  const [ExchangeTypeData, setExchangeTypeData] = useState();
 
  const [ExchangeTypevalue, setExchangeTypevalue] = useState();
  const [totalamount,setTotalamount]=useState(0);
  const [statusvalue, setStatusvalue] = useState({ value: 1, label: "Approved" });
  const paginationRowOptions = [50, 100, 500, 1000];
  const [limit, setLimit] = useState(paginationRowOptions[0]);
  const [TotalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const statusoption = [
    { value: 1, label: "Approved" },
    { value: 2, label: "Reject" },
  ];
const columns = [
  
  {name: 'S.No.',selector: row => row.id,sortable: true,width:"80px"},
  {name: ' Expenses Type',selector: row => row.expensestype,sortable: true,minWidth:"200px"},
  {name: ' Expenses Channel',selector: row => row.expenseschannel,sortable: true,minWidth:"200px"},
  {name: ' Expenses',selector: row => row.expenses,sortable: true,minWidth:"200px"},
  {name: ' Amount',selector: row => row.amount,sortable: true,minWidth:"200px"},
  {name: ' Comment',selector: row => row.comment,sortable: true,minWidth:"200px"},
  {name: 'Added By',selector: row => row.addedby,sortable: true,minWidth:"130px"},
  {name: 'Created At',selector: row => row.createdat,sortable: true,minWidth:"160px"},
  {name: 'Updated At',selector: row => row.updatedat,sortable: true,minWidth:"160px"},
  {
    name: "Action",
    sortable: false,
    minWidth: "300px",
    selector: (row) => (
      <>
     {" "}
        {profiledata?.permission.filter((e) => e.name === "Expenses")[0]
          ?.permission?.edit === 1 ? (
        <button
          className="cell-tabs bg-secondary text-white"
          onClick={() => setEditid(row?.action?.id)}
          data-bs-toggle="modal"
          data-bs-target="#updateExpensesModal"
        >
          Edit
        </button>
         ) : (
          ""
        )}
         {profiledata?.permission.filter((e) => e.name === "Expenses")[0]
          ?.permission?.delete === 1 ? 
        
        <button
          className="cell-tabs mx-3 bg-danger text-white"
          onClick={() => setDeleteItem(row?.action?.id)}
          data-bs-toggle="modal"
          data-bs-target="#deleteitemModal"
        >
          Delete
        </button>
         : (
          ""
        )}
          {profiledata?.permission.filter((e) => e.name === "Expenses")[0]
          ?.permission?.edit === 1 ? (
          row?.action?.status === 1 ? (
            <button
              data-bs-toggle="modal"
              data-bs-target="#statusModal"
              onClick={() =>
                setStatusItem({ id: row?.action?.id, status: 2 })
              }
              className="cell-tabs text-white bg-success"
            >
              Approved
            </button>
          ) : (
            <button
              data-bs-toggle="modal"
              data-bs-target="#statusModal"
              onClick={() =>
                setStatusItem({ id: row?.action?.id, status: 1 })
              }
              className="cell-tabs bg-warning"
            >
              Reject
            </button>
          )
        ) : (
          ""
        )}
      </>
    ),
  },
];

const getexpenses=async()=>{
  setDatatableloder(true);
  const skip = (currentPage - 1) * limit;
  let res = await CallApi({"search":search,"from_date": datesearch[0]?datesearch[0]:'',
  "to_date": datesearch[1]? datesearch[1]:'',"type":ExchangeTypevalue?.value,status: statusvalue?.value,limit:limit,skip:skip}, "get_expenses");
  if(res?.status === 200){
    let array=[];
    if( res?.data?.length){
      res?.data.map((value,key)=>{
        const CreatedAt = moment.utc(value?.createdAt).tz("America/New_York");
        const UpdateAt = moment.utc(value?.updatedAt).tz("America/New_York");
        array.push({
            
                    "id":key+1,
                    "expensestype":value?.exp_type,
                    "expenseschannel":value?.exp_channel_name,
                    'expenses':value?.exp,
                    'amount':value?.amount?'$'+value?.amount:'$0' ,
                    'comment':value?.comment,
                    "addedby":value?.addedBy,
                    "createdat": <Moment format="MMM DD, YYYY : hh:mm A" >{CreatedAt}</Moment>,
                    "updatedat":<Moment format="MMM DD, YYYY : hh:mm A" >{UpdateAt}</Moment>,
                    "action":{ id: value?._id, status: value?.status }
        })
      })
     
      
    }  
    setTotalamount( res?.totalAmount);

    setExpensesdata(array);
    setTotalRows(res?.TotalRows);
    setDatatableloder(false);
  }
 }
 const getexpensestype=async()=>{
  let res = await CallApi({"search":'',"status":1}, "get_expense_type");
  let array=[{'value':'','label':'All'}];
  if(res?.status === 200){
           res?.data.map((value)=>{
            array.push({'value':value?._id,'label':value?.expense_type});
           })
           setExchangeTypeData(array);
  }
}

 useEffect(()=>{
  getexpenses();

 },[notifyrole,datesearch,ExchangeTypevalue?.value,statusvalue,limit,currentPage])
 useEffect(()=>{
  getexpensestype();
 },[])

  return (
          <>
            <div className="main-page-body position-relative">
              <h1 className="com-heading">Expenses</h1>

              <div className="com-card-box">
                <div className="d-flex justify-content-between align-items-center gap-2">
                  <div className="col-md-4">
                    <h1 className="com-heading mb-0">Expenses List</h1>
                  </div>
                  <div className="col-md-3 d-flex align-items-center justify-content-end ms-auto gap-2 ">
                  <button
                    type="button"
                    className="btn font-13px golden-bg d-flex align-items-center justify-content-center gap-2 text-nowrap com-btn golden-btn-respn-show"
                    data-bs-toggle="modal"
                    data-bs-target="#addallExpensesModal"
                  >
                    <IoMdAddCircleOutline />
                    Add Expenses
                  </button>
                  </div>
                </div>
              </div>

              <div className="com-card-box">
                <div className="row">
                  <div className="col-lg-3 col-md-5 col-sm-6 mb-3 mb-sm-0">
                    <div className="input-group">
                      <div className="input-group-text bg-transparent border-end-0"><BiSearch/></div>
                      <input type="text" className="form-control border-start-0 ps-0 font-13px" value={search} onKeyPress={(e) => e.key === "Enter" && getexpenses() } onChange={(e)=>{setSearch(e.target.value); setCurrentPage(1);}} placeholder="Search Expenses...."/>
                    </div>
                  </div>
                  <div className=" col-md-6 ms-auto col-sm-6 col-12 order-datatable-filter-container">
                  <Select
                    defaultValue={statusvalue}
                    onChange={(e)=>{setStatusvalue(e); setCurrentPage(1)}}
                    options={statusoption}
                    classNamePrefix="com-select"
                    isSearchable={false}
                  />
                  <Select
                  onChange={(e)=>{setExchangeTypevalue(e); setCurrentPage(1)}}
                  value={ExchangeTypevalue}
                  options={ExchangeTypeData}
                  classNamePrefix="com-select"
                  isSearchable= {true}
                  placeholder="Select Expenses Type"
                />

                  <DateRange setDateSearch={setDateSearch}  setCurrentPage={ setCurrentPage} />
                    <div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="com-datatable com-card-box py-2">
                {datatableloder?
                <DataTableLoaders/>
                :
                <DataTable
                columns={columns}
                data={expensesdata}
                pagination
                paginationServer
                fixedHeaderScrollHeight="300px"
                className="myTable"
                responsive
                striped
                paginationRowsPerPageOptions= {paginationRowOptions}
                paginationPerPage={limit}
                paginationTotalRows={TotalRows}
                onChangeRowsPerPage={(newLimit) => {
                  setLimit(newLimit);
                  setCurrentPage(1);
                }}
                onChangePage={(page) => {setCurrentPage(page)}}
                paginationDefaultPage={currentPage}
              />
                }
                 <div>Total Amount: ${totalamount?totalamount:0}</div>
              </div>
            
            </div>
             <DeleteModal 
             apiname={"delete_expenses"}
             deleteItem={deleteItem}
             setNotifyrole={setNotifyrole}/>
            <StatusModal 
            apiname={"update_expenses"}
            statusItem={statusItem}
            setNotifyrole={setNotifyrole}/>
            <AddExpenses  setNotifyrole={setNotifyrole}/>
            <EditExpenses
                 editid={editid}
                 setNotifyrole={setNotifyrole}
                 setEditid={setEditid}
            />
          </>

    
  )
}

export default AllExpenses;