import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { IoMdAddCircleOutline } from "react-icons/io";
import { BiSearch } from "react-icons/bi";
import AddNewStaff from "./modal/AddStaffModal";
import DeleteModal from "./modal/DeleteModal";
import StatusModal from "./modal/StatusModal";
import EditStaff from "./modal/EditStaffDetails";
import { CallApi } from "./common/API/APi";
import { error } from "./common/Toastify";
import Select from "react-select";
import DataTableLoaders from "./common/DatatableLoader";
import Moment from "react-moment";
import { useLocation } from "react-router-dom";
import moment from "moment-timezone";


const Staff = () => {
  const {state} = useLocation();
  const {role_ids}=state || { role_ids: null };
  const profiledata = JSON.parse(localStorage.getItem("profiledata"));
  const columns = [
    {
      name: "S.No.",
      selector: (row) => row.sno,
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      minWidth: "140px",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      minWidth: "240px",
    },
    {
      name: "Contact No.",
      selector: (row) => <>{<a style={{"text-decoration": "none","color":" #000","cursor": "pointer"}} href={`tel:${row.phone}`}>{row.phone}</a>}</>,
      sortable: true,
      minWidth: "180px",
    },
    {
      name: "Role Name",
      selector: (row) => row.rolename,
      sortable: true,
      minWidth: "180px",
    },
    {
      name: "Created On",
      selector: (row) => row.createdOn,
      sortable: true,
      minWidth: "190px",
    },
    {
      name: "Last Updated",
      selector: (row) => row.lastUpdated,
      sortable: true,
      minWidth: "190px",
    },
    {
      name: "Updated By",
      selector: (row) => row.updatedBy,
      sortable: true,
      minWidth: "140px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Action",
      sortable: false,
      minWidth: "220px",
      selector: (row) => (
        <>
          {" "}
          {profiledata?.permission.filter((e) => e.name === "Staff")[0]
            ?.permission?.edit === 1 ? (
            <button
              className="cell-tabs bg-secondary text-white"
              onClick={() => setEditid(row?.action?.id)}
              data-bs-toggle="modal"
              data-bs-target="#editStaffModal"
            >
              Edit
            </button>
          ) : (
            ""
          )}
          {profiledata?.permission.filter((e) => e.name === "Staff")[0]
            ?.permission?.delete === 1 ? (
            <button
              className="cell-tabs mx-3 bg-danger text-white"
              onClick={() => setDeleteItem(row?.action?.id)}
              data-bs-toggle="modal"
              data-bs-target="#deleteitemModal"
            >
              Delete
            </button>
          ) : (
            ""
          )}
          {profiledata?.permission.filter((e) => e.name === "Staff")[0]
            ?.permission?.edit === 1 ? (
            row?.action?.status === 1 ? (
              <button
                data-bs-toggle="modal"
                data-bs-target="#statusModal"
                onClick={() =>
                  setStatusItem({ id: row?.action?.id, status: 2 })
                }
                className="cell-tabs text-white bg-success"
              >
                Active
              </button>
            ) : (
              <button
                data-bs-toggle="modal"
                data-bs-target="#statusModal"
                onClick={() =>
                  setStatusItem({ id: row?.action?.id, status: 1 })
                }
                className="cell-tabs bg-warning"
              >
                Suspend
              </button>
            )
          ) : (
            ""
          )}{" "}
        </>
      ),
    },
  ];

  const paginationRowOptions = [50, 100, 500, 1000];

  const statusoption = [
    { value: 1, label: "Active" },
    { value: 2, label: "Suspend" },
  ];
  const [staffdata, setStaffdata] = useState();
  const [search, setSearch] = useState("");
  const [notifyrole, setNotifyrole] = useState();
  const [statusvalue, setStatusvalue] = useState({ value: 1, label: "Active" });
  const [datatableloder, setDatatableloder] = useState();
  const [editid, setEditid] = useState();
  const [deleteItem, setDeleteItem] = useState();
  const [statusItem, setStatusItem] = useState();
  const [rolesdata, setRolesData] = useState([]);
  const [rolesid, setRolesid] = useState(role_ids?role_ids:'');
  const [limit, setLimit] = useState(paginationRowOptions[0]);
  const [TotalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const getStaff = async () => {
    setDatatableloder(true);
    const skip = (currentPage - 1) * limit;
    const postdata = { search: search, status: statusvalue?.value,user_type:1,role_id:rolesid?.value,limit:limit,skip:skip };
    let res = await CallApi(postdata, "getadmins");
    if (res?.status === 200) {
      let array = [];
      res?.data.map((value, key) => {
        const CreatedAt = moment.utc(value?.createdAt).tz("America/New_York");
        const UpdateAt = moment.utc(value?.updatedAt).tz("America/New_York");
        array.push({
          sno: key + 1,
          name: value?.name,
          email: value?.email,
          phone: value?.phone,
          rolename:value?.Rolename,
          createdOn: (
            <Moment format="MMM DD, YYYY : hh:mm A" >
              {CreatedAt}
            </Moment>
          ),
          lastUpdated: (
            <Moment format="MMM DD, YYYY : hh:mm A" >
              {UpdateAt}
            </Moment>
          ),
          updatedBy: value?.updatedBy,
          status: value?.status == 1 ? "Active" : "Suspend",
          action: { id: value?.id, status: value?.status },
        });
      });
      setStaffdata(array);
      setTotalRows(res?.TotalRows);
      setDatatableloder(false);
    } else {
      error("No data Found!");
    }
  };
   const getRoles=async()=>{
     let res= await CallApi({},'getactive_roles');
      if(res?.status ==200){
        if(res?.data?.length){
          let array=[{value:'',label:'Select All'}];
          res?.data.map((value)=>{
            array.push({'value':value?.id,'label':value?.rolename});
          })
          setRolesData(array);
        }
        
      }
   }

  useEffect(() => {
    getStaff();
  }, [statusvalue, notifyrole,rolesid,limit,currentPage]);
  useEffect(()=>{
    getRoles();
  },[])

  return (
    <>
      <div className="main-page-body position-relative">
        <div className="com-card-box">
          <div className="row align-items-center">
            <div className="col-4">
              <h1 className="com-heading mb-0">Staff</h1>
            </div>
            {profiledata?.permission.filter((e) => e.name === "Staff")[0]
              ?.permission?.add === 1 ? (
              <div className="col-3 d-flex align-items-center justify-content-end ms-auto gap-2">
                <button
                  type="button"
                  className="btn font-13px golden-bg d-flex align-items-center justify-content-center gap-2 text-nowrap com-btn golden-btn-respn-show"
                  data-bs-toggle="modal"
                  data-bs-target="#addStaffModal"
                >
                  <IoMdAddCircleOutline />
                  Add New
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-12">
            <div className="com-card-box">
              <div className="row">
                <div className="col-lg-3 col-md-5 col-sm-6 mb-3 mb-sm-0">
                  <div className="input-group">
                    <div className="input-group-text bg-transparent border-end-0">
                      <BiSearch />
                    </div>
                    <input
                      type="text"
                      className="form-control border-start-0 ps-0 font-13px"
                      value={search}
                      onKeyPress={(e) => e.key === "Enter" && getStaff()}
                      onChange={(e) => {setSearch(e.target.value);setCurrentPage(1)}}
                      placeholder="Search name..."
                    />
                  </div>
                </div>
                <div className="col-lg-9 col-md-9 ms-auto col-sm-12 col-12 order-datatable-filter-container">
                <Select
                    defaultValue={rolesid}
                    onChange={(e)=>{setRolesid(e);setCurrentPage(1)}}
                    options={rolesdata}
                    classNamePrefix="com-select"
                    isSearchable={true}
                    placeholder='Select Roles'
                  />
                  <Select
                    defaultValue={statusvalue}
                    onChange={(e)=>{setStatusvalue(e);setCurrentPage(1)}}
                    options={statusoption}
                    classNamePrefix="com-select"
                    isSearchable={false}
                  />
                </div>
               
              </div>
            </div>

            <div className="com-datatable com-card-box py-1">
              {datatableloder ? (
                <DataTableLoaders />
              ) : (
                <DataTable
                columns={columns}
                data={staffdata}
                pagination
                paginationServer
                className="myTable"
                paginationRowsPerPageOptions={paginationRowOptions}
                paginationPerPage={limit}
                paginationTotalRows={TotalRows}
                onChangeRowsPerPage={(newLimit) => {
                  setLimit(newLimit);
                  setCurrentPage(1);
                }}
                onChangePage={(page) => {setCurrentPage(page)}}
                paginationDefaultPage={currentPage}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <DeleteModal
        apiname={"deleteadmin"}
        deleteItem={deleteItem}
        setNotifyrole={setNotifyrole}
      />
      <StatusModal
        apiname={"updateadminstatus"}
        statusItem={statusItem}
        setNotifyrole={setNotifyrole}
      />
      <AddNewStaff setNotifyrole={setNotifyrole} />
      <EditStaff
        editid={editid}
        setNotifyrole={setNotifyrole}
        setEditid={setEditid}
      />
    </>
  );
};

export default Staff;
