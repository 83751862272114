import React,{useEffect,useState} from 'react';
import {CallApi} from "../common/API/APi";
import {success,error} from "../common/Toastify"; 


const EditMeetPrice = (props) => {
  const {meetbyid,setNotifyrole}=props;
  const [meetprice,setMeetprice]=useState();
  const getmeetbyid=async()=>{
          let res= await CallApi({'id':meetbyid},'get_pricelistbyid');
          if(res?.status ==200){
            setMeetprice(res?.data?.meetgreet);
          }
  }

const updatemeetprice=async()=>{
  let inputs= document.querySelectorAll(".meet-car-amount-box-show input");
  for(let i=0;i<inputs.length;i++){
    if(inputs[i].value == ''){
      error("Amount should not be empty!");
      return;
    }
  }
  let res= await CallApi({'meetgreet':meetprice,'id':meetbyid},'update_pricelist');
  if(res?.status ==200){
    success(res?.message);
    setNotifyrole(current => !current);

    document.querySelector(".updatemeetcancel").click();
  }else{
    error(res?.message);
  }
}
  useEffect(()=>{
         if(meetbyid){
          getmeetbyid();
         }
  },[meetbyid])
return(
  <div className="modal fade com-modal" id="editMeetpriceModal" tabIndex="-1" aria-labelledby="editMeetpriceModal" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5 golden-text" id="editMeetpriceModal">Edit Meet And Greet</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body pb-0">
        <form id="">
          
                  <div className="row" >
                            
                        <div className="meet-car-amount-box-show d-flex  align-items-center  gap-1 ms-auto">
                        <p className="order-refund-amount-box-para mb-0 ms-2">
                             Meet And Greet Amount($)
                           </p> 
                              <input type="number" value={meetprice?.amount} onChange={(e)=>setMeetprice({...meetprice,amount:e.target.value})}    className= "com-input-field" style={{height: "32px",width: "42%"}}/>
                        </div>
                      
                  </div>
        </form>

      </div>
      <div className="modal-footer text-center">
        <button type="button" className="golden-bg font-13px com-btn golden-border" onClick={updatemeetprice} >Update</button>
        <button type="button" className="golden-bg font-13px com-btn com-btn-outline golden-border bg-transparent updatemeetcancel" data-bs-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>
);
}
export default EditMeetPrice;