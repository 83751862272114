
import React, {useState,useEffect} from 'react';
import BalanceOverview from "./common/analyticsBox/BalanceOverview.js";
import Totalorder from "./common/analyticsBox/Totalorder.js";
import { LuDatabaseBackup } from "react-icons/lu";
import {CallApi} from "./common/API/APi";
import {error} from "./common/Toastify";


const Dashboard = () => {
  const profiledata = JSON.parse(localStorage.getItem("profiledata"));
  const path_file=process.env.REACT_APP_PATH_URL;
    const [countdata,setCountdata]=useState();
    const [graphdata,setGraphdata]=useState();


  const getcountdata=async()=>{
    let res = await CallApi({}, "dashboard");
    if(res?.status === 200){
      setCountdata(res?.data);
    }
   }
   const getgraphdashboard=async()=>{
    let res = await CallApi({}, "getordersbymonth");
    if(res?.status === 200){
      setGraphdata(res?.data);
    }
   }
   const getcrmbackup=async()=>{
    let res = await CallApi({}, "exportDb");
    if(res?.status === 200){
      const a = document.createElement("a");
      a.href = path_file+res?.data?.filePath;
      a.download = "CRMBackup.zip"; 
      a.click();
    }else{
      error("Something Went Wrong!");
    }

   }
   useEffect(()=>{
    getcountdata();
    getgraphdashboard();
   },[]);
  return (
   <>
   <div className="main-page-body position-relative">
        <div className="d-flex justify-content-between align-items-center gap-2">
             <h1 className="com-heading">ANALYTICS</h1>

             <div className="mb-2 ms-auto gap-2 ">
             {profiledata?.permission.filter((e) => e.name === "CRMBackup")[0]
            ?.permission?.edit === 1 || profiledata?.permission.filter((e) => e.name === "CRMBackup")[0]
            ?.permission?.view === 1   ? (
                <button
                onClick={getcrmbackup}
                      type="button"
                      className="btn font-13px golden-bg d-flex align-items-center justify-content-center gap-2 text-nowrap com-btn golden-btn-respn-show">
                      <LuDatabaseBackup />
                        CRM Backup 
                </button>
            ):''}
              </div>  
        </div>
       
        <div className="d-flex gap-4 overflow-auto mb-3">
          <Totalorder countdata={countdata}/>
        </div>
        <div>
          <BalanceOverview graphdata={graphdata}/>
        </div>
        <div className="row mt-4">
        </div>
      </div>

   </>
      

    
  )
}

export default Dashboard;