import { React,useState,useEffect } from "react";
import { io } from 'socket.io-client';
import GoogleMapcom from './GoogleMap';

const TrackDriver = (props) => {
  const {trackorderid,setTrackorderid}=props;
  const ws_url = process.env.REACT_APP_PATH_URL; 
  const [currenlocation,setCurrentlocation]=useState({'currentloc':'','toloc':''});
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    if(trackorderid){
      const newSocket = io(ws_url, {
        transports: ['websocket'],
        reconnectionAttempts: 5,
        reconnectionDelay: 2000
      });
      setSocket(newSocket);

      newSocket.on('connect', () => {
        console.log("connect");
        newSocket.emit('order', trackorderid);
      });
      newSocket.on('orderUpdate', (data) => {
        let fromloc={'lat':'','lng':''};
        console.log(data,"datadata");
        if(data?.to_location){
              let jsondata= JSON.parse(data?.to_location);
              fromloc.lat= parseFloat(jsondata['lat']);
              fromloc.lng= parseFloat(jsondata['lang']);

        }
        setCurrentlocation({'currentloc':data?.currentloc,'toloc':fromloc});
        console.log('Message from server:', data);
      });
  0
      newSocket.on('disconnect', () => {
        setTrackorderid(null);
        setCurrentlocation({ currentloc: '', toloc: '' });
        console.log('Disconnected from WebSocket server');
      });
  
    
      return () => {
        newSocket.disconnect();
      };
      
    }
  
  }, [trackorderid]);

  useEffect(() => {
    const handleModalClose = () => {
      if (socket) {
        socket.disconnect();
        setTrackorderid(null);
        setCurrentlocation({ currentloc: '', toloc: '' });
        setSocket(null);
      }
    };

    const modalElement = document.getElementById("Trackdrivermodal");
    modalElement.addEventListener("hide.bs.modal", handleModalClose);

    return () => {
      modalElement.removeEventListener("hide.bs.modal", handleModalClose);
    };
  }, [socket]);
  

 return (
    <div className="modal fade com-modal" id="Trackdrivermodal" tabIndex="-1" aria-labelledby="addDriverModalLabel" aria-hidden="true">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title fs-5 golden-text" id="addDriverModalLabel">Track Driver</h1>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">
         {(trackorderid && currenlocation?.toloc) ? <GoogleMapcom currentLocation={currenlocation} />:'Wait for some time....'}
        </div>
      </div>
    </div>
  </div>
  );
};

export default TrackDriver;
