import {React,useState,useEffect} from 'react';
import Select from 'react-select';
import {CallApi} from "../common/API/APi";
import {success,error} from "../common/Toastify"; 

const AddDriver = (props) => {
   const {editid,setNotifyrole,setEditid} = props;
  const [editexpensesdata, seteditExpensesdata] = useState('');
  const [errors, setError] = useState();
    const editstatusoption = [
        { value: 1, label: 'Active' },
        { value: 2, label: 'Suspend' },
      ];
      const SubmitEditExpensesType =async()=>{
                  if(!editexpensesdata?.expense_type){
                    setError({ editexpensestype: true });
                    return;
                  }  
                  editexpensesdata['status']= editexpensesdata?.status_select?.value;
                  delete editexpensesdata?.status_select;
            let res = await CallApi(editexpensesdata, "add_expense_type");
            if(res?.status === 200){
              setNotifyrole(current => !current);
              success(res?.message);
              setEditid();
              document.getElementById("editExpensesTypeform").reset();
              document.querySelector(".excancel").click();
            }else{
              error(res?.message);
            }
      }
      const getexpensestypedata =async()=>{
         setError();
         let res = await CallApi({'id':editid}, "get_expense_typebyid");
         if(res?.status === 200){
            seteditExpensesdata(res?.data);
         }
       }
       useEffect(()=>{
         if(editid){
            getexpensestypedata();
         }
       },[editid])
  return(
    <div className="modal fade com-modal" id="editExpensesModal" tabIndex="-1" aria-labelledby="editExpensesModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5 golden-text" id="editExpensesModalLabel">Edit Expenses Type</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
          <form id="editExpensesTypeform">
            <div className='row'>
              <div className='col-md-6 mb-3'>
              <input type="text" className={errors?.editexpensestype? " com-input-field border-danger" : "com-input-field"} value={editexpensesdata?.expense_type} onChange={(e)=>seteditExpensesdata({...editexpensesdata,expense_type:e.target.value})} placeholder='Expenses Type'/>
              </div>
           
              <div className='col-md-6 mb-3'>
              <Select
                  value={editexpensesdata?.status_select}
                  onChange={(e)=>seteditExpensesdata({...editexpensesdata,status_select:e})}
                  options={editstatusoption}
                  classNamePrefix="com-select"
                  isSearchable= {false}
                />
              </div>
            </div>
            </form>
          </div>
          <div className="modal-footer text-center mt-3">
            <button type="button" className="golden-bg font-13px com-btn golden-border" onClick={SubmitEditExpensesType} >Update Expenses Type</button>
            <button type="button" className="golden-bg font-13px com-btn com-btn-outline golden-border bg-transparent excancel" data-bs-dismiss="modal">Cancel</button> 
          </div>
        </div>
      </div>
    </div>
  )
} 

export default AddDriver;
