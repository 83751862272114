import React from 'react';
import DoneIcon from '../../assets/images/DoneIcon';
import {success,error} from "../common/Toastify";
import {CallApi} from "../common/API/APi";

const BookingComplete = (props) => {
let{setNotifyrole,bookingid}=props;

  const completedbook=async()=>{
    if(bookingid?.id){
      const  postdata={"id":bookingid?.id,'status':5,'return_order':bookingid?.return_order?bookingid?.return_order:0}
      let res = await CallApi(postdata, 'booking_status');
      if(res?.status === 200){
        setNotifyrole(current => !current);
        success(res?.message);
      }else{
        error(res?.message);
      }
    }
    }
  return (
    <div
      className="modal fade com-modal delete-item-modal"
      id="bookingComplete"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="BookingCompleteModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog  modal-dialog-centered delete-item-modal-dialog"
      >
        <div className="modal-content">
          <div className="modal-body">
            <div className="text-center mb-2 mt-3 ">
              <span className='completeIcon-box'>
                <DoneIcon/>
              </span>
            </div>
            <p className="text-center mt-5 mb-4 font-15px">
               Is This Booking <b>Accept</b> From Your Side?
            </p>
          </div>
          <div className="pb-4 gap-3 text-center d-flex justify-content-center">
            <button
              data-bs-dismiss="modal"
              type="button"
              className="font-13px com-btn bg-green booking-complete-footer"
              onClick={completedbook}
            >
              Yes, Accept!
            </button>
            <button
              type="button"
              className="font-13px com-btn green-color bg-white booking-complete-footer"
              data-bs-dismiss="modal"
            >
              No,Yet.
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookingComplete;