import React,{ useState,useEffect} from "react";
import DataTable from 'react-data-table-component';
import { BiSearch } from "react-icons/bi"
import {CallApi} from "./common/API/APi";
import DataTableLoaders from "./common/DatatableLoader";
import Moment from "react-moment";
import DeleteModal from "./modal/DeleteModal";
import StatusModal from "./modal/StatusModal";
import { IoMdAddCircleOutline } from "react-icons/io";
import { DateRange } from "./modal/ComDatePicker";
import Select from 'react-select';
import AddExpenseschannel from "./modal/Addexpensechannel";
import moment from "moment-timezone";

// import EditExpenseschannel from "./modal/Editexpenseschannel";



const ExpensesChannel = () => {
  const profiledata = JSON.parse(localStorage.getItem("profiledata"));
  const [search,setSearch]=useState('');
  const [datatableloder,setDatatableloder]=useState();
  const [expensesdata,setExpensesdata]=useState();
  const [datesearch,setDateSearch]=useState([]);
  const [deleteItem, setDeleteItem] = useState();
  const [statusItem, setStatusItem] = useState();
  const [notifyrole, setNotifyrole] = useState();
  // const [editid, setEditid] = useState();
  const [ExchangeTypeData, setExchangeTypeData] = useState();
  const [ExchangeTypevalue, setExchangeTypevalue] = useState();




  const paginationRowOptions = [50, 100, 500, 1000];

const columns = [
  
  {name: 'S.No.',selector: row => row.id,sortable: true,width:"80px"},
  {name: ' Expenses Type',selector: row => row.expensestype,sortable: true,minWidth:"200px"},
  {name: ' Expenses Channel Name',selector: row => row.expenses,sortable: true,minWidth:"200px"},
  {name: 'Added By',selector: row => row.addedby,sortable: true,minWidth:"130px"},
  {name: 'Created At',selector: row => row.createdat,sortable: true,minWidth:"160px"},
  {name: 'Updated At',selector: row => row.updatedat,sortable: true,minWidth:"160px"},
  {
    name: "Action",
    sortable: false,
    minWidth: "300px",
    selector: (row) => (
      <>
     {" "}
        {/* {profiledata?.permission.filter((e) => e.name === "ExpensesChannel")[0]
          ?.permission?.edit === 1 ? (
        <button
          className="cell-tabs bg-secondary text-white"
          onClick={() => setEditid(row?.action?.id)}
          data-bs-toggle="modal"
          data-bs-target="#updateExpenseschannelModal"
        >
          Edit
        </button>
         ) : (
          ""
        )} */}
         {profiledata?.permission.filter((e) => e.name === "ExpensesChannel")[0]
          ?.permission?.delete === 1 ? 
        
        <button
          className="cell-tabs mx-3 bg-danger text-white"
          onClick={() => setDeleteItem(row?.action?.id)}
          data-bs-toggle="modal"
          data-bs-target="#deleteitemModal"
        >
          Delete
        </button>
         : (
          ""
        )}
         {profiledata?.permission.filter((e) => e.name === "Expenses")[0]
          ?.permission?.edit === 1 ? (
          row?.action?.status === 1 ? (
            <button
              data-bs-toggle="modal"
              data-bs-target="#statusModal"
              onClick={() =>
                setStatusItem({ id: row?.action?.id, status: 2 })
              }
              className="cell-tabs text-white bg-success"
            >
              Approved
            </button>
          ) : (
            <button
              data-bs-toggle="modal"
              data-bs-target="#statusModal"
              onClick={() =>
                setStatusItem({ id: row?.action?.id, status: 1 })
              }
              className="cell-tabs bg-warning"
            >
              Reject
            </button>
          )
        ) : (
          ""
        )}
         
      </>
    ),
  },
];

const getexpenseschannel=async()=>{
  setDatatableloder(true);
  let res = await CallApi({"search":search,"from_date": datesearch[0]?datesearch[0]:'',
  "to_date": datesearch[1]? datesearch[1]:'',"type":ExchangeTypevalue?.value}, "get_subexpensechannel");
  if(res?.status === 200){
    let array=[];
    if( res?.data?.length){
      res?.data.map((value,key)=>{
        const CreatedAt = moment.utc(value?.createdAt).tz("America/New_York");
        const UpdateAt = moment.utc(value?.updatedAt).tz("America/New_York");

        array.push({
            
                    "id":key+1,
                    "expensestype":value?.exp_type_name,
                    'expenses':value?.expchannel,
                    "addedby":value?.addedBy,
                    "createdat": <Moment format="MMM DD, YYYY : hh:mm A" >{CreatedAt}</Moment>,
                    "updatedat":<Moment format="MMM DD, YYYY : hh:mm A" utc>{UpdateAt}</Moment>,
                    "action":{ id: value?._id, status: value?.status }
        })
      })
     
      
    }  
    setExpensesdata(array);
    setDatatableloder(false);
  }
 }
 const getexpensestype=async()=>{
  let res = await CallApi({"search":'',"status":1}, "get_expense_type");
  let array=[{'value':'','label':'All'}];
  if(res?.status === 200){
           res?.data.map((value)=>{
            array.push({'value':value?._id,'label':value?.expense_type});
           })
           setExchangeTypeData(array);
  }
}
 useEffect(()=>{
  getexpenseschannel();

 },[notifyrole,datesearch,ExchangeTypevalue?.value])
 useEffect(()=>{
  getexpensestype();
 },[])

  return (
          <>
            <div className="main-page-body position-relative">
              <h1 className="com-heading">Expenses Channel</h1>

              <div className="com-card-box">
                <div className="d-flex justify-content-between align-items-center gap-2">
                  <div className="col-md-4">
                    <h1 className="com-heading mb-0">Expenses Channel List</h1>
                  </div>
                  <div className="col-md-3 d-flex align-items-center justify-content-end ms-auto gap-2 ">
                  <button
                    type="button"
                    className="btn font-13px golden-bg d-flex align-items-center justify-content-center gap-2 text-nowrap com-btn golden-btn-respn-show"
                    data-bs-toggle="modal"
                    data-bs-target="#addallExpenseschannelModal"
                  >
                    <IoMdAddCircleOutline />
                    Add Expenses Channel
                  </button>
                  </div>
                </div>
              </div>

              <div className="com-card-box">
                <div className="row">
                  <div className="col-lg-3 col-md-5 col-sm-6 mb-3 mb-sm-0">
                    <div className="input-group">
                      <div className="input-group-text bg-transparent border-end-0"><BiSearch/></div>
                      <input type="text" className="form-control border-start-0 ps-0 font-13px" value={search} onKeyPress={(e) => e.key === "Enter" && getexpenseschannel() } onChange={(e)=>setSearch(e.target.value)} placeholder="Search Expenses...."/>
                    </div>
                  </div>
                  <div className=" col-md-6 ms-auto col-sm-6 col-12 order-datatable-filter-container">
           
                  <Select
                  onChange={setExchangeTypevalue}
                  value={ExchangeTypevalue}
                  options={ExchangeTypeData}
                  classNamePrefix="com-select"
                  isSearchable= {true}
                  placeholder="Select Expenses Type"
                />

                  <DateRange setDateSearch={setDateSearch} />
                    <div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="com-datatable com-card-box py-2">
                {datatableloder?
                <DataTableLoaders/>
                :
                <DataTable
                columns={columns}
                data={expensesdata}
                pagination
                fixedHeaderScrollHeight="300px"
                className="myTable"
                responsive
                striped
                paginationRowsPerPageOptions= {paginationRowOptions}
              />
                }
              </div>
            
            </div>
            <AddExpenseschannel   setNotifyrole={setNotifyrole} />
            {/* <EditExpenseschannel  setNotifyrole={setNotifyrole} editid={editid} setEditid={setEditid}/> */}
             <DeleteModal 
             apiname={"delete_subexpensechannel"}
             deleteItem={deleteItem}
             setNotifyrole={setNotifyrole}/>
            <StatusModal 
            apiname={"update_subexpensechannelstatus"}
            statusItem={statusItem}
            setNotifyrole={setNotifyrole}/>
           
          </>

    
  )
}

export default ExpensesChannel;