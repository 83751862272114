import React, { useEffect, useState } from "react";
import { CallApi } from "./common/API/APi";
import { success, error } from "./common/Toastify";

const Setting = () => {
  const [tapikey, setTapikey] = useState({ tkey: "", ttoken: "" });
  const [sapikey, setSapikey] = useState();
  const [tapname, setTapname] = useState("twilio");
  const [errors, setError] = useState();
  const profiledata = JSON.parse(localStorage.getItem("profiledata"));

  const UpdateTwilio = async () => {
    let postdata = {};
    if (tapname == "twilio") {
      if (!tapikey?.tkey) {
        setError({ tkey: true });
        return;
      }
      if (!tapikey?.ttoken) {
        setError({ ttoken: true });
        return;
      }
      postdata = { name: tapname, key: tapikey?.tkey, token: tapikey?.ttoken };
    } else if (tapname == "sendgrid") {
      if (!sapikey) {
        setError({ skey: true });
        return;
      }
      postdata = { name: tapname, key: sapikey };
    }
    setError("");
    let res = await CallApi(postdata, "update_api_key");
    if (res?.status == 200) {
      success(res?.message);
    } else {
      error(res?.message);
    }
  };

  const getapikeys = async () => {
    let res = await CallApi({ name: tapname }, "get_api_key");
    if (res?.status === 200) {
      if (tapname == "twilio") {
        document.querySelector(".tapikey_name").placeholder = converttostar(
          res?.key?.account_sid
        );
        document.querySelector(".ttoken_name").placeholder = converttostar(
          res?.key?.auth_token
        );
        let twiliokeys = { ...tapikey };
        twiliokeys["tkey"] = res?.key?.account_sid;
        twiliokeys["ttoken"] = res?.key?.auth_token;
        setTapikey(twiliokeys);
      } else if (tapname == "sendgrid") {
        document.querySelector(".sapkikey_name").placeholder = converttostar(
          res?.key
        );
        setSapikey(res?.key);
      }
    } else {
      error(res?.message);
    }
  };
  const converttostar = (key) => {
    if (key) {
      let array = Array.from(key);
      let last2 = array.slice(-2);
      let first2 = array.slice(0, 4);
      let finalarray = array.slice(4, -2);
      let getstararray = [];
      for (let i = 0; i < finalarray.length; i++) {
        getstararray.push("*");
      }
      let concatarray = first2.concat(getstararray, last2);
      return concatarray.join("");
    }
  };
  useEffect(() => {
    getapikeys();
  }, [tapname]);
  return (
    <>
      <div className="setting-main-box main-page-body position-relative">
        <div className="setting-main-box-header com-card-box">
          <ul
            className="nav nav-pills ms-md-4 d-flex gap-4"
            id="pills-tab"
            role="tablist"
          >
            <li
              className="nav-item"
              role="presentation"
              onClick={() => setTapname("twilio")}
            >
              <button
                className="nav-link active setting-main-box-header-toggle-btn"
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
              >
                Twilio
              </button>
            </li>
            <li
              className="nav-item"
              role="presentation"
              onClick={() => setTapname("sendgrid")}
            >
              <button
                className="nav-link setting-main-box-header-toggle-btn"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                Sendgrid
              </button>
            </li>
          </ul>
        </div>
        <div
          className="tab-content setting-main-box-body"
          id="pills-tabContent"
        >
          <div
            className="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div className="com-card-box setting-main-box-body-card-box">
              <div className="setting-main-box-body-card-box-heading">
                <span className="com-heading">Twilio</span>
              </div>
              <div className="setting-main-box-body-card-box-subBody mt-4 col-md-12 col-sm-12 col-12 mb-3">
                <div className="setting-form-imput-box">
                  <label className="setting-form-label">Api Key</label>
                  <input
                    type="text"
                    className={
                      errors?.tkey
                        ? "form-control border-danger tapikey_name"
                        : "form-control tapikey_name"
                    }
                    onChange={(e) =>
                      setTapikey({ ...tapikey, tkey: e.target.value })
                    }
                  />
                </div>
                <div className="setting-form-imput-box">
                  <label className="setting-form-label">Api Token</label>
                  <input
                    type="text"
                    className={
                      errors?.ttoken
                        ? "form-control border-danger ttoken_name"
                        : "form-control ttoken_name "
                    }
                    onChange={(e) =>
                      setTapikey({ ...tapikey, ttoken: e.target.value })
                    }
                  />
                </div>
                {profiledata?.permission.filter((e) => e.name === "Setting")[0]
                  ?.permission?.edit === 1 ? (
                  <span
                    className="setting-key-update-btn"
                    onClick={UpdateTwilio}
                  >
                    <button className="btn w-100 font-13px golden-bg d-flex align-items-center justify-content-center gap-2 text-nowrap com-btn ">
                      Update
                    </button>
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
          >
            <div className="com-card-box setting-main-box-body-card-box">
              <div className="setting-main-box-body-card-box-heading">
                <span className="com-heading">Sendgrid</span>
              </div>
              <div className="setting-main-box-body-card-box-subBody mt-4 col-md-6 col-sm-6 col-11 mb-3">
                <label className="setting-form-label">Api Key</label>
                <input
                  type="text"
                  className={
                    errors?.skey
                      ? "form-control border-danger sapkikey_name"
                      : "form-control sapkikey_name"
                  }
                  onChange={(e) => setSapikey(e.target.value)}
                />
                {profiledata?.permission.filter((e) => e.name === "Setting")[0]
                  ?.permission?.edit === 1 ? (
                  <span
                    className="setting-key-update-btn"
                    onClick={UpdateTwilio}
                  >
                    <button className="w-100 btn font-13px golden-bg d-flex align-items-center justify-content-center gap-2 text-nowrap com-btn ">
                      Update
                    </button>
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Setting;
