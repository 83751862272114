import {React,useEffect,useState} from 'react';
import Select from 'react-select';
import {CallApi} from "../common/API/APi";
import {success,error} from "../common/Toastify"; 

const AddDriver = (props) => {
    let {setNotifyrole} =props;
  const [statusvalue, setStatusvalue] = useState({value: 1, label: 'Active'});
  const [vehicelmodeldata, setVehicelmodeldat] = useState([]);
  const [adddriverdata, setAdddriverdata] = useState({'name':'','email':'','phone':'','password':'','address':'','vehicle_model':'','vehicle_number':'',role_id:'','user_type':2});
  const [errors, setError] = useState();
    const statusoption = [
        { value: 1, label: 'Active' },
        { value: 2, label: 'Suspend' },
      ];
      const SubmitDriver =async()=>{
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;


                  if(!adddriverdata?.name){
                    setError({ name: true });
                    return;
                  } 
                 if(!adddriverdata?.email || !emailRegex.test(adddriverdata?.email)){
                   setError({ email: true });
                    return;
                 } 
                 if(!adddriverdata?.phone ){
                  setError({ phone: true });
                  return;
                } 
                if(!adddriverdata?.password){
                  setError({ password: true });
                  return;
                } 
                if(!adddriverdata?.address){
                  setError({ address: true });
                  return;
                }   
                if(!adddriverdata?.vehicle_model){
                  setError({ vehicle_model: true });
                  return;
                }   
                if(!adddriverdata?.vehicle_number){
                  setError({ vehicle_number: true });
                  return;
                }   
                adddriverdata['status'] = statusvalue?.value;
            let res = await CallApi(adddriverdata, "admin");
            if(res?.status === 200){
              setNotifyrole(current => !current);
              success(res?.message);
              document.getElementById("addDriverform").reset();
              document.querySelector(".addcancel").click();
            }else{
              error(res?.message);
            }
      }
      const getvehicledata=async()=>{
        let res = await CallApi({}, "getvehiclemodel");
        if(res?.status === 200){
          let array=[];
          res?.data.map((value)=>{
            array.push({label:value?.name,value:value?.id})      
          })
          setVehicelmodeldat(array)
        }
      }
     useEffect(()=>{
      setAdddriverdata({'name':'','email':'','phone':'','password':'','address':'','vehicle_model':'','vehicle_number':'',role_id:'','user_type':2});
      setError('');
      getvehicledata();
     },[])
  return(
    <div className="modal fade com-modal" id="addDriverModal" tabIndex="-1" aria-labelledby="addDriverModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5 golden-text" id="addDriverModalLabel">Add New Driver</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
          <form id="addDriverform">
            <div className='row'>
              <div className='col-md-6 mb-3'>
              <input type="text" className={errors?.name? " com-input-field border-danger" : "com-input-field"} value={adddriverdata?.name} onChange={(e)=>setAdddriverdata({...adddriverdata,name:e.target.value})} placeholder='Name'/>
              </div>
              <div className='col-md-6 mb-3'>
              <input type="email" className={errors?.email? " com-input-field border-danger" : "com-input-field"} value={adddriverdata?.email} onChange={(e)=>setAdddriverdata({...adddriverdata,email:e.target.value})} placeholder='Email'/>
              </div>
              <div className='col-md-6 mb-3'>
              <input type="text" className={errors?.phone? " com-input-field border-danger" : "com-input-field"} value={adddriverdata?.phone} onChange={(e)=>setAdddriverdata({...adddriverdata,phone:e.target.value})} placeholder='Contact Number'/>
              </div>
               <div className='col-md-6 mb-3'>
               <input type="password" className={errors?.password? " com-input-field border-danger" : "com-input-field"} value={adddriverdata?.password} onChange={(e)=>setAdddriverdata({...adddriverdata,password:e.target.value})} placeholder='Password'/>
              </div>
              
              <div className='col-md-12 mb-3'>
                <textarea className={errors?.address? " com-input-field h-auto resize-none border-danger" : "com-input-field h-auto resize-none"} value={adddriverdata?.address} onChange={(e)=>setAdddriverdata({...adddriverdata,address:e.target.value})} rows="3" placeholder='Address'/>
              </div>
              <div className='col-md-6 mb-3'>
              <Select
                  defaultValue={statusvalue}
                  onChange={setStatusvalue}
                  options={statusoption}
                  classNamePrefix="com-select"
                  isSearchable= {false}
                />
              </div>
              <div className='col-md-6 mb-3'>
              <Select
                  defaultValue={''}
                  onChange={(e)=>setAdddriverdata({...adddriverdata,vehicle_model:e.value})}
                  options={vehicelmodeldata}
                  classNamePrefix={errors?.vehicle_model? " com-select border-danger" : "com-select"}
                  placeholder='Vehicle Model'
                  isSearchable= {false}
                />
              </div>
              <div className='col-md-6 mb-3'>
               <input type="text" className={errors?.vehicle_number? " com-input-field border-danger" : "com-input-field"} value={adddriverdata?.vehicle_number} onChange={(e)=>setAdddriverdata({...adddriverdata,vehicle_number:e.target.value})} placeholder='Vehicle Number'/>
              </div>
            </div>
            </form>
          </div>
          <div className="modal-footer text-center mt-3">
            <button type="button" className="golden-bg font-13px com-btn golden-border" onClick={SubmitDriver} >Create Driver</button>
            <button type="button" className="golden-bg font-13px com-btn com-btn-outline golden-border bg-transparent addcancel" data-bs-dismiss="modal">Cancel</button> 
          </div>
        </div>
      </div>
    </div>
  )
} 

export default AddDriver;
