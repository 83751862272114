import { React,useState,useEffect } from "react";
import {CallApi} from "../common/API/APi";
import {success,error} from "../common/Toastify"; 
import Select from 'react-select';

const CallModal = (props) => {
   const {callitem,setNotifyrole}= props;
   const profiledata= JSON.parse(localStorage.getItem('profiledata'));
      const[calldata,setCalldata]=useState({'callmessage':'','callstatus':''});
      const [errors, setError] = useState();
   const callstatusdata=[
      {value:'yes',label:'Yes'},
      {value:'no',label:'No'}
   ]
   const getcalldata=async()=>{
    setCalldata();
    let res = await CallApi({'id':callitem}, "getcallstatus");
    if(res?.status === 200){
      setCalldata(res?.data);
    }
   }
   const submitcall=async()=>{
    if(!calldata?.callstatus?.value){
        setError({ callstatus: true });
        return;
      }
      if(!calldata?.callmessage){
        setError({ callmessage: true });
        return;
      }
      calldata['id']=callitem;
      calldata['staff_id']=profiledata?.id;
      let res = await CallApi(calldata, "addcallstatus");
      if(res?.status === 200){
        setNotifyrole(current => !current);
        success(res?.message);
        setError('');
        document.getElementById("callform").reset();
        document.querySelector(".cancelcall").click();
      }else{
        error(res?.message);
      }
   }
   useEffect(()=>{
 
    if(callitem){
      setCalldata({'callmessage':'','callstatus':''});
        getcalldata();
    }
    
   },[callitem])

    return (
        <div
        className="modal fade com-modal"
        id="customercallModal"
        tabIndex="-1"
        aria-labelledby="customercallModalLabel"
        aria-hidden="true"
        >
        <div className="modal-dialog">
            <div className="modal-content">
            <div className="modal-header">
                <h1
                className="modal-title fs-5 golden-text"
                id="orderRefundModalLabel"
                >
                Customer Call Informations
                </h1>
                <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ></button>
            </div>
            <div className="modal-body">
                <form id="callform">
                <div className="row ">
                    <div className=" col-md-12 mb-3">
                      <p>Call Status</p>
                        <Select
                          value={calldata?.callstatus}
                          onChange={(e)=>setCalldata({...calldata,'callstatus':e})}
                          options={callstatusdata}
                          classNamePrefix="com-select"
                          isSearchable= {false}
                          placeholder="Select Status"
                        />
                        

                    </div>
                </div>
                <div className="row mt-4">
                  <p>Message</p>
                    <div className=" order-refund-amount-box ">
                        <textarea  placeholder='Any Call Message' rows="4"  value={calldata?.callmessage?calldata?.callmessage:''} onChange={(e)=>setCalldata({...calldata,'callmessage':e.target.value})} className={errors?.callmessage?"com-input-field resize-none overflow-auto h-auto border-danger":'com-input-field resize-none overflow-auto h-auto'} />
                    </div>
                </div>
                </form>
            </div>
            <div className="modal-footer text-center mt-3">
                <button
                type="button"
                className="golden-bg font-13px com-btn golden-border"
                onClick={submitcall}
                >
                Submit
                </button>
                <button
                type="button"
                className="golden-bg font-13px com-btn com-btn-outline golden-border bg-transparent cancelcall"
                data-bs-dismiss="modal"
                >
                Cancel
                </button>
            </div>
            </div>
        </div>
        </div>
    );
};

export default CallModal;
