import React,{useState,useEffect} from 'react';
import {CallApi} from "../common/API/APi";
import {success,error} from "../common/Toastify"; 


const EditTransferPrice = (props) => {
  const {transferbyid,setNotifyrole}=props;
  const [transferprice,setTransferprice]=useState([]);
  const gettransferbyid=async()=>{
          let res= await CallApi({'id':transferbyid},'get_pricelistbyid');
          if(res?.status ==200){
            setTransferprice(res?.data?.transfer);
          }
  }
  const updatesamount=(cartype,name,targetvalue,keys)=>{
          let dataarray=[...transferprice];
          dataarray.map((value)=>{
              if(value?.cartype == cartype){
                dataarray[keys][name]=targetvalue;
              }
          })
          setTransferprice(dataarray);
  }

  const updatetransferdata=async()=>{
    let inputs= document.querySelectorAll(".transfer-car-amount-box-show input");
    for(let i=0;i<inputs.length;i++){
      if(inputs[i].value == ''){
        error("Transfer distance and amount should not be empty!");
        return;
      }
    }
    let res= await CallApi({'transfer':transferprice,'id':transferbyid},'update_pricelist');
    if(res?.status ==200){
      success(res?.message);
      setNotifyrole(current => !current);
      document.querySelector(".updatetransfercancel").click();
    }else{
      error(res?.message);
    }
}
  useEffect(()=>{
         if(transferbyid){
           gettransferbyid();
         }
  },[transferbyid])
return(
  <div className="modal fade com-modal" id="editTransferpriceModal" tabIndex="-1" aria-labelledby="editTransferpriceModal" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5 golden-text" id="editTransferpriceModal">Edit Transfer</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body pb-0">
        <form id="">
          {transferprice?.length?transferprice.map((value,key)=>{
            return(
              <div className="row" key={key}>
                  <p className="order-refund-amount-box-para mb-0 ms-2">
                    {value?.carname}
                  </p>        
                  <div className=" transfer-car-amount-box d-flex justify-content-start align-items-center ms-auto my-1" >
                      <p className="transfer-car-amount-box-para m-0  text-nowrap font-15px">
                          Base Amount($) :
                      </p>
                      <div className="transfer-car-amount-box-show d-flex justify-content-start  align-items-center  gap-1 ms-auto">
                          <input type="number"  value={value?.base_amount} onChange={(e)=>updatesamount(value?.cartype,'base_amount',e.target.value,key)} className= "com-input-field" style={{height: "32px",width: "60%"}}/>
                      </div>
                  </div>
                  <div className="  transfer-car-amount-box d-flex justify-content-start align-items-center ms-auto my-1">
                      <p className="transfer-car-amount-box-para m-0  text-nowrap font-15px">
                          Base Distance(miles) :
                      </p>
                      <div className="transfer-car-amount-box-show d-flex justify-content-start  align-items-center  gap-1 ms-auto">
                          <input type="number"  value={value?.base_distance} className= "com-input-field" onChange={(e)=>updatesamount(value?.cartype,'base_distance',e.target.value,key)} style={{height: "32px",width: "60%"}}/>
                      </div>
                  </div>
                  <div className="transfer-car-amount-box d-flex justify-content-start align-items-center ms-auto my-1">
                      <p className="transfer-car-amount-box-para m-0  text-nowrap font-15px">
                          Distance Amount($) :
                      </p>
                      <div className="transfer-car-amount-box-show d-flex justify-content-start  align-items-center  gap-1 ms-auto">
                          <input type="number"  value={value?.distance_amount} onChange={(e)=>updatesamount(value?.cartype,'distance_amount',e.target.value,key)} className= "com-input-field" style={{height: "32px",width: "60%"}}/>
                      </div>
                  </div>
              </div>
            );
          }):''}
          
          
        </form>

      </div>
      <div className="modal-footer text-center">
        <button type="button" className="golden-bg font-13px com-btn golden-border" onClick={updatetransferdata} >Update</button>
        <button type="button" className="golden-bg font-13px com-btn com-btn-outline golden-border bg-transparent updatetransfercancel" data-bs-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>
);
}
export default EditTransferPrice;