import React, { useState,useEffect } from "react";
import {Link,useNavigate,NavLink} from "react-router-dom";
import "./sidebar.scss";
import Logo from "../../../assets/images/main-logo.png"
import ShortLogo from "../../../assets/images/short-logo.png"
import { HiOutlineBars3BottomLeft } from "react-icons/hi2"
import { AiOutlineDashboard } from "react-icons/ai";
import { MdProductionQuantityLimits , MdOutlineManageAccounts } from "react-icons/md";
import { GiPayMoney } from "react-icons/gi";
import { BsCashCoin } from "react-icons/bs";
import { TbLogout} from "react-icons/tb";
import { HiOutlineUsers } from "react-icons/hi";
import { IoIosArrowDown,IoMdPricetags } from "react-icons/io";
import { IconContext } from "react-icons";
import CarBooking from "../../../assets/images/BookingIcon";
import CarDriverIcon from "../../../assets/images/CarDriverIcon";
import { RiMailSettingsLine } from "react-icons/ri";


const Sidebar = (props) => {
  let {setalert}=props;
  const profiledata= JSON.parse(localStorage.getItem('profiledata'));
  const [sidebarToggle , setSidebarToggle] = useState(false);
  let navigate = useNavigate();
  const Logout=()=>{
    localStorage.clear();
    setTimeout(() => {
      navigate("/");
    }, 1000);
  }
  useEffect(()=>{
    let ul = document.getElementById('siderbarlist');
    ul.addEventListener('click', function () {
    
       if(props?.setalert){
        setalert(current=>!current);
       }
       if (window.innerWidth < 1199) {
        setSidebarToggle(current => !current);
      }
        
      
    }); 
  },[])

  return(
    <aside className={sidebarToggle ? "minimized main-sidebar position-relative main-page-row-left flex-shrink-0" : "main-sidebar position-relative main-page-row-left flex-shrink-0"}>
      <button className="border-0 p-0 bg-white main-sidebar-toggle position-absolute" onClick={() => {setSidebarToggle(current => !current)}}><HiOutlineBars3BottomLeft/></button>
      {profiledata?.user_type == 1?
      <>
      <div className="text-center mt-4">
        
        <Link to={profiledata?.permission.filter(e => e.name === 'Dashboard')[0]?.permission?.view === 1 ?"/dashboard":"#"}>
          <img className="main-sidebar-logo d-none d-xl-block" src={sidebarToggle ? ShortLogo : Logo} alt="side-logo"/>
          <img className="main-sidebar-logo d-block d-xl-none" src={Logo} alt="side-logo"/>
        </Link>
      </div>
      <ul className="main-sidebar-menu list-unstyled " id="siderbarlist">
      {profiledata?.permission.filter(e => e.name === 'Dashboard')[0]?.permission?.view === 1 ?
        <li  className="main-sidebar-menu-single main-sidebar-menu-dropdown"><Link to="/dashboard"><button className="main-sidebar-menu-single-a btn-toggle main-sidebar-menu-dropdown-btn d-flex align-items-center w-100" data-bs-toggle="collapse" data-bs-target="#dashboard-collapse" aria-expanded="true"><AiOutlineDashboard/>
          <span className="main-sidebar-menu-single-a-text">  Dashboard </span>
        {/* <IconContext.Provider value={{ className: "main-sidebar-menu-dropdown-btn-arrow" }}><IoIosArrowDown/></IconContext.Provider> */}
        </button></Link>
        </li>:''}
        {(profiledata?.permission.filter(e => e.name === 'Staff')[0]?.permission?.view === 1 || profiledata?.permission.filter(e => e.name === 'Role')[0]?.permission?.view === 1)?
          <li className="main-sidebar-menu-single main-sidebar-menu-dropdown"><button className="main-sidebar-menu-single-a btn-toggle main-sidebar-menu-dropdown-btn d-flex align-items-center w-100" data-bs-toggle="collapse" data-bs-target="#manage-collapse" aria-expanded="true"><MdOutlineManageAccounts/>
            <span className="main-sidebar-menu-single-a-text"> Manage </span>
          <IconContext.Provider value={{ className: "main-sidebar-menu-dropdown-btn-arrow" }}><IoIosArrowDown/></IconContext.Provider></button>
          <div className="collapse main-sidebar-menu-dropdown-collapse rounded-1" id="manage-collapse">
            <ul className="main-sidebar-menu-dropdown-collapse-list btn-toggle-nav list-unstyled fw-normal pb-1 small">
              {profiledata?.permission.filter(e => e.name === 'Staff')[0]?.permission?.view === 1 ?<li className="main-sidebar-menu-dropdown-collapse-list-single"><NavLink to="/staff" className="main-sidebar-menu-dropdown-collapse-list-single-a text-decoration-none"><span className="main-sidebar-menu-single-a-text">Staff</span></NavLink></li>:''}
              {profiledata?.permission.filter(e => e.name === 'Role')[0]?.permission?.view === 1 ? <li className="main-sidebar-menu-dropdown-collapse-list-single"><NavLink to="/roles" className="main-sidebar-menu-dropdown-collapse-list-single-a text-decoration-none"><span className="main-sidebar-menu-single-a-text">Roles</span></NavLink></li>:''}
            </ul>
          </div>
          </li>
        :''}
        {profiledata?.permission.filter(e => e.name === 'Order')[0]?.permission?.view === 1 ?  <li className="main-sidebar-menu-single"><NavLink className="main-sidebar-menu-single-a d-flex align-items-center text-decoration-none" to="/order"><MdProductionQuantityLimits/> <span className="main-sidebar-menu-single-a-text">Orders</span></NavLink></li>:''}

        {profiledata?.permission.filter(e => e.name === 'Customer')[0]?.permission?.view === 1 ?   <li className="main-sidebar-menu-single"><NavLink className="main-sidebar-menu-single-a d-flex align-items-center text-decoration-none" to="/customers"><HiOutlineUsers/> <span className="main-sidebar-menu-single-a-text">Customers</span> </NavLink></li>:''}

        {profiledata?.permission.filter(e => e.name === 'Driver')[0]?.permission?.view === 1 ?   <li className="main-sidebar-menu-single"><NavLink className="main-sidebar-menu-single-a d-flex align-items-center text-decoration-none" to="/driver"><CarDriverIcon/> <span className="main-sidebar-menu-single-a-text">Drivers</span> </NavLink></li>:''}


        <li className="main-sidebar-menu-single main-sidebar-menu-dropdown">
          <button className="main-sidebar-menu-single-a btn-toggle main-sidebar-menu-dropdown-btn d-flex align-items-center w-100" data-bs-toggle="collapse" data-bs-target="#finance-collapse" aria-expanded="true"><BsCashCoin /> 
            <span className="main-sidebar-menu-single-a-text"> Finance </span>
            <IconContext.Provider value={{ className: "main-sidebar-menu-dropdown-btn-arrow" }}><IoIosArrowDown/></IconContext.Provider>
          </button>
          <div className="collapse main-sidebar-menu-dropdown-collapse rounded-1" id="finance-collapse">
            <ul className="main-sidebar-menu-dropdown-collapse-list btn-toggle-nav list-unstyled fw-normal pb-1 small">
              {profiledata?.permission.filter(e => e.name === 'Finance')[0]?.permission?.view === 1 ?  <><li className="main-sidebar-menu-dropdown-collapse-list-single"><NavLink className="main-sidebar-menu-dropdown-collapse-list-single-a text-decoration-none" to="/transaction"><span className="main-sidebar-menu-single-a-text">Transactions</span> </NavLink></li>
              <li className="main-sidebar-menu-dropdown-collapse-list-single"><NavLink className="main-sidebar-menu-dropdown-collapse-list-single-a text-decoration-none" to="/pnl"><span className="main-sidebar-menu-single-a-text">Profit And Loss</span> </NavLink></li></>:''}
            </ul>
          </div>
        </li>

        {profiledata?.permission.filter(e => e.name === 'Setting')[0]?.permission?.view === 1 ?   <li className="main-sidebar-menu-single"><NavLink className="main-sidebar-menu-single-a d-flex align-items-center text-decoration-none" to="/setting"><RiMailSettingsLine /> <span className="main-sidebar-menu-single-a-text">Settings</span> </NavLink></li>:''}
        {(profiledata?.permission.filter(e => e.name === 'ExpensesType')[0]?.permission?.view === 1 || profiledata?.permission.filter(e => e.name === 'Expenses')[0]?.permission?.view === 1)?
        <li className="main-sidebar-menu-single main-sidebar-menu-dropdown"><button className="main-sidebar-menu-single-a btn-toggle main-sidebar-menu-dropdown-btn d-flex align-items-center w-100" data-bs-toggle="collapse" data-bs-target="#expenses-collapse" aria-expanded="true"><GiPayMoney/>
          <span className="main-sidebar-menu-single-a-text"> Expenses </span>
        <IconContext.Provider value={{ className: "main-sidebar-menu-dropdown-btn-arrow" }}><IoIosArrowDown/></IconContext.Provider></button>
        <div className="collapse main-sidebar-menu-dropdown-collapse rounded-1" id="expenses-collapse">
          <ul className="main-sidebar-menu-dropdown-collapse-list btn-toggle-nav list-unstyled fw-normal pb-1 small">
            {profiledata?.permission.filter(e => e.name === 'ExpensesType')[0]?.permission?.view === 1 ?<li className="main-sidebar-menu-dropdown-collapse-list-single"><NavLink to="/expensestype" className="main-sidebar-menu-dropdown-collapse-list-single-a text-decoration-none"><span className="main-sidebar-menu-single-a-text">Expenses Type</span></NavLink></li>:''}
            {profiledata?.permission.filter(e => e.name === 'ExpensesChannel')[0]?.permission?.view === 1 ? <li className="main-sidebar-menu-dropdown-collapse-list-single"><NavLink to="/expenseschannel" className="main-sidebar-menu-dropdown-collapse-list-single-a text-decoration-none"><span className="main-sidebar-menu-single-a-text">Expenses Channel</span></NavLink></li>:''}
            {profiledata?.permission.filter(e => e.name === 'Expenses')[0]?.permission?.view === 1 ? <li className="main-sidebar-menu-dropdown-collapse-list-single"><NavLink to="/expenses" className="main-sidebar-menu-dropdown-collapse-list-single-a text-decoration-none"><span className="main-sidebar-menu-single-a-text">Expenses</span></NavLink></li>:''}
            <li className="main-sidebar-menu-dropdown-collapse-list-single"><NavLink to="/driverexpenses" className="main-sidebar-menu-dropdown-collapse-list-single-a text-decoration-none"><span className="main-sidebar-menu-single-a-text">Driver Expenses</span></NavLink></li>
          </ul>
        </div>
        </li>
        :""}
        {profiledata?.permission.filter(e => e.name === 'Price')[0]?.permission?.view === 1 ?   <li className="main-sidebar-menu-single"><NavLink className="main-sidebar-menu-single-a d-flex align-items-center text-decoration-none" to="/pricemanage"><IoMdPricetags/> <span className="main-sidebar-menu-single-a-text">Price Management</span> </NavLink></li>:''}
      </ul>
      </>:
      <>
        <div className="text-center mt-4">
          <Link to="/bookings">
            <img className="main-sidebar-logo d-none d-xl-block" src={sidebarToggle ? ShortLogo : Logo} alt="side-logo"/>
            <img className="main-sidebar-logo d-block d-xl-none" src={Logo} alt="side-logo"/>
          </Link>
        </div>
        <ul className="main-sidebar-menu list-unstyled " id="siderbarlist">
            <li  className="main-sidebar-menu-single main-sidebar-menu-dropdown"><Link to="/bookings"><button className="main-sidebar-menu-single-a btn-toggle main-sidebar-menu-dropdown-btn d-flex align-items-center w-100" data-bs-toggle="collapse" data-bs-target="#dashboard-collapse" aria-expanded="true">
              <CarBooking />
              <span className="main-sidebar-menu-single-a-text"> Bookings </span>
            </button></Link>
            </li>
        </ul>
        </>
      }
      <ul className="main-sidebar-menu bottom list-unstyled mt-auto " onClick={Logout}>
        <li className="main-sidebar-menu-single"><Link className="main-sidebar-menu-single-a d-flex align-items-center text-decoration-none" to=""><TbLogout/> <span className="main-sidebar-menu-single-a-text">Logout</span></Link></li>
      </ul>

    </aside>
  )
}

export default Sidebar;