import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "./TextEditor.scss";

const Editor = ({ placeholder,setEditordata }) => {
  const [editorHtml, setEditorHtml] = useState('');
 

  const handleChange = useCallback((html) => {
    setEditorHtml(html);
    setEditordata(html);
  }, []);


  return (
    <div className='text-editor-container'>
      <ReactQuill
        theme={'snow'}
        onChange={handleChange}
        value={editorHtml}
        modules={Editor.modules}
        formats={Editor.formats}
        bounds={'.app'}
        placeholder={placeholder}
      />
    </div>
  );
};

Editor.modules = {
  toolbar: [
    [{ 'font': [] }],
    ['bold', 'italic', 'underline'],
    ['link'],
  ],
  clipboard: {
    matchVisual: false,
  }
};

Editor.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
];

Editor.propTypes = {
  placeholder: PropTypes.string,
};

export default Editor;
