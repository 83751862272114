import React,{useState,useEffect} from 'react';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import ChangePassword from './components/ChangePassword';
import Profile from './components/Profile';
import Customers from './components/Customers';
import Staff from './components/Staff';
import Order from './components/Order'
import Roles from './components/Roles'
import {ProtectedRoute,UnProtectedRoute} from './components/common/ProtectRouter';
import Loader from "./components/common/MainLoader";
import Layout from './components/common/Layout';
import Otp from './components/otp';
import Driver from './components/Driver';
import Bookings from './components/Bookings';
import Setting from './components/Setting';
import Finance from './components/Finance';
import Expenses from './components/Expenses';
import ExpensesType from './components/ExpensesType';
import DriverExpenses from './components/DriverExpenses';
import PNL from './components/PNL';
import PriceManage from './components/PriceManage';
import ExpensesChannel from './components/ExpenseChannel';



function App() {
  const [isLoading, setIsLoading] = useState(true);
  const handleLoading = () => {
      setIsLoading(false);
    }
    useEffect(()=>{
      handleLoading();
    },[]);

  return (
    <>
    {!isLoading ?
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<UnProtectedRoute  component={Login} />}></Route>
          <Route path="/forgotpassword" element={<UnProtectedRoute  component={ForgotPassword} />}></Route>
         <Route path="/changepassword/:token" element={<UnProtectedRoute  component={ChangePassword} />}></Route>
         <Route path="/otp" element={<UnProtectedRoute  component={Otp} />}></Route>

          <Route element={<Layout/>}>
              <Route path="/dashboard" element={ <ProtectedRoute  component={Dashboard} permissionss='Dashboard'  /> }></Route>
              <Route path="/customers" element={ <ProtectedRoute  component={Customers} permissionss='Customer' />}></Route>
              <Route path="/order" element={<ProtectedRoute  component={Order} permissionss='Order' />}></Route>
              <Route path="/setting" element={<ProtectedRoute  component={Setting} permissionss='Profile' />}></Route>
              <Route path="/driver" element={<ProtectedRoute  component={Driver} permissionss='Driver' />}></Route>
              <Route path="/bookings" element={<ProtectedRoute  component={Bookings} permissionss='Bookings' />}></Route>
              <Route path="/roles" element={<ProtectedRoute  component={Roles} permissionss='Role'  />}></Route>
              <Route path="/staff" element={<ProtectedRoute  component={Staff} permissionss='Staff'  />}></Route>
              <Route path="/profile" element={<ProtectedRoute  component={Profile} permissionss='Profile' />}></Route>
              <Route path="/expensestype" element={<ProtectedRoute  component={ExpensesType} permissionss='ExpensesType' />}></Route>
              <Route path="/expenses" element={<ProtectedRoute  component={Expenses} permissionss='Expenses' />}></Route>
              <Route path="/driverexpenses" element={<ProtectedRoute  component={DriverExpenses} permissionss='Expenses' />}></Route>

              <Route path="/expenseschannel" element={<ProtectedRoute  component={ExpensesChannel} permissionss='Expenses' />}></Route>
              
              <Route path="/transaction" element={<ProtectedRoute  component={Finance} permissionss='Finance' />}></Route>
              <Route path="/pnl" element={<ProtectedRoute  component={PNL} permissionss='Finance' />}></Route>
              <Route path="/pricemanage" element ={<ProtectedRoute  component={PriceManage} permissionss='Price' />}></Route>
              

          </Route>

         

        </Routes>
      </BrowserRouter>
       :<Loader/>
    }

    </>
  );
}

export default App;
