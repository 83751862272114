import React, { useState } from "react";
import {Link,useNavigate } from "react-router-dom";
import logo from "../assets/images/main-logo.png";
import car from "../assets/images/cloud-cars.png";
import {_APIS} from "./common/API/APi";
import { IconContext } from "react-icons";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import {BiUser, BiLock} from "react-icons/bi"
import {success,error} from "./common/Toastify"; 
import { ToastContainer } from "react-toastify";

const Login = () => {
  const navigate=useNavigate();
  const [inputs,setInputs]= useState({'email':'','password':''});
  const [errors, setError] = useState();
  const[visible , setVisible] = useState(false);

  
  const SubmitLogin=async()=>{
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
               if(!inputs?.email || !emailRegex.test(inputs?.email)){
                 setError({ email: true });
                  return;
               }  
               if(!inputs?.password){
                setError({ password: true });
                return;
              } 
             setError();
             document.querySelector(".loginbutton").style.pointerEvents = "none";
            let res = await _APIS(inputs, "login");
            if(res?.status === 200){
              document.querySelector(".loginbutton").style.pointerEvents = "auto";
              success(res?.message);
               setTimeout(()=>{
                   navigate("/otp");        
               },3000)
            }else{
              document.querySelector(".loginbutton").style.pointerEvents = "auto";
              error(res?.message);
            }
  }

  return(
    <>
     <ToastContainer></ToastContainer>
     <div className="form-design main-page d-flex align-items-center">
     <img className="w-100 h-100 form-design-bg" src={car} alt="black cars"/>
     <div className="form-design-inner position-relative mx-auto row">
       <div className="form-design-inner-left d-flex align-items-center justify-content-center col-md-5 position-relative">
         <img src={logo} className="d-block mx-auto" alt="main-logo"/>
         <div className="dot rounded-circle position-absolute"></div>
       </div>
       <div className="form-design-inner-right d-flex align-items-center justify-content-center col-md-7 position-relative">
         <div className="dot rounded-circle position-absolute"></div>
         <div className="form-design-inner-right-inner w-100 text-center">
           <h1 className="form-design-inner-right-inner-heading mb-5">Login Page</h1>
           <div className="form-design-inner-right-inner-input mb-4 ">
             <IconContext.Provider value={{ className: "form-design-inner-right-inner-input-left-icon" }}>
               <BiUser/>
             </IconContext.Provider>
             <input type="text" onChange={(e)=>setInputs({...inputs,'email':e.target.value.toLowerCase()})} value={inputs?.email} onKeyPress={(e) => e.key === "Enter" && SubmitLogin()}  className={errors?.email? " font-13px pe-2 w-100 border-danger" : "font-13px pe-2 w-100"} placeholder="Email"/>
           </div>
           <div className="form-design-inner-right-inner-input mb-3">
             <IconContext.Provider value={{ className: "form-design-inner-right-inner-input-left-icon" }}>
               <BiLock/>
             </IconContext.Provider>
             <input type={visible ? "text" : "password"} onKeyPress={(e) => e.key === "Enter" && SubmitLogin()} onChange={(e)=>setInputs({...inputs,'password':e.target.value})} value={inputs?.password} className={errors?.password? " font-13px w-100 border-danger" : "font-13px w-100"} placeholder="Password"/> 
             <button className="form-design-inner-right-inner-input-password-icon position-absolute border-0 p-0 bg-transparent" onClick={() => setVisible(current => !current)}>
               {visible ? <BsEyeSlash/> : <BsEye/>}
             </button>
           </div>
           <div className="text-end mb-5">
             <Link className="text-decoration-none mt-3 fw-semibold form-design-inner-right-inner-link" to="/forgotpassword">Forgot Password ?</Link>
           </div>
           <button className="btn form-design-inner-right-inner-btn golden-bg loginbutton"  onClick={SubmitLogin}>Submit</button>
         </div>
       </div>
     </div>

   </div>

   </>
  )
}

export default Login;