import React ,{useEffect,useRef} from 'react'
import Chart from "chart.js/auto";
import { Link } from 'react-router-dom';


function Totalorder(props) {
  const {countdata} =props;
    const cus_chartRef = useRef(null);
    const cus_chartInstance = useRef(null); 
    const lead_chartRef = useRef(null);
    const lead_chartInstance = useRef(null); 
    const porder_chartRef = useRef(null);
    const porder_chartInstance = useRef(null);
    const revenue_chartRef = useRef(null);
    const revenue_chartInstance = useRef(null); 
    
    useEffect(() => {
      if(countdata){
        cus_analyticsChart();
        lead_analyticsChart();
        porder_analyticsChart();
        revenue_analyticsChart();
      }
    
    }, [countdata]);

    const cus_analyticsChart = ()=>{
        const labels = ["S", "M", "T", "W", "TH", "F", "S"];
        for (let i = 0; i < labels; ++i) {
          labels.push(i.toString());
        }
    
        const datapoints = countdata?.monthCustomers;
    
        const data = {
          labels: labels,
          datasets: [
            {
              data: datapoints,
              borderColor: "rgb(255, 99, 132)",
              fill: false 
            }
          ]
        };
        const config = {
          type: 'line',
          data: data,
          options: {
            elements: {
                point:{
                    radius: 0
                }
            },
            responsive: true,
            plugins: {
              legend: {
                  display: false
              },
              title: {
                display: false,
                text: 'Chart.js Line Chart - Cubic interpolation mode'
              },
            },
            interaction: {
              intersect: false,
            },
            scales: {
              x: {
                display: true,
                title: {
                  display: true
                },
                grid: {
                  display: false
                },
                ticks: {
                  font: {
                      size: 10,
                  }
              }
        
              },
              y: {
                display: false,
                title: {
                  display: true,
                  text: 'Value'
                },
              }
            }
          }
        };
    
        const analyticsChart = cus_chartRef.current.getContext('2d');
        if (cus_chartInstance.current) {
          cus_chartInstance.current.destroy();
        }
        cus_chartInstance.current = new Chart(analyticsChart, config);
        return () => {
          if (cus_chartInstance.current) {
            cus_chartInstance.current.destroy();
          }
        };
    }
    const lead_analyticsChart = ()=>{
      const labels = ["S", "M", "T", "W", "TH", "F", "S"];
      for (let i = 0; i < labels; ++i) {
        labels.push(i.toString());
      }
  
      const datapoints = countdata?.monthLeadOrders ;
  
      const data = {
        labels: labels,
        datasets: [
          {
            data: datapoints,
            borderColor: "rgb(255, 99, 132)",
            fill: false 
          }
        ]
      };
      const config = {
        type: 'line',
        data: data,
        options: {
          elements: {
              point:{
                  radius: 0
              }
          },
          responsive: true,
          plugins: {
            legend: {
                display: false
            },
            title: {
              display: false,
              text: 'Chart.js Line Chart - Cubic interpolation mode'
            },
          },
          interaction: {
            intersect: false,
          },
          scales: {
            x: {
              display: true,
              title: {
                display: true
              },
              grid: {
                display: false
              },
              ticks: {
                font: {
                    size: 10,
                }
            }
      
            },
            y: {
              display: false,
              title: {
                display: true,
                text: 'Value'
              },
            }
          }
        }
      };
  
      const analyticsChart = lead_chartRef.current.getContext('2d');
      if (lead_chartInstance.current) {
        lead_chartInstance.current.destroy();
      }
      lead_chartInstance.current = new Chart(analyticsChart, config);
      return () => {
        if (lead_chartInstance.current) {
          lead_chartInstance.current.destroy();
        }
      };
    }
    const porder_analyticsChart = ()=>{
      const labels = ["S", "M", "T", "W", "TH", "F", "S"];
      for (let i = 0; i < labels; ++i) {
        labels.push(i.toString());
      }
  
      const datapoints = countdata?.monthPaidOrders;
  
      const data = {
        labels: labels,
        datasets: [
          {
            data: datapoints,
            borderColor: "rgb(255, 99, 132)",
            fill: false 
          }
        ]
      };
      const config = {
        type: 'line',
        data: data,
        options: {
          elements: {
              point:{
                  radius: 0
              }
          },
          responsive: true,
          plugins: {
            legend: {
                display: false
            },
            title: {
              display: false,
              text: 'Chart.js Line Chart - Cubic interpolation mode'
            },
          },
          interaction: {
            intersect: false,
          },
          scales: {
            x: {
              display: true,
              title: {
                display: true
              },
              grid: {
                display: false
              },
              ticks: {
                font: {
                    size: 10,
                }
            }
      
            },
            y: {
              display: false,
              title: {
                display: true,
                text: 'Value'
              },
            }
          }
        }
      };
  
      const analyticsChart = porder_chartRef.current.getContext('2d');
      if (porder_chartInstance.current) {
        porder_chartInstance.current.destroy();
      }
      porder_chartInstance.current = new Chart(analyticsChart, config);
      return () => {
        if (porder_chartInstance.current) {
          porder_chartInstance.current.destroy();
        }
      };
    }
    const revenue_analyticsChart = ()=>{
      const labels = ["S", "M", "T", "W", "TH", "F", "S"];
      for (let i = 0; i < labels; ++i) {
        labels.push(i.toString());
      }
  
      const datapoints = countdata?.monthRevenue;
  
      const data = {
        labels: labels,
        datasets: [
          {
            data: datapoints,
            borderColor: "rgb(255, 99, 132)",
            fill: false 
          }
        ]
      };
      const config = {
        type: 'line',
        data: data,
        options: {
          elements: {
              point:{
                  radius: 0
              }
          },
          responsive: true,
          plugins: {
            legend: {
                display: false
            },
            title: {
              display: false,
              text: 'Chart.js Line Chart - Cubic interpolation mode'
            },
          },
          interaction: {
            intersect: false,
          },
          scales: {
            x: {
              display: true,
              title: {
                display: true
              },
              grid: {
                display: false
              },
              ticks: {
                font: {
                    size: 10,
                }
            }
      
            },
            y: {
              display: false,
              title: {
                display: true,
                text: 'Value'
              },
            }
          }
        }
      };
  
      const analyticsChart = revenue_chartRef.current.getContext('2d');
      if (revenue_chartInstance.current) {
        revenue_chartInstance.current.destroy();
      }
      revenue_chartInstance.current = new Chart(analyticsChart, config);
      return () => {
        if (revenue_chartInstance.current) {
          revenue_chartInstance.current.destroy();
        }
      };
    }
  return (
    <>
   <Link to="/customers" className='text-decoration-none text-dark'> <div className="com-card-box analytics-box mb-1">
        <div className="analytics-box-head">
          <p className="analytics-box-heading">Total Customers</p>
        </div>
        <div className="analytics-box-body">
                <div className="col-md-5 analytics-box-body-first">
                    <h4 className="analytics-box-body-show"><span>{countdata?.totalCustomers?countdata?.totalCustomers:0}</span></h4>
                    <p className="mb-0 ">
                    <span className="me-2"></span>
            
                    </p>
                </div>
                <div className="analytics-box-body-chart-box col-md-7" >
                    <canvas ref={cus_chartRef} className="analytics-box-body-chart-box-canvas"></canvas>
                    <p className='analytics-box-body-chart-box-para'>Current Week</p>
                </div> 
        </div>
        <div className="analytics-box-meter-box">
           <div className="analytics-box-meter-box-inner" style={{width: "50%"}}></div>
        </div>
    </div></Link>

    <Link to="/order" state={{bookingfilters:{value: 'leads', label: 'Lead Orders'}}} className='text-decoration-none text-dark'><div className="com-card-box analytics-box mb-1">
        <div className="analytics-box-head">
          <p className="analytics-box-heading">Latest Leads</p>
     
        </div>
        <div className="analytics-box-body">
                <div className="col-md-5 analytics-box-body-first">
                    <h4 className="analytics-box-body-show"><span>{countdata?.totalLead?countdata?.totalLead:0}</span></h4>
                    <p className="mb-0 ">
                    <span className="me-2"></span>
                   
                    </p>
                </div>
                <div className="analytics-box-body-chart-box col-md-7" >
                    <canvas ref={lead_chartRef} className="analytics-box-body-chart-box-canvas"></canvas>
                    <p className='analytics-box-body-chart-box-para'>Current Week</p>
                </div> 
        </div>
        <div className="analytics-box-meter-box">
           <div className="analytics-box-meter-box-inner" style={{width: "50%"}}></div>
        </div>
    </div></Link>

    <Link  to="/order" state={{paidstatusfilter:{ value: 1, label: 'Paid' }}}  className='text-decoration-none text-dark'><div className="com-card-box analytics-box mb-1">
        <div className="analytics-box-head">
          <p className="analytics-box-heading">Total Paid Orders</p>
       
        </div>
        <div className="analytics-box-body">
                <div className="col-md-5 analytics-box-body-first">
                    <h4 className="analytics-box-body-show"><span>{countdata?.totalPaidOrders?countdata?.totalPaidOrders:0}</span></h4>
                    <p className="mb-0 ">
                    <span className="me-2"></span>
                 
                    </p>
                </div>
                <div className="analytics-box-body-chart-box col-md-7" >
                    <canvas ref={porder_chartRef} className="analytics-box-body-chart-box-canvas"></canvas>
                    <p className='analytics-box-body-chart-box-para'>Current Week</p>
                </div> 
        </div>
        <div className="analytics-box-meter-box">
           <div className="analytics-box-meter-box-inner" style={{width: "50%"}}></div>
        </div>
    </div>
    </Link>
    <Link  to="/pnl"  className='text-decoration-none text-dark'><div className="com-card-box analytics-box mb-1">
        <div className="analytics-box-head">
          <p className="analytics-box-heading">Total Revenue</p>
       
        </div>
        <div className="analytics-box-body">
                <div className="col-md-5 analytics-box-body-first">
                    <h4 className="analytics-box-body-show"><span>{countdata?.totalRevenue?countdata?.totalRevenue:0}</span></h4>
                    <p className="mb-0 ">
                    <span className="me-2"></span>
                    </p>
                </div>
                <div className="analytics-box-body-chart-box col-md-7" >
                    <canvas ref={revenue_chartRef} className="analytics-box-body-chart-box-canvas"></canvas>
                    <p className='analytics-box-body-chart-box-para'>Current Week</p>
                </div> 
        </div>
        <div className="analytics-box-meter-box">
           <div className="analytics-box-meter-box-inner" style={{width: "50%"}}></div>
        </div>
    </div>
    </Link>

    </>
  )
}

export default Totalorder