import React from "react";


const OrderCard = (props) => {
  const {countdata,driver_id,setorderfilter,setTranselect} =props;
  return(
    <>
    {
driver_id?
    <div className="OrderCard-main-container">
      <div className="order-card com-card-box OrderCard-width analytics-box  border-bottom border-3 border-warning mb-0">
        <div className="d-flex align-items-center justify-content-between mb-3 ">
          <h4 className="analytics-box-body-show mb-0"><span>{countdata?.totalOrders}</span></h4>
        </div>
        <p className="analytics-box-heading mb-0">TOTAL DRIVER ORDERS</p>
      </div>
      <div className="order-card com-card-box OrderCard-width analytics-box  border-bottom border-3 border-warning mb-0" >
        <div className="d-flex align-items-center justify-content-between mb-3 ">
          <h4 className="analytics-box-body-show mb-0"><span>{countdata?.totalAmount}</span></h4>
        </div>
        <p className="analytics-box-heading mb-0">TOTAL DRIVER COSTS</p>
      </div>
      <div className="order-card com-card-box OrderCard-width analytics-box border-bottom border-3 border-warning mb-0">
        <div className="d-flex align-items-center justify-content-between mb-3 ">
          <h4 className="analytics-box-body-show mb-0"><span>{countdata?.totalUpcomingleads}</span></h4>
        </div>
        <p className="analytics-box-heading mb-0">TOTAL UPCOMING ORDERS</p>
      </div>
      <div className="order-card com-card-box OrderCard-width analytics-box border-bottom border-3 border-warning mb-0">
        <div className="d-flex align-items-center justify-content-between mb-3 ">
          <h4 className="analytics-box-body-show mb-0"><span>{countdata?.totalcompleteorders}</span></h4>
        </div>
        <p className="analytics-box-heading mb-0">TOTAL COMPLETED ORDERS</p>
      </div>
    </div>
    :
    <div className="OrderCard-main-container">
      <div className="order-card com-card-box OrderCard-width analytics-box  border-bottom border-3 border-warning mb-0">
        <div className="d-flex align-items-center justify-content-between mb-3 ">
          <h4 className="analytics-box-body-show mb-0"><span>{countdata?.totalOrders}</span></h4>
        </div>
        <p className="analytics-box-heading mb-0">TOTAL ORDERS</p>
      </div>
      <div className="order-card com-card-box OrderCard-width analytics-box  border-bottom border-3 border-warning mb-0" onClick={()=>setorderfilter({ value: 'leads', label: 'Lead Orders' })}>
        <div className="d-flex align-items-center justify-content-between mb-3 ">
          <h4 className="analytics-box-body-show mb-0"><span>{countdata?.totalLeads}</span></h4>
        </div>
        <p className="analytics-box-heading mb-0">LEAD ORDERS</p>
      </div>
      <div className="order-card com-card-box OrderCard-width analytics-box border-bottom border-3 border-warning mb-0" onClick={()=>setTranselect( { value: 1, label: 'Paid' })}>
        <div className="d-flex align-items-center justify-content-between mb-3 ">
          <h4 className="analytics-box-body-show mb-0"><span>{countdata?.totalPaidOrders}</span></h4>
        </div>
        <p className="analytics-box-heading mb-0">PAID ORDERS</p>
      </div>
      <div className="order-card com-card-box OrderCard-width analytics-box border-bottom border-3 border-warning mb-0">
        <div className="d-flex align-items-center justify-content-between mb-3 ">
          <h4 className="analytics-box-body-show mb-0"><span>${countdata?.totalRevenue}</span></h4>
        </div>
        <p className="analytics-box-heading mb-0">Total Revenue</p>
      </div>
    </div>
   
    }
     </>
  )
}

export default OrderCard;