import {React,useState,useEffect} from "react";
import BookingCard from "./common/analyticsBox/BookingCard";
import { BiSearch } from "react-icons/bi";
import { DateRange } from "./modal/ComDatePicker";
import DataTable from "react-data-table-component";
import CommentModal from "./modal/CommentModal";
import Moment from 'react-moment';
import DataTableLoaders from "./common/DatatableLoader";
import {CallApi} from "./common/API/APi";
import {error} from "./common/Toastify";
import BookingComplete from "./modal/BookingComplete";
import BookcancelModal from "./modal/BookingCancel";
import Select from 'react-select';

const Bookings = () => {
  const [datatableloder,setDatatableloder]=useState();
  const [notifyrole,setNotifyrole]=useState();
  const [search,setSearch]=useState('');
  const [datesearch,setDateSearch]=useState([]);
  const [bookdata,setBookdata]=useState([]);
  const [commentsdata,setCommentsdata]=useState();
  const [bookcount,setBookcount]=useState({'totalbooking':0,'totaldonebooking':0,'totalupcoming':0});
  const [bookingid,setBookingid]=useState();
  const [cancelbookingid,setCancelbookingid]=useState();
  const [orderfiltervalue,setorderfilter]=useState();
  const paginationRowOptions = [50, 100, 500, 1000];
  const [limit, setLimit] = useState(paginationRowOptions[0]);
  const [TotalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const orderfilteroption=[ 
    { value: '', label: 'Select' },
    { value: 'upcoming', label: 'Upcomings Orders' },
    { value: 'completed', label: 'Completed Orders' },
    { value: 'cancel', label: 'Canceled Orders' }]

  const columns = [
    {name: 'S.No.',selector: row => row.sno,sortable: true,minWidth: "80px"},
    {name: 'Customer Name',selector: row => row.customer,sortable: true,minWidth: "175px"},
    {name: 'Phone',selector: row => row.phone,sortable: true,minWidth: "120px"},
    {name: 'Special Note',sortable: true,minWidth: "180px", selector: (row) => <><td className ='tooltip-area'><span className ='location-td'>{row.specialNote }</span><span className='tooltip-text'>{ row.specialNote }</span></td> </> },
    {name: 'Pickup Date',selector: row => row.pickupDate,sortable: true,minWidth: "190px" },
    {name: 'Pickup Location',sortable: true,minWidth: "180px", selector: (row) => <><td className ='tooltip-area'><span className ='location-td'>{row.pickupLocation }</span><span className='tooltip-text'>{ row.pickupLocation }</span></td> </> },
    {name: 'To Location',sortable: true,minWidth: "180px", selector: (row) => <><td className ='tooltip-area'><span className ='location-td'>{row.toLocation }</span><span className ='tooltip-text'>{ row.toLocation }</span></td> </> },
    {name: 'Booking Car',selector: row => row.bookingCar,sortable: true,minWidth: "240px"},
    {name: 'Booking Type',selector: row => row.bookingType,sortable: true,minWidth: "130px"},
    {name: 'No. Luggage',selector: row => row.luggage,sortable: true,minWidth: "150px"},
    {name: 'No. Passenger',selector: row => row.no_of_pass,sortable: true,minWidth: "150px"},
    {name: 'Passengers Info',sortable: true ,minWidth: "170px", selector: (row) => <><td className='tooltip-area'><span className='location-td'>{row.passengersInfo }</span><span className='tooltip-text'>{ row.passengersInfo }</span></td> </>},
    {name: 'Flight Details',selector: row => row.flightDetails,sortable: true,minWidth: "170px"},
    {name: 'Amount',selector: row => row.amount,sortable: true,minWidth: "150px"},
    {name: 'Booking Status',selector: row => row.bookingstatus,sortable: true,minWidth: "150px"},

    { name: 'Action',sortable: false , minWidth: '350px',
    selector: (row) =>  <>
    {row?.action?.book_status ==0?
    <>
    <button
      data-bs-toggle="modal"
      data-bs-target="#bookingComplete"
      className="cell-tabs bg-secondary text-white"
       onClick={()=>setBookingid({'id':row?.action?.id,'return_order':row?.action?.return_order?row?.action?.return_order:0})}
    >
      Booking Accept
    </button>
    <button
      data-bs-toggle="modal"
      data-bs-target="#BookcancelModal"
      className="cell-tabs mx-3 bg-danger text-white"
      onClick={()=>setCancelbookingid({'id':row?.action?.id,'return_order':row?.action?.return_order?row?.action?.return_order:0})}

    >
      Booking Cancel

    </button>
    </>
    :''}
    <button
      data-bs-toggle="modal"
      data-bs-target="#commentModal"
      className="cell-tabs text-white bg-success"
       onClick={()=>setCommentsdata({'id':row?.action?.id,'comment':row?.action?.comment})}
    >
      Comment
    </button>
    </>,

    }
  ];


  
  const getBookings = async () => {
    setDatatableloder(true);
    const skip = (currentPage - 1) * limit;
    const postdata = {
      search: search,
      start_date: datesearch[0] ? datesearch[0] : "",
      end_date: datesearch[1] ? datesearch[1] : "",
      bookingfilter:orderfiltervalue?.value?orderfiltervalue?.value:"",
      limit:limit,
      skip:skip
    };
    let res = await CallApi(postdata, "getbooking");
    if (res?.status === 200) {
      let array = [];
      res?.data.map((value, key) => {
        array.push({
          sno: key + 1,
          customer: value?.customer,
          phone: value?.phone,
          specialNote: value?.specialNote,
          pickupDate: <>
            <Moment format="MMM DD, YYYY">{value?.pickupDate}</Moment>: {value?.pickupTime} 
          </>,
          pickupLocation: value?.pickupLocation,
          toLocation: value?.toLocation,
          bookingCar: value?.bookingCar,
          bookingType: value?.bookingType,
          luggage: value?.luggage,
          no_of_pass: value?.passengers,
          passengersInfo:
            value?.is_passengers == 1
              ? "I am the Passenger"
              : value?.is_passengers == 0
              ? "I am not the Passenger " + value?.passengersdetails + ""
              : "",
          flightDetails: value?.flightDetails,
              amount:value?.amount?'$'+value?.amount:'$0',
              bookingstatus:(
                <span className={`payment-status ${value?.booking_status == 1 ? "paid-label" :(value?.booking_status == 0 ?   "pending-label":value?.booking_status == 3?"ready-label": value?.booking_status == 4?"pickup-label":value?.booking_status == 5?"accept-label": value?.booking_status == 6?"notaccept-label":value?.booking_status ==7?"unpaid-label":value?.booking_status == 8?"aftertime-label":"unpaid-label")}`}>

                        {value?.booking_status ==1 ? "Booking Completed" :(value?.booking_status == 0 ?"Booking Pending":value?.booking_status == 3?"Ready To Start":value?.booking_status == 4?"Ready To Pickup":value?.booking_status == 5?"Accepted":value?.booking_status == 6?"Not Accepted ": value?.booking_status == 7?"Booking Canceled":value?.booking_status == 8?"Pickup Time Gone" :"Booking Canceled")}
                        </span>
              ),
          action: {
            id: value?.order_id,
            comment:value?.driver_note,
            book_status:value?.booking_status,
            return_order:value?.return_order
          },
        });
      });
      let count = {'totalbooking':res?.totalbooking,'totaldonebooking':res?.totaldonebooking,'totalupcoming':res?.totalupcoming};
      setBookcount(count);
      setBookdata(array);
      setTotalRows(res?.TotalRows);
      setDatatableloder(false);
    } else {
      error("No data Found!");
    }
  };
   useEffect(()=>{
    getBookings();
   },[notifyrole,datesearch,orderfiltervalue,limit,currentPage])
  return (
    <>
      <div className="main-page-body position-relative">
        <h1 className="com-heading">BOOKINGS</h1>

        <div className="row com-card-box mx-0">
          <BookingCard bookcount={bookcount}/>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="com-card-box">
              <div className="row row-box-respnon-show">
                <div className="col-lg-4 col-md-4 mb-sm-0 d-flex gap-1">
                  <div className="input-group">
                    <div className="input-group-text bg-transparent border-end-0">
                      <BiSearch />
                    </div>
                    <input type="text" className="form-control border-start-0 ps-0 font-13px" value={search} onKeyPress={(e) => e.key === "Enter" && getBookings()}  onChange={(e)=>setSearch(e.target.value)} placeholder="Search Name And Email..."/>
                  </div>
                </div>
                <div className="col-lg-7 col-md-8 ms-auto col-sm-8 col-12 order-datatable-filter-container">
                <Select
                  onChange={(e)=>{setorderfilter(e);setCurrentPage(1)}}
                  options={orderfilteroption}
                  classNamePrefix="com-select"
                  isSearchable= {true}
                  placeholder="Orders Filter"
                />
                <DateRange setDateSearch={setDateSearch} setCurrentPage={setCurrentPage} />
                </div>
              </div>
            </div>
            <div className="com-datatable com-card-box py-2">
            {datatableloder?
                <DataTableLoaders/>
                :
                <DataTable
                  columns={columns}
                  data={bookdata}
                  pagination
                  paginationServer
                  fixedHeaderScrollHeight="300px"
                  responsive="true"
                  striped
                  paginationRowsPerPageOptions= {paginationRowOptions}
                  paginationPerPage={limit}
                  paginationTotalRows={TotalRows}
                  onChangeRowsPerPage={(newLimit) => {
                    setLimit(newLimit);
                    setCurrentPage(1);
                  }}
                  onChangePage={(page) => {setCurrentPage(page)}}
                  paginationDefaultPage={currentPage}
               />
                }
            </div>
          </div>
        </div>
      </div>
      <CommentModal commentsdata={commentsdata} setNotifyrole={setNotifyrole}/>
      <BookingComplete bookingid={bookingid} setNotifyrole={setNotifyrole}/>
      <BookcancelModal cancelbookingid={cancelbookingid} setNotifyrole={setNotifyrole}/>
    </>
  );
};

export default Bookings;
